import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { createPrintingPage } from "pages/campaign/quote/container/utils";
import { PossibleFlyerType } from "pages/flyer/builder/drawer";
import { ADM_FORMAT_PREVIEW, ADM_FORMAT } from "pages/adm/builder/constants";
import { updateCampaignExtraData } from "graphQL/mutations/campaignExtraData";

import { useClientConfig } from "../../../context/ClientConfigContext";
import { useCampaignData } from "../../../context/CampaignDataContext";

export const useOrderDetailsModal = ({
  setIsOpen,
  flyerType,
}: {
  setIsOpen: (isOpen: boolean) => void;
  flyerType: PossibleFlyerType;
}) => {
  const [firstPageRef, secondPageRef, thirdPageRef] = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];
  const {
    activeAudienceDetails,
    campaignData,
    isLoading: isLoadingCampaign,
    refetchCampaign,
    shouldDisplayDatesAlert,
  } = useCampaignData();
  const { country, isLoading: isLoadingClientConfig } = useClientConfig();
  const { thumbnailWidth, thumbnailHeight } = ADM_FORMAT_PREVIEW[flyerType as ADM_FORMAT];

  const handleClose = () => {
    setIsOpen(false);
  };
  const isLoading = isLoadingCampaign || isLoadingClientConfig;

  const onUpdateCampaignCostWithCoupon = async (couponCode: string | null, amountOff: number, percentOff: number) => {
    const payloadCampaignExtraData = {
      campaignId: campaignData?.id,
      stripeCouponCode: couponCode,
      amountOff,
      percentOff,
    };
    await updateCampaignExtraData(payloadCampaignExtraData);
    refetchCampaign();
  };

  const handlePrint = useReactToPrint({
    content: () => {
      const print = document.createElement("div");
      print.style.display = "flex";
      print.style.flexDirection = "column";
      print.style.maxHeight = "100vh";

      [firstPageRef, secondPageRef, thirdPageRef].forEach((ref) => {
        if (ref.current) {
          const page = createPrintingPage(ref);
          page.style.transform = "scale(0.8)";
          page.style.transformOrigin = "top center";
          print.appendChild(page);
        }
      });

      return print;
    },
  });

  return {
    isLoading,
    onUpdateCampaignCostWithCoupon,
    handleClose,
    audiences: activeAudienceDetails,
    campaignData,
    country,
    printingRefs: { firstPageRef, secondPageRef, thirdPageRef },
    handlePrint,
    thumbnailWidth,
    thumbnailHeight,
    shouldDisplayDatesAlert,
  };
};
