import { InputBase } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const CustomTextInput = withStyles((theme) => ({
  root: {
    "&.Mui-error": {
      border: `1px solid ${theme.palette.error.main}`,
      borderRadius: 8,
    },
    "&.MuiInputBase-multiline": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  input: {
    "&.MuiInputBase-inputMultiline": {
      padding: "12px",
    },
    display: "flex",
    minHeight: "44px",
    padding: "0px 12px",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: 8,
    border: "1px solid  #E2E2FC",
    "&:focus": {
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);
