import { z } from "zod";
import { isAheadByBusinessDays } from "../utils";
const REGEX_FOR_CAMPAIGN_NAME = /[^a-z0-9 !@#$%^&*()_+ {}:”<>?±-]/gi;

export const campaignInfoFormSchema = z.object({
  campaignName: z
    .string()
    .min(1, "Campaign name is required")
    .transform((val) => val.trim())
    .refine((val) => val.replace(/\s/g, "").length >= 4, "Campaign name is too short (minimum 4 characters)")
    .refine((val) => val.length <= 46, "Campaign name should not exceed 46 characters")
    .refine((val) => !REGEX_FOR_CAMPAIGN_NAME.test(val), "Campaign name contains invalid characters."),
  updateAddressOnMove: z.string({
    required_error: "Please select an option",
  }),
  campaignStartDate: z
    .date({
      required_error: "Launch date is required",
      invalid_type_error: "Please select a valid date",
    })
    .refine(
      (date) => {
        const isAhead = isAheadByBusinessDays(date, 5);
        return isAhead;
      },
      {
        message: "Launch date must be 5 business days from today",
      }
    ),
  format: z.string({
    required_error: "Please select a format",
  }),
});

export type CampaignInfoFormData = z.infer<typeof campaignInfoFormSchema>;
