import { ADMCampaignData, Audience } from "pages/adm/builder/types";
import { parseTimestampStringDate } from "pages/adm/builder/utils";
import dayjs from "dayjs";
import { FORMAT_OPTIONS } from "../../../../constants";
import "./CampaignDetailsSection.css";

interface CampaignDetailsSectionProps {
  printingRef: React.RefObject<HTMLDivElement>;
  audiences: Audience[];
  campaign: Partial<ADMCampaignData>;
}

export const CampaignDetailsSection = ({ printingRef, audiences, campaign }: CampaignDetailsSectionProps) => {
  const format = FORMAT_OPTIONS.find((option) => option.value === campaign.flyerType);

  return (
    <div className="orderDetailsCampaignSection" ref={printingRef}>
      <div className="sectionTitle">Campaign specifics</div>

      <div className="detailsRow">
        <div className="detailsLabel">Campaign name</div>
        <div className="detailsValue">{campaign.campaignName}</div>
      </div>

      <div className="detailsRow">
        <div className="detailsLabel">Launch date</div>
        <div className="detailsValue">
          {dayjs(parseTimestampStringDate(campaign?.startDate)).format("MMM DD, YYYY")}
        </div>
      </div>

      {campaign && (
        <div className="detailsRow">
          <div className="detailsLabel">Addressee moved</div>
          {/* TODO: add logic to check if addressee moved */}
          <div className="detailsValue">Send to new address</div>
        </div>
      )}

      {audiences && (
        <div className="detailsRow">
          <div className="detailsLabel">Audience</div>
          <div className="detailsValue">
            {audiences.map((audience, index) => (
              <div key={index}>
                {audience.name} - {audience.metadata.rowsAmount} units
              </div>
            ))}
          </div>
        </div>
      )}

      {campaign.stripeCouponCode && (
        <div className="detailsRow">
          <div className="detailsLabel">Coupon</div>
          <div className="detailsValue">{campaign.stripeCouponCode}</div>
        </div>
      )}

      <div className="detailsRow">
        <div className="detailsLabel">Format</div>
        <div className="detailsValue">{format ? `${format.topText} ${format.label}` : campaign.flyerType}</div>
      </div>
    </div>
  );
};
