import { Typography, styled } from "@material-ui/core";

export const SummaryContentText = styled(Typography)({
  color: "#0C172C",
  fontFamily: "'Proxima Nova', 'sans-serif'",
  fontSize: "14px",
  fontWeight: 600,
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
});
