import { Grid } from "@material-ui/core";
import { FormProvider } from "react-hook-form";
import { AudienceCreationModal } from "../components/AudienceCreationModal";
import { CampaignInfoCard } from "../components/CampaignInfoCard";
import { CampaignSummaryCard } from "../components/CampaignSummaryCard";
import { CampaignTargetingCard } from "../components/CampaignTargetingCard";
import useSegments from "./hooks/useSegments";
import { useCampaignCostPreviewCalculation } from "../hooks/useCampaignCostPreviewCalculation";
import { ADM_FORMAT } from "../constants";

export enum SEGMENT_STEPS {
  CAMPAIGN_INFO = "CAMPAIGN_INFO",
  TARGETING = "TARGETING",
}

const BUTTON_TITLE_MAP = {
  [SEGMENT_STEPS.CAMPAIGN_INFO]: "Continue to Targeting",
  [SEGMENT_STEPS.TARGETING]: "Continue to Design",
};

export const Segments = () => {
  const {
    isCreateAudienceModalOpen,
    setIsCreateAudienceModalOpen,
    clickedAudience,
    checkingCampaignName,
    methods,
    isCampaignInfoOpen,
    setIsCampaignInfoOpen,
    isTargetingCardOpen,
    setIsTargetingCardOpen,
    setClickedAudience,
    currentValues,
    activeAudienceDetails,
    currentStep,
    isNextEnabled,
    isLoading,
    onSubmit,
    triggerAnalyticsEvent,
    isReadOnly,
  } = useSegments();
  const { campaignCostPreview, costPerFlyer } = useCampaignCostPreviewCalculation({
    format: methods.watch("format") as ADM_FORMAT,
  });

  return (
    <Grid container className="segmentsContainer" spacing={3}>
      {isCreateAudienceModalOpen && (
        <AudienceCreationModal
          isOpen={isCreateAudienceModalOpen}
          setIsOpen={setIsCreateAudienceModalOpen}
          audience={clickedAudience}
          triggerAnalyticsEvent={triggerAnalyticsEvent}
        />
      )}
      <Grid item xs={12} md={8}>
        <FormProvider {...methods}>
          <CampaignInfoCard
            isOpen={isCampaignInfoOpen}
            setIsOpen={setIsCampaignInfoOpen}
            checkingCampaignName={checkingCampaignName}
            triggerAnalyticsEvent={triggerAnalyticsEvent}
            isReadOnly={isReadOnly}
          />
        </FormProvider>
        <CampaignTargetingCard
          isOpen={isTargetingCardOpen}
          setIsOpen={setIsTargetingCardOpen}
          setIsCreateAudienceModalOpen={setIsCreateAudienceModalOpen}
          clickedAudience={clickedAudience}
          setClickedAudience={setClickedAudience}
          triggerAnalyticsEvent={triggerAnalyticsEvent}
          costPerFlyer={costPerFlyer}
          isReadOnly={isReadOnly}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CampaignSummaryCard
          campaign={currentValues}
          targeting={activeAudienceDetails}
          flyerQuote={
            activeAudienceDetails.length
              ? {
                  flyersAmount: activeAudienceDetails.reduce((acc, audience) => acc + audience.metadata.rowsAmount, 0),
                  totalCost: campaignCostPreview?.total || 0,
                }
              : null
          }
          onClickNext={() => methods.handleSubmit(onSubmit)()}
          buttonText={BUTTON_TITLE_MAP[currentStep]}
          isNextEnabled={isNextEnabled}
          isLoading={isLoading}
          isReadOnly={isReadOnly}
        />
      </Grid>
    </Grid>
  );
};
