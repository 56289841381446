import { useCallback } from "react";
import { Box, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { InputFieldNameText } from "../InputField/components/InputFieldNameText";
import { InputFieldDescriptionText } from "../InputField/components/InputFieldDescriptionText";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "flex-start",
  },
  radio: {
    "& .MuiSvgIcon-root": {
      width: 20,
      height: 20,
    },
  },
  label: {
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
      color: "#707087",
    },
  },
}));

export type RadioButtonOption = {
  value: string;
  label: string;
  topText?: string;
  bottomText?: string;
  image?: string;
};

export type RadioButtonFieldProps = {
  className?: string;
  description?: string;
  error?: string;
  label: string;
  name: string;
  options: RadioButtonOption[];
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  required?: boolean;
};

export const RadioButtonField = ({
  className,
  description,
  disabled,
  error,
  label,
  name,
  onBlur,
  onChange,
  options,
  value,
  required,
}: RadioButtonFieldProps) => {
  const classes = useStyles();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <Box width="100%" className={className}>
      <Box className="inputLabelContainer">
        <InputFieldNameText>
          {label}
          {required && <span className="required">*</span>}
        </InputFieldNameText>
        {description && <InputFieldDescriptionText>{description}</InputFieldDescriptionText>}
      </Box>
      <Box className={classes.container}>
        <RadioGroup aria-label={name} name={name} value={value} onChange={handleChange} onBlur={onBlur} row>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio disabled={disabled} className={classes.radio} />}
              label={option.label}
              className={classes.label}
            />
          ))}
        </RadioGroup>
      </Box>
      {error && <Box className="errorContainer">{error}</Box>}
    </Box>
  );
};
