import { useCallback, useState } from "react";
import { Box, MenuItem } from "@material-ui/core";
import { InputFieldNameText } from "../InputField/components/InputFieldNameText";
import { InputFieldDescriptionText } from "../InputField/components/InputFieldDescriptionText";
import { CustomSelect } from "./components/CustomSelect";
import { CustomMenuItem } from "./components/CustomMenuItem";

export type SelectOption = {
  value: string | number | boolean;
  label: string;
  description?: string;
};

export type SelectFieldProps = {
  className?: string;
  description?: string;
  error?: string;
  label: string;
  name: string;
  options: SelectOption[];
  value: string | number | boolean;
  onChange: (value: string | number | boolean) => void;
  onBlur?: () => void;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
};

export const SelectField = ({
  className,
  description,
  disabled,
  error,
  label,
  name,
  onBlur,
  onChange,
  options,
  value,
  required,
  placeholder,
}: SelectFieldProps) => {
  const [isFocus, setIsFocus] = useState(false);

  const handleChange = useCallback(
    (event: any) => {
      const selectedValue = event.target.value;
      onChange(selectedValue);
    },
    [onChange]
  );

  const handleBlur = useCallback(() => {
    onBlur && onBlur();
    setIsFocus(false);
  }, [onBlur]);

  const handleFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const renderValue = (selected: any) => {
    if (!selected || selected === "") return placeholder;
    const option = options.find((opt) => opt.value === selected);
    return option ? option.label : "";
  };

  return (
    <Box width="100%" className={className}>
      {!!label && (
        <Box className="inputLabelContainer">
          <InputFieldNameText>
            {label}
            {required && <span className="required">*</span>}
          </InputFieldNameText>
          {description && <InputFieldDescriptionText>{description}</InputFieldDescriptionText>}
        </Box>
      )}
      <CustomSelect
        name={name}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        onBlur={handleBlur}
        onFocus={handleFocus}
        error={!!error && !isFocus}
        fullWidth
        displayEmpty
        disableUnderline
        renderValue={renderValue}
      >
        {placeholder && (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        )}
        {options.map((option) => (
          <CustomMenuItem
            key={option.value}
            option={option}
            value={value}
            onClick={() => handleChange({ target: { value: option.value } })}
          />
        ))}
      </CustomSelect>
      {error && <Box className="errorContainer">{error}</Box>}
    </Box>
  );
};
