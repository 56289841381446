import { Box, Grid, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import cn from "classnames";
import { UseFormReturn } from "react-hook-form";

import { InputField } from "components/form/InputField";

import { RadioButtonField } from "components/form/RadioButtonField";

import "./CampaignDetailsCard.css";
import { ToggleText } from "../ToggleText";
import { TitleText } from "../TitleText";
import { CampaignDetailsFormData } from "../../schemas/campaignDetails";
import checkCircleIcon from "../../../../../static/media/check-circle.svg";

interface CampaignDetailsCardProps {
  form: UseFormReturn<CampaignDetailsFormData>;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isReadOnly?: boolean;
}

export const CampaignDetailsCard = ({ form, isOpen, setIsOpen, isReadOnly }: CampaignDetailsCardProps) => {
  const {
    register,
    formState: { errors, isValid },
    setValue,
    watch,
    trigger,
  } = form;

  const hasQRCode = watch("hasQRCode");
  const hasRedemptionCoupon = watch("hasRedemptionCoupon");

  return (
    <Grid className={`detailsCardContainer ${!isOpen ? "closed" : ""}`} xs={12}>
      <Box className="detailsCardHeaderContainer">
        <div className="titleContainer">
          <TitleText>Campaign details</TitleText>
          {isValid && <img src={checkCircleIcon} alt="" className="checkIconDetailsCard" />}
        </div>

        <div className="seeMoreToggle" onClick={() => setIsOpen(!isOpen)}>
          <ToggleText>See {isOpen ? "less" : "more"}</ToggleText>
          <KeyboardArrowDownIcon fontSize="small" className={cn("toggleIcon", isOpen && "toggleIconOpen")} />
        </div>
      </Box>
      <form>
        <RadioButtonField
          disabled={isReadOnly}
          label="Will your flyer have a QR code?"
          name="hasQRCode"
          error={errors.hasQRCode?.message}
          value={hasQRCode}
          onChange={(value) => {
            setValue("hasQRCode", value);
            trigger("hasQRCode");
          }}
          options={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ]}
          required
        />

        {hasQRCode === "yes" && (
          <InputField
            disabled={isReadOnly}
            label="What is the QR code URL?"
            placeholder="e.g., https://www.mybusiness.com"
            error={errors.qrCodeUrl?.message}
            {...register("qrCodeUrl")}
            value={watch("qrCodeUrl")}
            onChange={(e) => {
              setValue("qrCodeUrl", e.target.value);
              trigger("qrCodeUrl");
            }}
            required
          />
        )}

        <RadioButtonField
          disabled={isReadOnly}
          label="Will your flyer have a redemption coupon?"
          name="hasRedemptionCoupon"
          error={errors.hasRedemptionCoupon?.message}
          value={hasRedemptionCoupon}
          onChange={(value) => {
            setValue("hasRedemptionCoupon", value);
            trigger("hasRedemptionCoupon");
          }}
          options={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ]}
          required
        />

        {hasRedemptionCoupon === "yes" && (
          <InputField
            disabled={isReadOnly}
            label="Enter the coupon code from your flyer to track redemptions"
            placeholder="e.g., PROMO50"
            error={errors.couponCode?.message}
            {...register("couponCode")}
            value={watch("couponCode")}
            onChange={(e) => {
              setValue("couponCode", e.target.value);
              trigger("couponCode");
            }}
            required
          />
        )}

        <Box>
          <InputField
            disabled={isReadOnly}
            label="Share any additional comment"
            placeholder=""
            error={errors.additionalComment?.message}
            {...register("additionalComment")}
            value={watch("additionalComment")}
            onChange={(e) => {
              setValue("additionalComment", e.target.value);
              trigger("additionalComment");
            }}
            multiline
            rows={6}
            inputProps={{ maxLength: 240 }}
          />
          <Typography variant="caption" component="p" gutterBottom>
            {watch("additionalComment")?.length ?? 0} of 240 characters
          </Typography>
        </Box>
      </form>
    </Grid>
  );
};
