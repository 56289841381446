import ROUTES from "config/routing";
import { ADM_NAVIGATION } from "./constants";
import { CampaignInfoFormData } from "./schemas/campaignInformation";
import { ADMCampaignData } from "./types";

export const validateUserAccess = (
  history: { push: (path: string) => void },
  user: { clientId: number },
  clientId: number
) => {
  if (user.clientId != clientId) {
    return history.push(ROUTES.ACCESS_DENIED);
  }
};

export const isAheadByBusinessDays = (date: Date, businessDays: number) => {
  const dateToCheck = getNextDayByBusinessDays(new Date(), businessDays);
  return date.toISOString().split("T")[0] >= dateToCheck.toISOString().split("T")[0];
};

export const getNextDayByBusinessDays = (date: Date, businessDays: number): Date => {
  const result = new Date(date);
  let daysAdded = 0;

  while (daysAdded < businessDays) {
    result.setDate(result.getDate() + 1);

    const isWeekend = !isBusinessDay(result);

    if (!isWeekend) {
      daysAdded++;
    }
  }

  return result;
};
const isBusinessDay = (date: Date): boolean => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};

export const getIndexOfCurrentStep = (lastActiveStep: string | null) => {
  const previousStep = ADM_NAVIGATION.find((item) => item.step === lastActiveStep);

  if (!previousStep) {
    return 0;
  }

  return Math.min(previousStep.index, ADM_NAVIGATION.length - 1);
};

export const parseTimestampStringDate = (timestampString: string | undefined) => {
  if (!timestampString) {
    return null;
  }
  const utcDate = new Date(parseInt(timestampString));
  const date = new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());
  return date;
};

export const campaignHasUpdatedFields = (
  currentCampaignData: ADMCampaignData,
  newCampaignData: CampaignInfoFormData
) => {
  const startDate = parseTimestampStringDate(currentCampaignData.startDate) || new Date();

  return (
    currentCampaignData.campaignName !== newCampaignData.campaignName ||
    startDate?.toISOString().split("T")[0] !== newCampaignData.campaignStartDate.toISOString().split("T")[0] ||
    currentCampaignData.flyerType !== newCampaignData.format
  );
};

export const isCampaignInfoFormFullyFilled = (campaignData: CampaignInfoFormData): boolean => {
  return !!(
    campaignData.campaignName &&
    campaignData.campaignName.length > 0 &&
    campaignData.campaignStartDate &&
    campaignData.format &&
    campaignData.format.length > 0
  );
};

export const convertNumberRounded = ({ number = 0, currency = "", fixed = 2, isUSClient = false }) => {
  const multiplier = Number(addTrailingZeros(1, fixed + 1));
  const fixedNumber = String(Math.round((Number(number) || 0) * multiplier) / multiplier);
  const [num, decimal] = fixedNumber.split(".");
  const formattedNumber = Number(num).toLocaleString();
  return `${currency}${isUSClient ? formattedNumber : formattedNumber.replace(/,/g, " ")}.${addTrailingZeros(
    Number(decimal || 0),
    fixed
  )}`;
};

const addTrailingZeros = (num: number, totalLength: number) => {
  return String(num).padEnd(totalLength, "0");
};
