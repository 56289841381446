import CloseIcon from "@material-ui/icons/Close";
import { CircularProgress, Drawer, styled } from "@material-ui/core";
import { UseGetFlyerInfoResponse } from "pages/flyer/flyerTypePage/useGetFlyerInfo";
import { PossibleFlyerType } from "pages/flyer/builder/drawer";

import { OrderDetailsFooter } from "./components/OrderDetailsFooter";
import "./OrderDetailsModal.css";
import { QuoteSection } from "./components/QuoteSection";
import { CampaignDetailsSection } from "./components/CampaignDetailsSection";
import { DesignPreviewSection } from "./components/DesignPreviewSection";
import { useOrderDetailsModal } from "./hooks/useOrderDetailsModal";
import { useCampaignCostPreviewCalculation } from "../../hooks/useCampaignCostPreviewCalculation";
interface OrderDetailsModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  submitter: string;
  onContinueToCheckout: () => void;
  flyerInfo: UseGetFlyerInfoResponse;
  flyerType: PossibleFlyerType;
  isReadOnly?: boolean;
  isCheckoutEnabled?: boolean;
}

export const CloseIconStyled = styled(CloseIcon)({
  width: "18px",
  height: "18px",
  flexShrink: 0,
  color: "#4F5B94",
});

export const OrderDetailsModal = ({
  isOpen,
  setIsOpen,
  submitter,
  onContinueToCheckout,
  flyerInfo,
  flyerType,
  isReadOnly = false,
  isCheckoutEnabled = false,
}: OrderDetailsModalProps) => {
  const {
    isLoading,
    onUpdateCampaignCostWithCoupon,
    handleClose,
    audiences,
    campaignData,
    country,
    printingRefs,
    handlePrint,
    thumbnailWidth,
    thumbnailHeight,
    shouldDisplayDatesAlert,
  } = useOrderDetailsModal({
    setIsOpen,
    flyerType,
  });

  const { campaignCostPreview } = useCampaignCostPreviewCalculation();

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      SlideProps={{
        timeout: 300,
      }}
    >
      <div className="orderDetailsModalHeader">
        <button className="orderDetailsModalCloseButton" onClick={handleClose}>
          <CloseIconStyled />
        </button>
      </div>
      <div className="orderDetailsModalContainer">
        <div className="orderDetailsModalContent">
          {isLoading ||
            !country ||
            (!campaignData && (
              <div className="orderDetailsLoadingContainer">
                <CircularProgress size={20} color="secondary" />
              </div>
            ))}
          <span className="orderDetailsModalTitle">Order details</span>
          {country && campaignData && (
            <QuoteSection
              printingRef={printingRefs.firstPageRef}
              campaignCostPreview={campaignCostPreview}
              country={country}
              campaign={campaignData}
              onUpdateCampaignCostWithCoupon={onUpdateCampaignCostWithCoupon}
              isReadOnly={isReadOnly}
            />
          )}
          {campaignData && (
            <CampaignDetailsSection
              printingRef={printingRefs.secondPageRef}
              audiences={audiences}
              campaign={campaignData}
            />
          )}
          <DesignPreviewSection
            printingRef={printingRefs.thirdPageRef}
            flyerInfo={flyerInfo}
            thumbnailWidth={thumbnailWidth}
            thumbnailHeight={thumbnailHeight}
          />
          <div className="orderDetailsModalSubmitterContainer">
            <span>Submitter:</span>
            <span className="orderDetailsModalSubmitterValue">{submitter}</span>
          </div>
        </div>
      </div>
      <OrderDetailsFooter
        onPrint={handlePrint}
        onContinueToCheckout={onContinueToCheckout}
        isContinueToCheckoutDisabled={shouldDisplayDatesAlert || isReadOnly || !isCheckoutEnabled}
        quote={campaignCostPreview}
        isSubmitted={!!campaignData?.isSubmitted}
      />
    </Drawer>
  );
};
