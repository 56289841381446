export const styles = {
  routeCardContainer: {
    width: "100%",
    border: "1px solid #E2E2FC",
    background: "#F9F9FF",
    padding: "5px 8px",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    height: "66px",
    position: "relative" as const,
  },
  routeHeader: {
    width: "100%",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  routeTitle: {
    display: "flex",
    alignItems: "center",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    maxWidth: "90%",
    padding: "2px 5px 0px",
    width: "fit-content",
    position: "relative" as const,
    overflow: "hidden",
    transition: "color 0.4s ease-out",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "#4F5B94",
      transform: "translateX(-101%)",
      transition: "transform 0.4s ease-out",
      zIndex: 0,
    },
    "&.active": {
      color: "white",
      "&::before": {
        transform: "translateX(0)",
      },
    },
    "& span": {
      position: "relative",
      zIndex: 1,
      display: "flex",
      alignItems: "center",
    },
  },
  shortTitle: {
    maxWidth: "65%",
  },
  iconButton: {
    cursor: "pointer",
    border: "1px solid #E2E2FC",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectButton: {
    cursor: "pointer",
    border: "1px solid #EE4360",
    backgroundColor: "#EE4360",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    marginLeft: "5px",
    "& svg": {
      width: "15px",
      height: "15px",
    },
  },
  deleting: {
    backgroundColor: "#FF798D1F",
    borderLeft: "1px solid #E00404",
    borderRight: "1px solid #E00404",
  },
  deleteConfirmationButtonsWrapper: {
    display: "flex",
  },
  routeStatistics: {
    width: "100%",
    marginTop: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    fontSize: "14px",
  },
  residences: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "2px",
    },
    color: "#4F5B94",
  },
  statisticLabel: {
    color: "#4F5B94",
    fontSize: "12px",
    marginRight: "5px",
  },
  costContainer: {
    display: "flex",
    alignItems: "center",
  },
  cost: {
    color: "#4F5B94",
    fontWeight: 600,
  },
};
