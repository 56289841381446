import CloseIcon from "@material-ui/icons/Close";
import classes from "./CancelAudienceCreationModal.module.css";
import { SecondaryButton } from "../../../SecondaryButton";
import { PrimaryButton } from "../../../PrimaryButton";

interface CancelAudienceCreationModalProps {
  setIsCancellingAudienceCreation: (isCancellingAudienceCreation: boolean) => void;
  cancelAudienceCreation: () => void;
}

export const CancelAudienceCreationModal = ({
  setIsCancellingAudienceCreation,
  cancelAudienceCreation,
}: CancelAudienceCreationModalProps) => {
  const handleClose = () => {
    setIsCancellingAudienceCreation(false);
  };

  return (
    <div
      className={classes.modalBackground}
      onClick={(e) => {
        e.stopPropagation();
        handleClose();
      }}
    >
      <div className={classes.modalContent}>
        <div className={classes.modalHeader}>
          <button className={classes.closeButton} onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </button>
        </div>
        <div className={classes.modalTitleContainer}>
          <span className={classes.modalTitle}>Are you sure you want to cancel your audience creation?</span>
        </div>
        <div className={classes.modalFooter}>
          <SecondaryButton className={classes.button} onClick={cancelAudienceCreation}>
            Cancel audience creation
          </SecondaryButton>
          <PrimaryButton className={classes.button} onClick={handleClose}>
            Continue audience creation
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};
