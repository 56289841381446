import papaparse from "papaparse";
import { useState, useEffect, useCallback } from "react";
import { apiClient } from "module/api";
import { Audience } from "../../types";
interface UseDragAndDropProps {
  audience: Audience | null;
  setFiles: (files: File[]) => void;
  maxFileSize?: number; // in MB
  clientId: string;
  triggerAnalyticsEvent: (eventName: string, eventProperties?: Record<string, any>) => void;
  headersMapInitialState: Record<string, string>;
  setHeadersMap: (headersMap: Record<string, string>) => void;
}

interface FileRejection {
  errors: { code: string }[];
}

export const useADMDesignDragAndDrop = ({
  audience,
  setHeadersMap,
  headersMapInitialState,
  setFiles,
  maxFileSize = 25,
  clientId,
  triggerAnalyticsEvent,
}: UseDragAndDropProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const [fileError, setFileError] = useState<string | null>(null);
  const [fileMetadata, setFileMetadata] = useState<{
    headers: string[];
    firstRow: string[];
    rowsAmount: number;
    fileUrl?: string;
  } | null>(audience?.metadata || null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.some((f: File) => f.size > maxFileSize * 1048576)) {
        // setTempFiles([]);
        setFiles([]);
        setFileMetadata(null);
        setFileError(`Error: The file exceeded the ${maxFileSize}mb limit`);
      } else if (acceptedFiles.length) {
        setFileError(null);
        papaparse.parse(acceptedFiles[0], {
          complete: (result: any) => {
            const { data } = result;
            let parseError;

            if (data.length < 1002) {
              parseError = "CSV must have at least 1001 rows (excluding headers).";
              setFileError(parseError);
              return;
            }

            if (data[0].length < 6) {
              parseError = "CSV must have at least 6 columns.";
              setFileError(parseError);
              return;
            }

            if (!parseError) {
              setHeadersMap(headersMapInitialState);
              setFiles(acceptedFiles);
              setFileMetadata({
                headers: data[0],
                firstRow: data[1],
                rowsAmount: data.length - 1,
              });
              triggerAnalyticsEvent("Uploaded an ADM audience CSV");
            }

            if (parseError) {
              setFileError(parseError);
              triggerAnalyticsEvent("Saw error on ADM audience CSV upload", {
                csvError: parseError,
              });
            }
          },
        });
      }
    },
    [setFileError]
  );

  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-invalid-type") {
          const fileError = "Error: You can upload only CSV file";
          setFileError(fileError);
          triggerAnalyticsEvent("Saw error on ADM audience CSV upload", {
            csvError: fileError,
          });
        }
      });
    });
  };

  const getPresignedFileUrl = async (file: File) => {
    try {
      const presignedUrlResponse = await apiClient.getPresignedUrl({ fileName: file.name, clientId });
      if (presignedUrlResponse.status !== 200) {
        throw new Error("Failed to get the presigened url");
      }

      const presignedUrlData = await presignedUrlResponse.json();

      if (!presignedUrlData?.url) {
        throw new Error("Failed to get the presigened url");
      }
      return presignedUrlData;
    } catch (e) {
      console.error({ e }, "error getting the presigned url");
      setFileError("There was an error while uploading the file");
    }
  };

  const uploadHandler = async (file: File, presignedUrl: string, signal?: AbortSignal) => {
    if (!file || !presignedUrl) return;
    let responseStatus;
    setFileError(null);
    setIsUploading(true);

    try {
      const response = await fetch(presignedUrl, {
        method: "PUT",
        body: file,
        signal: signal,
        headers: {
          "Content-Type": file.type,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }
      responseStatus = true;
    } catch (error) {
      // @ts-ignore
      if (error?.name === "AbortError") {
        throw error;
      }
      console.error({ error }, "uploadFile error");
      setFileError("There was an error while uploading the file");
      responseStatus = false;
    } finally {
      setIsUploading(false);
    }
    return responseStatus;
  };

  useEffect(() => {
    if (audience?.csv_url) {
      const fileName = audience?.csv_url.split("/").pop();
      setFiles([{ name: fileName } as File]);
    }
  }, [audience]);

  return {
    isUploading,
    setIsUploading,
    uploadHandler,
    getPresignedFileUrl,
    fileError,
    onDrop,
    onDropRejected,
    fileMetadata,
    setFileMetadata,
  };
};
