export const Upload = ({ disabled = false }) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.85796 6.43075H5.23596C3.8793 6.43075 2.7793 7.53075 2.7793 8.88741L2.7793 12.1374C2.7793 13.4934 3.8793 14.5934 5.23596 14.5934H12.656C14.0126 14.5934 15.1126 13.4934 15.1126 12.1374V8.88075C15.1126 7.52808 14.016 6.43075 12.6633 6.43075H12.0346"
        stroke={disabled ? "#AEAFC7" : "#273754"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.94613 1.90174V9.92908"
        stroke={disabled ? "#AEAFC7" : "#273754"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00244 3.85394L8.94577 1.90194L10.8898 3.85394"
        stroke={disabled ? "#AEAFC7" : "#273754"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
