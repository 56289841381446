import { useState } from "react";
import cn from "classnames";
import { DistributionLocation, SelectedRoute } from "module/eddm/dto";
import { getRouteResidences } from "utils/eddm";
import { usePostHog } from "posthog-js/react";
import { EmitAnalyticsEvent } from "module/analytics/application/EmitAnalyticsEvent";
import { formatNumber, toFixed2 } from "utils/formators";
import { GoogleMap } from "@react-google-maps/api";
import { ExpandIcon } from "components/icons/components/Expand";
import { RouteIcon } from "components/icons/components/RouteIcon";
import { ResidencesIcon } from "components/icons/components/ResidencesIcon";
import { CollapseIcon } from "components/icons/components/Collapse";
import { makeStyles, Tooltip } from "@material-ui/core";
import { DeleteIcon } from "static/media/delete-icon";
import { useStore } from "store";
import { stylesV2 } from "./stylesV2";
import { RouteCard } from "./RouteCard";
import { AddressIconSelected, AddressIconUnselected } from "./icons";

const useStyles = makeStyles(() => stylesV2);

interface AddressCardProps {
  address: DistributionLocation;
  onClick: () => void;
  handleDeleteLocation: (deletedLocation: DistributionLocation) => void;
  setSelectedRoutes: (selectedRoutes: SelectedRoute[] | ((selectedRoutes: SelectedRoute[]) => SelectedRoute[])) => void;
  googleMap: GoogleMap | null;
  setShouldAutoSave: (shouldAutoSave: boolean) => void;
  setIsUndoOrRedo: (isUndoOrRedo: boolean) => void;
  setIsSingleRouteSelection: (isSingleSelection: boolean) => void;
}

export const AddressCardV2 = ({
  address,
  onClick,
  handleDeleteLocation,
  setSelectedRoutes,
  googleMap,
  setShouldAutoSave,
  setIsUndoOrRedo,
  setIsSingleRouteSelection,
}: AddressCardProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { isFocused, name, selectedRoutes } = address;
  const [expanded, setExpanded] = useState(isFocused);
  const classes = useStyles();
  const {
    user: { id: userId },
    client: { name: clientName },
    campaign: { isSubmitted },
    costsCalculationData: { costPerFlyer },
  } = useStore();
  const posthog = usePostHog();
  const posthogEventTracker = new EmitAnalyticsEvent(posthog);
  const noRoutes = !selectedRoutes?.length;
  const lightGray = "#AEAFC7";

  const handleRouteDelete = (route: SelectedRoute) => {
    if (!route || !googleMap) return;
    setShouldAutoSave(true);
    setIsUndoOrRedo(false);
    setIsSingleRouteSelection(true);
    setSelectedRoutes((selectedRoutes) => [...selectedRoutes.filter((r) => r.id !== route.id)]);
    // @ts-ignore
    googleMap.data.forEach((feature) => {
      if (route.id === feature.getProperty("id")) {
        feature.setProperty("selected", false);
      }
    });
  };

  const totalAddresses = getRouteResidences(selectedRoutes);

  return (
    <div className={cn(classes.addressCardContainer)}>
      <div className={classes.addressHeader}>
        <div className={cn(classes.addressTitle, { [classes.shortTitle]: isDeleting })} onClick={onClick}>
          {isDeleting ? <b>Delete</b> : isFocused ? <AddressIconSelected /> : <AddressIconUnselected />}
          <span className={classes.name}>{name}</span> {isDeleting ? "?" : ""}
        </div>
        {isDeleting ? (
          <div className={classes.deleteConfirmationButtonsWrapper}>
            <div
              className={classes.noButton}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsDeleting(false);
              }}
            >
              No
            </div>
            <div
              className={classes.yesButton}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                posthogEventTracker.run({
                  eventName: `Deleted entire location`,
                  userId: userId,
                  clientName,
                  device: navigator.userAgent,
                });
                handleDeleteLocation(address);
              }}
            >
              Yes
            </div>
          </div>
        ) : (
          <div className={classes.buttonsContainer}>
            {!!selectedRoutes?.length && (
              <Tooltip
                classes={classes}
                title={expanded ? "Collapse location" : "Expand location"}
                arrow
                placement="top"
              >
                <button
                  className={cn(classes.iconButton, classes.expandCollapseButton)}
                  onClick={() => {
                    posthogEventTracker.run({
                      eventName: `${expanded ? "Collapsed" : "Expanded"} addresses list`,
                      userId: userId,
                      clientName,
                      device: navigator.userAgent,
                    });
                    setExpanded(!expanded);
                  }}
                >
                  {expanded ? <CollapseIcon /> : <ExpandIcon />}{" "}
                </button>
              </Tooltip>
            )}
            <button className={classes.iconButton}>
              <DeleteIcon
                disabled={isSubmitted}
                width={"16"}
                height={"16"}
                className={classes.cursorPointer}
                onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (isSubmitted) return;
                  setIsDeleting(true);
                }}
              />
            </button>
          </div>
        )}
      </div>
      <div
        className={cn(classes.addressStatistics, {
          [classes.addressStatisticsEmpty]: !selectedRoutes?.length,
          [classes.addressStatisticsCollapsed]: !expanded && !!selectedRoutes?.length,
          [classes.addressStatisticsCollapsedDeleting]: !expanded && !!selectedRoutes?.length && isDeleting,
        })}
      >
        <div className={classes.statisticsValueContainer}>
          <span
            className={cn(classes.statisticLabel, {
              [classes.disabled]: noRoutes,
              [classes.deletingLabel]: isDeleting && !noRoutes,
            })}
          >
            Routes
          </span>
          <span
            className={cn(classes.statisticsValue, {
              [classes.disabled]: noRoutes,
              [classes.deletingLabel]: isDeleting && !noRoutes,
            })}
          >
            <RouteIcon stroke={noRoutes ? lightGray : isDeleting ? "#E00404" : null} /> {selectedRoutes?.length || 0}
          </span>
        </div>
        <div className={classes.statisticsValueContainer}>
          <span
            className={cn(classes.statisticLabel, {
              [classes.disabled]: noRoutes,
              [classes.deletingLabel]: isDeleting && !noRoutes,
            })}
          >
            Residences
          </span>
          <span
            className={cn(classes.statisticsValue, {
              [classes.disabled]: noRoutes,
              [classes.deletingLabel]: isDeleting && !noRoutes,
            })}
          >
            <ResidencesIcon stroke={noRoutes ? lightGray : isDeleting ? "#E00404" : null} />{" "}
            {formatNumber(totalAddresses)}
          </span>
        </div>
        <div className={cn(classes.statisticsValueContainer, classes.statisticsCostValue)}>
          <span
            className={cn(classes.statisticLabel, classes.costLabel, {
              [classes.disabled]: noRoutes,
              [classes.deletingLabel]: isDeleting && !noRoutes,
            })}
          >
            Costs
          </span>
          <span
            className={cn(classes.costValue, {
              [classes.disabled]: noRoutes,
              [classes.deletingLabel]: isDeleting && !noRoutes,
            })}
          >
            $ {formatNumber(toFixed2(costPerFlyer * totalAddresses))}
          </span>
        </div>
      </div>
      {expanded &&
        !!selectedRoutes?.length &&
        selectedRoutes.map((route, i) => (
          <RouteCard
            key={`${route.metadata.ZIP}-${route.metadata.CROUTE}`}
            route={route}
            className={cn({
              [classes.firstRoute]: i === 0,
              [classes.firstRouteDeleting]: i === 0 && isDeleting,
              [classes.lastRoute]: i === selectedRoutes.length - 1,
              [classes.lastRouteDeleting]: i === selectedRoutes.length - 1 && isDeleting,
            })}
            isDeleting={isDeleting}
            handleRouteDelete={handleRouteDelete}
            googleMap={googleMap}
          />
        ))}
    </div>
  );
};
