import { useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";
import { useStore } from "store";
import { StoreType } from "store/types";
import { EmitAnalyticsEvent } from "module/analytics/application/EmitAnalyticsEvent";
import { getSelectedTargetsAsString } from "components/audience-map/utils";
import {
  Education,
  Ethnicity,
  Gender,
  HealthInsurance,
  HouseholdOwnership,
  HouseholdSize,
  Income,
  Language,
  Plant,
} from "static/media";
import { Target, TargetOptions, TargetPayload, TargetSelectors } from "shared/models";
import { TARGET_SELECTOR_VALUES } from "shared/constants";
import { targetOptionsValues } from "components/audience-map/constant";

type FeatureEventOrigin = "map_explorer_demographics_attributes" | "map_demographics_attributes";

const EventNameByOrigin: Record<FeatureEventOrigin, string> = {
  map_explorer_demographics_attributes: "Used audience attributes",
  map_demographics_attributes: "Changed attributes on the map",
};
interface UseTargetSelector {
  selectedTargets: TargetPayload[];
  updateSelectedTargets: (targets: TargetPayload[]) => void;
  suggestionsEnabled?: boolean | null;
  fullTargets?: boolean;
  setShouldAutoSave: (shouldAutoSave: boolean) => void;
  metadata?: { origin: FeatureEventOrigin };
}

export const useTargetSelector = ({
  selectedTargets,
  updateSelectedTargets,
  suggestionsEnabled,
  fullTargets = true,
  setShouldAutoSave,
  metadata,
}: UseTargetSelector) => {
  const posthog = usePostHog();
  const {
    user,
    campaign: { id: campaignId },
    client: { name: clientName },
  }: StoreType = useStore();
  const postHogTracker = new EmitAnalyticsEvent(posthog);
  const initializeTargetOptions = () => {
    const updatedOptions: TargetSelectors = { ...targetOptionsValues };
    selectedTargets.forEach(({ value, options }) => {
      if (updatedOptions[value as keyof typeof updatedOptions]) {
        updatedOptions[value as keyof typeof updatedOptions] = updatedOptions[value as keyof typeof updatedOptions].map(
          (option) => ({
            ...option,
            selected: !!options.find((_option) => _option.selected && option.value === _option.value),
          })
        );
      }
    });
    return updatedOptions;
  };

  const [targetOptions, setTargetOptions] = useState(initializeTargetOptions());
  const [filteredSelectedOptions, setFilteredSelectedOptions] = useState<Array<Target>>([]);
  const [limitedSelectorsOptions, setLimitedSelectorsOptions] = useState<Array<Target>>([]);
  const [nonSelectedOptions, setNonSelectedOptions] = useState<Array<Target>>([]);

  const handleUpdateTarget = (key: string, selectedOption: TargetOptions[]) => {
    const newValues = {
      ...targetOptions,
      [key]: selectedOption,
    };
    setTargetOptions(newValues);

    const newSelectedTargets = selectorsOptions
      .map((target) => {
        if (target.value === key) {
          return {
            ...target,
            options: selectedOption,
          };
        }
        return target;
      })
      .filter((selector) => {
        const hasSelectedOptions = selector.options?.some((option) => option.selected);
        if (hasSelectedOptions) return selector;
      });

    if (metadata?.origin) {
      const selectedTargetsPayload = getSelectedTargetsAsString(newSelectedTargets);
      postHogTracker.run({
        eventName: EventNameByOrigin[metadata.origin],
        userId: user?.id,
        clientName,
        device: navigator.userAgent,
        additionalPayload: {
          campaignId,
          audienceAttributes: selectedTargetsPayload,
        },
      });
    }
  };

  const selectorsOptions = [
    {
      label: "Age",
      icon: Plant,
      value: TARGET_SELECTOR_VALUES.AGE_RANGE_TIERS,
      options: targetOptions.AGE_RANGE_TIERS,
      onUpdate: (newOption: TargetOptions[]) => handleUpdateTarget(TARGET_SELECTOR_VALUES.AGE_RANGE_TIERS, newOption),
    },
    {
      label: "Gender",
      icon: Gender,
      value: TARGET_SELECTOR_VALUES.GENDER_TIERS,
      options: targetOptions.GENDER_TIERS,
      onUpdate: (newOption: TargetOptions[]) => handleUpdateTarget(TARGET_SELECTOR_VALUES.GENDER_TIERS, newOption),
    },
    {
      label: "Income",
      icon: Income,
      value: TARGET_SELECTOR_VALUES.MEDIAN_INCOME,
      options: targetOptions.MEDIAN_INCOME,
      onUpdate: (newOption: TargetOptions[]) => handleUpdateTarget(TARGET_SELECTOR_VALUES.MEDIAN_INCOME, newOption),
    },
    {
      label: "Ethnicity",
      icon: Ethnicity,
      value: TARGET_SELECTOR_VALUES.ETHNICITY_TIERS,
      options: targetOptions.ETHNICITY_TIERS,
      onUpdate: (newOption: TargetOptions[]) => handleUpdateTarget(TARGET_SELECTOR_VALUES.ETHNICITY_TIERS, newOption),
    },
    {
      label: "Household size",
      icon: HouseholdSize,
      value: TARGET_SELECTOR_VALUES.AVERAGE_HOUSEHOLD_SIZE,
      options: targetOptions.AVERAGE_HOUSEHOLD_SIZE,
      onUpdate: (newOption: TargetOptions[]) =>
        handleUpdateTarget(TARGET_SELECTOR_VALUES.AVERAGE_HOUSEHOLD_SIZE, newOption),
    },
    {
      label: "Language",
      icon: Language,
      value: TARGET_SELECTOR_VALUES.LANGUAGE_TIERS,
      options: targetOptions.LANGUAGE_TIERS,
      onUpdate: (newOption: TargetOptions[]) => handleUpdateTarget(TARGET_SELECTOR_VALUES.LANGUAGE_TIERS, newOption),
    },
    {
      label: "Education",
      icon: Education,
      value: TARGET_SELECTOR_VALUES.EDUCATION_TIERS,
      options: targetOptions.EDUCATION_TIERS,
      onUpdate: (newOption: TargetOptions[]) => handleUpdateTarget(TARGET_SELECTOR_VALUES.EDUCATION_TIERS, newOption),
    },
    {
      label: "Household ownership",
      icon: HouseholdOwnership,
      value: TARGET_SELECTOR_VALUES.OCCUPANCY_TIERS,
      options: targetOptions.OCCUPANCY_TIERS,
      onUpdate: (newOption: TargetOptions[]) => handleUpdateTarget(TARGET_SELECTOR_VALUES.OCCUPANCY_TIERS, newOption),
    },
    {
      label: "Health insurance",
      icon: HealthInsurance,
      value: TARGET_SELECTOR_VALUES.HEALTH_INSURANCE_TIERS,
      options: targetOptions.HEALTH_INSURANCE_TIERS,
      onUpdate: (newOption: TargetOptions[]) =>
        handleUpdateTarget(TARGET_SELECTOR_VALUES.HEALTH_INSURANCE_TIERS, newOption),
    },
  ];

  useEffect(() => {
    const filteredTargetsWithSelectedOptions: TargetPayload[] = selectorsOptions.filter((selector) => {
      const hasSelectedOptions = selector.options?.some((option) => option.selected);
      if (hasSelectedOptions) return selector;
    });

    setFilteredSelectedOptions(filteredTargetsWithSelectedOptions);
    updateSelectedTargets(filteredTargetsWithSelectedOptions);
    setShouldAutoSave(true);
  }, [targetOptions]);

  useEffect(() => {
    if (!fullTargets) {
      const prioritizeSelectors = () => {
        const priorityTargets = [...selectorsOptions];
        const selectedWithCount = selectedTargets.map((target) => ({
          ...target,
          selectedCount: target.options.filter((option) => option.selected).length,
        }));

        const sortedSelected = selectedWithCount.sort((a, b) => b.selectedCount - a.selectedCount);
        const remainingPriority = priorityTargets.filter(
          (option) => !sortedSelected.find((selected) => selected.value === option.value)
        );

        const mergedTargets = [...sortedSelected, ...remainingPriority];
        setNonSelectedOptions(mergedTargets.slice(3) as Target[]);
        return mergedTargets.slice(0, 3) as Target[];
      };

      setLimitedSelectorsOptions(prioritizeSelectors());
    }
  }, [selectedTargets, suggestionsEnabled]);

  return { selectorsOptions, limitedSelectorsOptions, nonSelectedOptions, filteredSelectedOptions };
};
