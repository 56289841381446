import { createContext, useContext, ReactNode, useMemo } from "react";
import { authorization } from "module/auth";
import { useQuery } from "react-query";
import { ClientType, CountryType, PrintingCostsType } from "store/types";
import { formatPrintingCosts } from "shared/campaign";

import { getCountryData, getClient, getAdmClientsPricings } from "../../../../graphQL";

interface ClientConfigContextProps {
  client: ClientType | null;
  country: CountryType | null;
  refetchCountry: () => void;
  isLoading: boolean;
  errorFetchingCountry: Error | null;
  admPrintingCosts: PrintingCostsType | null;
}

const ClientConfigContext = createContext<ClientConfigContextProps | undefined>(undefined);

export const ClientConfigProvider = ({ children }: { children: ReactNode }) => {
  const user = useMemo(() => authorization.getUser(), [authorization]);
  const { data: client = null, isLoading: isLoadingClient } = useQuery<ClientType | null>({
    queryKey: ["client", user.clientId],
    queryFn: async () => {
      const clientData = await getClient({ clientId: user.clientId });
      return clientData[0];
    },
  });
  const {
    data: country = null,
    error,
    isLoading: isLoadingCountry,
    refetch,
  } = useQuery<CountryType | null>({
    queryKey: ["country", client?.countryId],
    queryFn: async () => {
      if (!client?.countryId) {
        return null;
      }
      const country: CountryType = await getCountryData(client.countryId);
      return country;
    },
  });

  const { data: admPrintingCosts = null, isLoading: isLoadingAdmPrintingCosts } = useQuery<PrintingCostsType | null>({
    queryKey: ["admPrintingCosts", client?.countryId],
    queryFn: async () => {
      if (!client?.countryId) {
        return null;
      }
      const admPrintingTable = await getAdmClientsPricings(client.countryId);
      const admPrintingCosts: PrintingCostsType = await formatPrintingCosts(admPrintingTable);
      return admPrintingCosts;
    },
  });

  const isLoading = isLoadingCountry || isLoadingAdmPrintingCosts || isLoadingClient;

  return (
    <ClientConfigContext.Provider
      value={{
        client,
        country,
        refetchCountry: refetch,
        isLoading,
        errorFetchingCountry: error as Error | null,
        admPrintingCosts,
      }}
    >
      {children}
    </ClientConfigContext.Provider>
  );
};

export const useClientConfig = () => {
  const context = useContext(ClientConfigContext);
  if (context === undefined) {
    throw new Error("useClientData must be used within a ClientDataProvider");
  }
  return context;
};
