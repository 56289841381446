import { useEffect, useMemo, useState } from "react";
import { checkIsNeedUpdateLastActiveStepState } from "utils";
import { CAMPAIGN_SUBTYPES_ENUM } from "shared/constants";
import { updateCampaignExtraData } from "graphQL/mutations/campaignExtraData";
import { DESIGN_STEPS } from "../Design";
import { useCampaignData } from "../../context/CampaignDataContext";
import { ADM_NAVIGATION, ADM_STEP } from "../../constants";
import { ADMCampaignData } from "../../types";
import { TriggerAnalyticsFn } from "../../hooks/usePosthogAnalytics/usePosthogAnalytics";
import { CampaignDetailsFormData } from "../../schemas/campaignDetails";

type UseDesignPageProps = {
  isFlyerUploaded: boolean;
  campaignData: ADMCampaignData | null;
  refetchCampaign: () => void;
  onSubmit: () => void;
  triggerAnalyticsEvent: TriggerAnalyticsFn;
  onSubmitCampaignDetails: (data: CampaignDetailsFormData) => Promise<void>;
};

export const useDesignPage = ({
  isFlyerUploaded,
  campaignData,
  refetchCampaign,
  triggerAnalyticsEvent,
  onSubmit,
  onSubmitCampaignDetails,
}: UseDesignPageProps) => {
  const { activeTabIndex, setActiveTabIndex, shouldDisplayDatesAlert } = useCampaignData();
  const [currentStep, setCurrentStep] = useState(DESIGN_STEPS.DESIGN_SELECTION);
  const [isLoading, setIsLoading] = useState(false);
  const [isDetailsCardOpen, setIsDetailsCardOpen] = useState(false);
  const [isDesignCardOpen, setIsDesignCardOpen] = useState(false);
  const [isSelectFlyerModalOpen, setFlyerSelectModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isOrderDetailsModalOpen, setIsOrderDetailsModalOpen] = useState(false);
  const [isTermsOfServiceModalOpen, setIsTermsOfServiceModalOpen] = useState(false);

  const isCheckoutEnabled = useMemo(() => {
    return isFlyerUploaded && !shouldDisplayDatesAlert;
  }, [isFlyerUploaded, shouldDisplayDatesAlert]);

  const isNextEnabled = useMemo(() => {
    switch (currentStep) {
      case DESIGN_STEPS.DESIGN_SELECTION:
        return isFlyerUploaded;
      case DESIGN_STEPS.CAMPAIGN_DETAILS:
        return isCheckoutEnabled;
      default:
        return false;
    }
  }, [currentStep, isFlyerUploaded]);

  const updateLastActiveStep = async () => {
    const lastActiveStep = ADM_NAVIGATION[1].step;
    const shouldUpdateLastActiveStep = checkIsNeedUpdateLastActiveStepState({
      stateLastActiveStep: campaignData?.lastActiveStep,
      newLastActiveStep: lastActiveStep,
      channel: campaignData?.subtype,
    });
    if (shouldUpdateLastActiveStep) {
      await updateCampaignExtraData({ campaignId: campaignData?.id, lastActiveStep });
      refetchCampaign();
    }
  };

  useEffect(() => {
    const lastActiveStep = campaignData?.lastActiveStep;
    if (lastActiveStep === ADM_STEP.DESIGN && activeTabIndex !== 1) {
      setCurrentStep(DESIGN_STEPS.CAMPAIGN_DETAILS);
    }
  }, [campaignData?.id]);

  useEffect(() => {
    if (activeTabIndex === 1) {
      setIsDetailsCardOpen(false);
      setIsDesignCardOpen(true);
    } else if (activeTabIndex === 2) {
      setIsDetailsCardOpen(true);
      setIsDesignCardOpen(false);
    }
  }, [activeTabIndex]);

  useEffect(() => {
    switch (currentStep) {
      case DESIGN_STEPS.DESIGN_SELECTION:
        setActiveTabIndex(ADM_NAVIGATION.findIndex((item) => item.step === ADM_STEP.DESIGN));
        break;
      case DESIGN_STEPS.CAMPAIGN_DETAILS:
        setActiveTabIndex(ADM_NAVIGATION.findIndex((item) => item.step === ADM_STEP.SUBMIT));
        updateLastActiveStep();
        break;
    }
  }, [currentStep]);

  const onClickSecondaryButton = () => {
    setIsOrderDetailsModalOpen(true);
    triggerAnalyticsEvent("Opened order details", {
      channel: CAMPAIGN_SUBTYPES_ENUM.ADM,
    });
  };

  const onEditFlyerFormat = () => {
    setFlyerSelectModalOpen(true);
    triggerAnalyticsEvent("Clicked to change ADM piece format on design page");
  };

  const handleContinueToCheckout = () => {
    setIsTermsOfServiceModalOpen(true);
    setIsOrderDetailsModalOpen(false);
    triggerAnalyticsEvent("Proceeded to campaign checkout", {
      channel: CAMPAIGN_SUBTYPES_ENUM.ADM,
    });
  };

  const onClickNextButton = () => {
    switch (currentStep) {
      case DESIGN_STEPS.DESIGN_SELECTION:
        setCurrentStep(DESIGN_STEPS.CAMPAIGN_DETAILS);
        triggerAnalyticsEvent("Continued to campaign details", {
          channel: CAMPAIGN_SUBTYPES_ENUM.ADM,
        });
        break;
      case DESIGN_STEPS.CAMPAIGN_DETAILS:
        handleContinueToCheckout();
        break;
    }
  };

  const handleCampaignSubmission = async (formData: CampaignDetailsFormData) => {
    setIsLoading(true);
    setIsTermsOfServiceModalOpen(false);
    setIsOrderDetailsModalOpen(false);
    triggerAnalyticsEvent("Clicked to submit campaign", {
      channel: CAMPAIGN_SUBTYPES_ENUM.ADM,
    });
    await onSubmitCampaignDetails(formData);
    await onSubmit();
    setIsLoading(false);
  };

  return {
    currentStep,
    isLoading,
    isDetailsCardOpen,
    setIsDetailsCardOpen,
    setCurrentStep,
    setIsLoading,
    isNextEnabled,
    isCheckoutEnabled,
    onClickSecondaryButton,
    onClickNextButton,
    onEditFlyerFormat,
    isDesignCardOpen,
    setIsDesignCardOpen,
    isSelectFlyerModalOpen,
    setFlyerSelectModalOpen,
    isPreviewModalOpen,
    setIsPreviewModalOpen,
    isOrderDetailsModalOpen,
    setIsOrderDetailsModalOpen,
    handleContinueToCheckout,
    isTermsOfServiceModalOpen,
    setIsTermsOfServiceModalOpen,
    handleCampaignSubmission,
    triggerAnalyticsEvent,
  };
};
