import { Box, CircularProgress } from "@material-ui/core";

import { CampaignInfoFormData } from "../../schemas/campaignInformation";
import { CampaignDetailsSection } from "./components/CampaignDetailsSection";
import { TargetingSection } from "./components/TargetingSection";

import "./CampaignSummaryCard.css";
import { TotalSection } from "./components/TotalSection";
import { PrimaryButton } from "../../components/PrimaryButton";
import { Audience } from "../../types";
import { SecondaryButton } from "../../components/SecondaryButton";

export type CampaignSummaryCardProps = {
  buttonText: string;
  secondaryButtonText?: string;
  campaign: Partial<CampaignInfoFormData>;
  flyerQuote: {
    flyersAmount: number;
    totalCost: number;
  } | null;
  isNextEnabled: boolean;
  onClickNext: () => void;
  onClickSecondaryButton?: () => void;
  onEditFlyerFormat?: () => void;
  targeting?: Audience[];
  isLoading?: boolean;
  isReadOnly?: boolean;
};

export const CampaignSummaryCard = ({
  campaign,
  flyerQuote,
  buttonText,
  targeting,
  onClickNext,
  isNextEnabled,
  secondaryButtonText,
  onClickSecondaryButton,
  isLoading,
  onEditFlyerFormat,
  isReadOnly,
}: CampaignSummaryCardProps) => {
  return (
    <Box className="summaryCardContainer">
      <Box className="campaignSummaryContainer">
        {isLoading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="rgba(255, 255, 255, 0.7)"
            zIndex={1}
          >
            <CircularProgress size={40} color="secondary" />
          </Box>
        )}
        <CampaignDetailsSection isReadOnly={isReadOnly} campaign={campaign} onEditFlyerFormat={onEditFlyerFormat} />
        {!!targeting?.length && <TargetingSection audiences={targeting} />}
        {flyerQuote && <TotalSection total={flyerQuote.totalCost} units={flyerQuote.flyersAmount} />}
      </Box>
      <div className="summaryCardButtonContainer primaryButtonPadding">
        {!isReadOnly && (
          <PrimaryButton disabled={!isNextEnabled || isLoading} onClick={onClickNext}>
            {buttonText}
          </PrimaryButton>
        )}
      </div>
      <div className="summaryCardButtonContainer">
        {secondaryButtonText && (
          <SecondaryButton disabled={isLoading} onClick={onClickSecondaryButton}>
            {secondaryButtonText}
          </SecondaryButton>
        )}
      </div>
    </Box>
  );
};
