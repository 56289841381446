import React, { Fragment, useContext, useMemo, MouseEvent } from "react";
import cn from "classnames";
import { useTheme, useMediaQuery, makeStyles, Theme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

import { OppiziLogoSVG } from "components/icons/components/Oppizi";
import { Anchor } from "components/Anchor";

import style from "./style";
import { HeaderProps, NavigationItem } from "./types";
import { CurrentStepIcon } from "../../../../../static/media/current-step";

const useStyles = makeStyles((theme: Theme) => style(theme));

export const Header: React.FC<HeaderProps> = ({
  campaignData,
  handleHomeClick,
  homeUrl,
  tabs = [],
  activeTabIndex,
  setActiveTabIndex,
}) => {
  const classes = useStyles();
  const completedStepIndex = tabs.find((item: NavigationItem) => item.step === campaignData.lastActiveStep)?.index || 0;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1200));
  const headerWithSkipLogic = (isActive: boolean, canBeActive: boolean, itemIndex: number) => {
    return isActive ? (
      <CurrentStepIcon />
    ) : completedStepIndex >= itemIndex ? (
      <CheckCircleIcon />
    ) : (
      <RadioButtonUncheckedIcon color={isActive || canBeActive ? "inherit" : "disabled"} />
    );
  };

  return (
    <Box className={cn(classes.headerWrapper, classes.eddmHeaderWrapper, "mob-header-wrapper")}>
      <Grid container className={cn(classes.gridWrapper, "mob-grid-wrapper")}>
        <Box className={cn(classes.newNavigationBtnsWrapper, "home-back-icon mob-navigation-back-and-home")}>
          <Box className={cn(classes.homeBtnContainer)}>
            <Anchor
              href={homeUrl}
              onClick={(e: MouseEvent) => {
                const isCtrlClick = e.ctrlKey || e.metaKey;
                if (!isCtrlClick && !campaignData.isSubmitted) {
                  e.preventDefault();
                }
              }}
            >
              <OppiziLogoSVG onClick={handleHomeClick} />
            </Anchor>
          </Box>
        </Box>
        <Grid item className={classes.stepsContainer}>
          <Box
            style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}
            className={cn("mob-tabs-wrapper")}
          >
            {tabs.map((item: NavigationItem, itemIndex: number) => {
              const isActive = activeTabIndex === itemIndex;
              const canBeActive = completedStepIndex >= itemIndex;

              const isSelectable = canBeActive && !isActive;
              return (
                <Fragment key={itemIndex}>
                  <span
                    onClick={() => {
                      if (isSelectable) {
                        setActiveTabIndex(itemIndex);
                      }
                    }}
                    className={cn(classes.item, "mob-item", {
                      "display-none": !isActive,
                      [classes.displayNone]: isMobile && !isActive,
                      [classes.itemCanBeActive]: isSelectable && canBeActive,
                    })}
                  >
                    {!isMobile && headerWithSkipLogic(isActive, canBeActive, itemIndex)}
                    <Box
                      key={`${item.title}-${itemIndex}`}
                      className={cn(classes.tabWrapper, { [classes.mobTabWrapper]: isMobile })}
                    >
                      <Typography
                        className={cn(classes.tab, {
                          [classes.activeTab]: isActive || canBeActive,
                          [classes.link]: isSelectable,
                        })}
                      >
                        {item.title}
                      </Typography>
                      <StepDots totalSteps={tabs.length} currentStep={activeTabIndex} isMobile={isMobile} />
                    </Box>
                  </span>
                  {itemIndex < tabs.length - 1 && (
                    <Box
                      key={`seperator-${itemIndex}`}
                      className={cn(classes.separator, {
                        [classes.disabled]: !canBeActive,
                        [classes.displayNone]: isMobile,
                      })}
                    />
                  )}
                </Fragment>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

interface StepDotsProps {
  totalSteps: number;
  currentStep: number;
  isMobile: boolean;
}

const StepDots = ({ totalSteps, currentStep, isMobile }: StepDotsProps) => {
  const classes = useStyles();
  if (!isMobile) return null;
  return (
    <Box className={cn(classes.dotsContainer)}>
      {Array.from({ length: totalSteps }, (_, i) => (
        <span key={i + 1} className={cn(classes.dot, { [classes.activeDot]: i + 1 === currentStep })} />
      ))}
    </Box>
  );
};
