const ROUTING = {
  BLANK: "/",
  ACCESS_DENIED: "/access-denied",
  LOGIN: "/login",
  CAMPAIGN_CHANNEL: "/client/:clientId/campaign/:campaignId?/channel",
  CAMPAIGN: "/client/:clientId?/campaign/:campaignId?",
  CAMPAIGN_DETAILS: "/client/:clientId/campaign/:campaignId/details",
  CAMPAIGN_AUDIENCE: "/client/:clientId/campaign/:campaignId/audience",
  SUBMIT: "/client/:clientId/campaign/:campaignId/submit",
  SUCCESS: "/client/:clientId/campaign/:campaignId/success",
  FLYER: "/client/:clientId/flyer/:campaignId?",
  FLYER_BUILDER: "/client/:clientId/flyer/:campaignId?/builder",
  UPLOAD_FLYER: "/client/:clientId/flyer/:campaignId?/upload/:hideUploadedFile?",
  ERROR: "/error",
};

export const EDDM_ROUTING = {
  EDDM_CAMPAIGN: "/client/:clientId?/eddm/campaign/:campaignId?",
  EDDM_CAMPAIGN_DETAILS: "/client/:clientId/eddm/campaign/:campaignId/details",
  EDDM_CAMPAIGN_SEGMENTS: "/client/:clientId/eddm/campaign/:campaignId/segments",
  EDDM_SUBMIT: "/client/:clientId/eddm/campaign/:campaignId/submit",
  EDDM_SUCCESS: "/client/:clientId/eddm/campaign/:campaignId/success",
  EDDM_FLYER_BUILDER: "/client/:clientId/eddm/flyer/:campaignId?/builder",
  EDDM_FLYER: "/client/:clientId/eddm/flyer/:campaignId?",
  EDDM_UPLOAD_FLYER: "/client/:clientId/eddm/flyer/:campaignId?/upload",
};

export const ADM_ROUTING = {
  ADM_CAMPAIGN: "/client/:clientId?/adm/campaign/:campaignId?",
  ADM_CAMPAIGN_BUILDER: "/client/:clientId/adm/campaign/:campaignId/builder",
};

export default ROUTING;
