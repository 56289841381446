import { formatNumber } from "utils/formators";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { capitalize } from "lodash";
import { useState, useEffect, useRef, useMemo } from "react";
import { ResidencesIcon } from "components/icons/components/ResidencesIcon";
import { StarIcon } from "components/icons/components/Star";
import { calculateRouteFitScorePercentage } from "components/audience-map/utils";
import { makeStyles } from "@material-ui/core";
import cn from "classnames";
import { styles } from "./styles";
import { Accordion, AccordionTrigger, AccordionDetails } from "../../components/AddressesBlock/AddressBlock";
import { getTooltipPosition } from "./utils";

const useStyles = makeStyles(() => styles);

export const RouteStatisticsV2 = ({ route, selectedRoutes, costPerFlyer, mousePositionCoords, selectedTargets }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [mobRoute, setMobRoute] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [tooltipArrowPosition, setTooltipArrowPosition] = useState("bottom");
  const containerRef = useRef(null);
  const currentContainerRefBounds = useMemo(
    () => containerRef.current?.getBoundingClientRect(),
    [containerRef.current]
  );

  useEffect(() => {
    if (route) {
      setMobRoute(route);
    }
  }, [route]);

  useEffect(() => {
    if (containerRef.current && mousePositionCoords) {
      const { arrowPosition, newTooltipPosition } = getTooltipPosition({ containerRef, mousePositionCoords });
      setTooltipArrowPosition(arrowPosition);
      setTooltipPosition(newTooltipPosition);
    }
  }, [mousePositionCoords, currentContainerRefBounds]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const mailPieces = route ? route.HOME_COUNT + route.APT_COUNT : 0;
  const totalRouteCost = route && costPerFlyer ? mailPieces * costPerFlyer : 0;
  const fitScore = calculateRouteFitScorePercentage(route, selectedTargets);

  const processedCityName = route?.ZIP_NAME
    ? route.ZIP_NAME.split(" ")
        .map((part) => capitalize(part))
        .join(" ")
    : "";

  return (
    <>
      {/* desk */}
      {route && !!mousePositionCoords ? (
        <div
          ref={containerRef}
          className={cn(classes.statisticsBlockContainer, "mob-hidden")}
          style={{ top: `${tooltipPosition.y}px`, left: `${tooltipPosition.x}px` }}
        >
          {tooltipArrowPosition === "bottom" && <ArrowDropUpIcon className={classes.topTooltipArrow} />}
          {tooltipArrowPosition === "top" && <ArrowDropDownIcon className={classes.bottomTooltipArrow} />}
          {tooltipArrowPosition === "right" && <ArrowRightIcon className={classes.rightTooltipArrow} />}
          {tooltipArrowPosition === "left" && <ArrowLeftIcon className={classes.leftTooltipArrow} />}
          <div className={classes.statisticsSection}>
            <span className={classes.statisticsLabel}>Zip code - Route</span>
            <span className={classes.statisticsAmount}>{`${route.ZIP}-${route.CROUTE}`}</span>
          </div>
          <div className={classes.verticalSeparator} />
          {!!selectedTargets?.length && (
            <>
              <div className={classes.statisticsSection}>
                <span className={classes.statisticsLabel}>Fit score</span>
                <span className={classes.statisticsAmount}>
                  <StarIcon />
                  {fitScore}%
                </span>
              </div>
              <div className={classes.verticalSeparator} />
            </>
          )}
          <div className={classes.statisticsSection}>
            <span className={classes.statisticsLabel}>Residences</span>
            <span className={classes.statisticsAmount}>
              <ResidencesIcon />
              {route.HOME_COUNT + route.APT_COUNT}
            </span>
          </div>
          <div className={classes.verticalSeparator} />
          <div className={classes.statisticsSection}>
            <span className={classes.statisticsLabel}>Costs</span>
            <span className={classes.statisticsAmount}>
              <span>$</span> <span className={classes.cost}>{totalRouteCost.toFixed(2)}</span>
            </span>
          </div>
        </div>
      ) : null}

      {/* mob */}
      {selectedRoutes && selectedRoutes.length > 0 && mobRoute && (
        <div className="desk-hidden">
          <Accordion>
            <div
              style={{ position: "relative" }}
              className={cn("mob-statistics-block-container mob-address-container")}
            >
              <AccordionTrigger onClick={handleToggle}>
                <div className="mob-routestatistics-padding">
                  <p className="mob-route-title">Route statistics</p>
                  <KeyboardArrowDownIcon fontSize="medium" className={cn({ open: isOpen })} />
                </div>
              </AccordionTrigger>

              <AccordionDetails isOpen={isOpen}>
                {mobRoute ? (
                  <div style={{ padding: "5px 10px" }}>
                    <div className={classes.statisticsHeaderWrapper}>
                      <span>
                        {mobRoute.ZIP} - {mobRoute.CROUTE} {mobRoute.ZIP_NAME}
                      </span>
                    </div>
                    <div className={classes.statisticsRow}>
                      <span>Addresses</span>
                      <span>{formatNumber(mobRoute.HOME_COUNT + mobRoute.APT_COUNT) || "-"}</span>
                    </div>
                    <div className={classes.statisticsRow}>
                      <span>Business</span> <span>{formatNumber(mobRoute.BUSINESS_C) || "-"}</span>
                    </div>
                    <div className={classes.separator} />
                    <div className={classes.statisticsRow}>
                      <span>Total addresses (exc. businesses)</span>
                      <span>{formatNumber(mobRoute.TOTAL_COUN - mobRoute.BUSINESS_C) || "-"}</span>
                    </div>
                  </div>
                ) : null}
              </AccordionDetails>
            </div>
          </Accordion>
        </div>
      )}
    </>
  );
};
