import { useState, useEffect } from "react";
import CONFIG from "config/config";
import { useParams } from "react-router-dom";
import { UploadFile } from "shared/graphql/mutations/uploadFile";
import { useMutation, useQuery } from "@apollo/client";
import { UseGetFlyerInfoResponse } from "pages/flyer/flyerTypePage/useGetFlyerInfo";
import { GetSignedUrl } from "shared/graphql/queries/getSignedFileUrl";

export const useUploadFile = (flyerInfo: UseGetFlyerInfoResponse, setIsOpen: (value: boolean) => void) => {
  const { campaignId } = useParams();
  const [s3Key, setS3Key] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadFile] = useMutation(UploadFile);
  const [error, setError] = useState<string | null>(null);
  const { data: signedUrlData } = useQuery(GetSignedUrl, {
    variables: {
      bucket: CONFIG.PDF_FLYER_BUCKET,
      key: s3Key,
      operation: "PUT",
    },
    skip: !s3Key,
  });

  useEffect(() => {
    const continueUpload = async () => {
      if (!signedUrlData?.getSignedUrl || !file || !s3Key) {
        setUploadingFile(false);
        return;
      }

      try {
        setError(null);
        // Upload to S3 using signed URL
        const response = await fetch(signedUrlData.getSignedUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to upload file");
        }

        await uploadFile({
          variables: {
            file: s3Key,
            fileName: file.name,
            fileType: file.type,
            campaignId: `${campaignId}`,
          },
        });

        if (flyerInfo) {
          await flyerInfo.getFlyerInfo();
        }
        setIsOpen(false);
      } catch (error) {
        console.error("Error while uploading the file", error);
        setError("There was an error while uploading the file, please try again.");
      } finally {
        setS3Key(null);
        setUploadingFile(false);
      }
    };

    continueUpload();
  }, [signedUrlData]);

  const triggerFileUpload = (file: File) => {
    setUploadingFile(true);
    const key = `campaign-builder/${Date.now().toString()}/${file.name}`;
    setS3Key(key);
    setFile(file);
  };

  return { triggerFileUpload, uploadingFile, error };
};
