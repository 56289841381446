import { convertArrayToHashMap } from "../shared/libraries/dataSets";
import { searchFeaturesPFGQL } from "../graphQL";

export const getOverlayPath = ({ coords, isMultiPolygon }) => {
  const getMultiPolygonPath = (coords) => {
    if (Array.isArray(coords[0])) {
      return coords.map(getMultiPolygonPath);
    }
    return { lng: coords[0], lat: coords[1] };
  };

  if (!coords) return [];
  if (isMultiPolygon) {
    return getMultiPolygonPath(coords.map(([array]) => array));
  }

  return [
    coords[0].map(([lng, lat]) => ({
      lat,
      lng,
    })),
  ];
};

const getArea = (coordinates) => {
  let s = 0.0;
  let ring = coordinates;
  for (let i = 0; i < ring.length - 1; i++) {
    s += ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1];
  }
  return 0.5 * s;
};

const getCenterOfPolygon = (coordinates) => {
  if (!coordinates) {
    return { lng: 0, lat: 0 };
  }

  let c = [0, 0];
  let ring = coordinates;
  for (let i = 0; i < ring.length - 1; i++) {
    c[0] += (ring[i][0] + ring[i + 1][0]) * (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
    c[1] += (ring[i][1] + ring[i + 1][1]) * (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
  }
  let area = getArea(coordinates);
  c[0] /= area * 6;
  c[1] /= area * 6;
  return { lng: c[0], lat: c[1] };
};

export const formatFeaturesToOverlays = (rawFeatures, options = {}) => {
  const { isPostcode = false } = options;

  return rawFeatures.map((feature) => {
    const { id, name } = feature.properties;
    const isMultiPolygon = feature.geometry.type == "MultiPolygon";

    const paths = getOverlayPath({
      coords: feature.geometry.coordinates,
      isMultiPolygon,
    });

    const center = getCenterOfPolygon(
      isMultiPolygon ? feature.geometry.coordinates[0][0] : feature.geometry.coordinates[0]
    );

    return {
      id,
      paths,
      name,
      metadata: feature.properties,
      isPostcode,
      center,
      geometry: feature.geometry,
    };
  });
};

const useOverlaysFetch = ({ countryCode, processOverlays = (o) => o, disabled }) => {
  if (disabled) {
    return { loadData: () => ({ pageInfo: {}, data: {} }) };
  }

  const loadData = async ({ layer, area, exclusionZones, includeAddressCount, after, centerSphere, radius }) => {
    if (!countryCode || !layer || !((centerSphere && radius) || area)) return;
    const response = await searchFeaturesPFGQL({
      layer,
      countryCode,
      includeAddressCount,
      area,
      excludeGeometry: exclusionZones && Object.keys(exclusionZones).length ? exclusionZones : null,
      after,
      centerSphere,
      radius,
    });
    const { data: rawAreasData, errors } = await response.json();

    if (errors) throw errors[0];
    const options = {
      isPostcode: layer === "Postcodes",
      isIRIS: ["IRISBoundaries", "BEStatisticalSectors", "USCarrierRoutes"].includes(layer),
    };

    if (!rawAreasData || !rawAreasData.searchFeatures) return {};
    const formattedOverlays = formatFeaturesToOverlays(rawAreasData.searchFeatures.collection.features, options);
    const processedOverlays = processOverlays(formattedOverlays);

    const hashMap = convertArrayToHashMap(processedOverlays, "id");

    return {
      pageInfo: rawAreasData.searchFeatures.pageInfo,
      data: hashMap,
      totalCount: rawAreasData.searchFeatures.totalCount,
    };
  };

  return { loadData };
};
export default useOverlaysFetch;
