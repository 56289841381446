export const styles = {
  contentContainer: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "24px",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "32px",
    marginLeft: "-12px",
  },
  checkboxText: {
    fontSize: "12px",
    color: "#4F5B94",
  },
  checkboxTextLink: {
    color: "#D72B4F",
    textDecoration: "underline",
    cursor: "pointer",
  },
  modalBackground: {
    position: "absolute" as const,
    top: "0px",
    left: "0px",
    height: "100vh",
    width: "100vw",
    backgroundColor: "rgba(0,0,0,0.3)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999999,
  },
  modalContainer: {
    width: "548px",
    padding: "35px",
    display: "flex",
    flexDirection: "column" as const,
    backgroundColor: "#FFFFFF",
    border: "1px solid #E2E2FC",
    borderRadius: "8px",
  },
  closeIcon: {
    cursor: "pointer",
  },
  title: {
    fontSize: "24px",
    fontWeight: 700,
  },
  closeContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  footer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button: {
    position: "relative" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "229px",
    height: "40px",
    cursor: "pointer",
    border: "1px solid #E2E2FC",
    borderRadius: "4px",
    fontWeight: 700,
  },
  updateButton: {
    backgroundColor: "#EE4360",
    color: "white",
    borderColor: "#EE4360",
  },
  disabledButton: {
    backgroundColor: "#E4E4EA",
    color: "#C2C5DD",
    borderColor: "#E4E4EA",
    cursor: "default",
  },
  loading: {
    color: "white",
    position: "absolute" as const,
    top: "10px",
    right: "50px",
  },
};
