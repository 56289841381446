import { useState } from "react";
import cn from "classnames";
import { usePostHog } from "posthog-js/react";
import { EmitAnalyticsEvent } from "module/analytics/application/EmitAnalyticsEvent";
import { FlyerTypeCard } from "components/FlyerTypeSelect/FlyerTypeCard";
import { flyerTypesOptions } from "components/FlyerTypeSelect/flyerTypeOptions";
import { FlyerTypeData } from "components/FlyerTypeSelect/FlyerTypeCard/FlyerTypeCard";
import { updateCampaignExtraData } from "graphQL/mutations/campaignExtraData";
import { PossibleFlyerType } from "pages/flyer/builder/drawer";
import { DMDocumentFormats, DMDocumentNames } from "shared/constants";
import { ADM_FORMAT } from "pages/adm/builder/constants";
import { makeStyles, CircularProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useStore } from "store";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);

interface FlyerTypeModalProps {
  setFlyerSelectModalOpen: (value: boolean) => void;
  options?: FlyerTypeData[];
  variant?: "primary" | "secondary";
  flyerType: PossibleFlyerType;
  campaignId: string;
  clientName: string;
  userId: string;
  onModalSubmit?: () => void;
  submitEventName: string;
}

export const FlyerTypeModal = ({
  setFlyerSelectModalOpen,
  options,
  variant = "primary",
  flyerType,
  campaignId,
  clientName,
  userId,
  onModalSubmit,
  submitEventName,
}: FlyerTypeModalProps) => {
  const isPrimary = variant === "primary";
  const posthog = usePostHog();
  const postHogTracker = new EmitAnalyticsEvent(posthog);
  const classes = useStyles();
  const { updateCampaign } = useStore();
  const [newFlyerType, setNewFlyerType] = useState<PossibleFlyerType>(flyerType);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const disabled = newFlyerType === flyerType;

  const handleChangeFlyerType = (newFlyerType: PossibleFlyerType) => {
    setNewFlyerType(newFlyerType);
  };

  const handleClose = () => setFlyerSelectModalOpen(false);

  const onSubmit = async () => {
    if (disabled) return;
    setSubmitting(true);
    if (isPrimary) {
      updateCampaign({ flyerType: newFlyerType });
    }
    postHogTracker.run({
      eventName: submitEventName,
      userId,
      clientName,
      device: navigator.userAgent,
      additionalPayload: {
        campaignId,
        postcardFormat: isPrimary
          ? DMDocumentNames[newFlyerType as keyof typeof DMDocumentNames]
          : ADM_FORMAT[newFlyerType as keyof typeof ADM_FORMAT].replace("_", " "),
      },
    });
    await updateCampaignExtraData({
      campaignId,
      flyerType: newFlyerType,
    });
    setSubmitting(false);
    onModalSubmit && onModalSubmit();
    handleClose();
  };

  return (
    <div className={classes.modalBackground}>
      <div className={classes.modalContainer}>
        <div className={classes.closeContainer}>
          <CloseIcon onClick={handleClose} className={classes.closeIcon} />
        </div>
        <span className={classes.title}>Choose a postcard format</span>
        <div className={classes.optionsContainer}>
          {(options || flyerTypesOptions).map((flyerTypeOption: FlyerTypeData) => {
            return (
              <FlyerTypeCard
                key={flyerTypeOption.dimensions}
                flyerTypeData={flyerTypeOption}
                selectedFlyerType={newFlyerType}
                onClick={() => {
                  handleChangeFlyerType(flyerTypeOption.type as keyof typeof DMDocumentFormats);
                }}
              />
            );
          })}
        </div>
        <span className={classes.advice}>The format affects the total cost of your campaign. </span>
        <div className={classes.footer}>
          <button className={classes.button} onClick={handleClose}>
            Cancel
          </button>
          <button
            disabled={disabled}
            className={cn(classes.button, classes.updateButton, { [classes.disabledButton]: disabled && !submitting })}
            onClick={onSubmit}
          >
            Update
            {submitting && <CircularProgress size={16} className={classes.loading} />}
          </button>
        </div>
      </div>
    </div>
  );
};
