export const templateDefaultRecipients = [
  ["Daniel", "Carter", "157 Cedarbrook Lane", "Apt 3B", "Fairview", "TX", "75069"],
  ["Susan", "Reed", "932 Windward Drive", "", "Rockport", "CA", "93456-2043"],
  ["Ashley", "Simmons", "8421 Emerald Street", "", "Bellevue", "WA", "98006"],
  ["James", "Brooks", "6709 Willow Bend Road", "Apt 12", "Oakdale", "FL", "32609"],
];

export const audienceHeadersData = [
  { label: "First Name", value: "first_name", optional: false },
  { label: "Last Name", value: "last_name", optional: false },
  { label: "Address 1", value: "address_1", optional: false },
  { label: "Address 2", value: "address_2", optional: true },
  { label: "City", value: "city", optional: false },
  { label: "State", value: "state", optional: false },
  { label: "Zip Code", value: "zip_code", optional: false },
];
