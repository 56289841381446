import { useCallback, useState } from "react";
import { Box, CircularProgress, InputBaseProps, TextFieldProps } from "@material-ui/core";
import { InputFieldNameText } from "./components/InputFieldNameText";
import { InputFieldDescriptionText } from "./components/InputFieldDescriptionText";

import "./InputField.css";
import { CustomTextInput } from "./components/CustomTextInput";

export type InputFieldProps = Omit<InputBaseProps, "error"> & {
  className?: string;
  description?: string;
  error?: string | undefined;
  label: string;
  name: string;
  required?: boolean;
  loading?: boolean;
};

export const InputField = ({
  className,
  description,
  disabled,
  error,
  label,
  name,
  onBlur,
  onChange,
  placeholder,
  value,
  required,
  type,
  loading,
  ...rest
}: InputFieldProps) => {
  const [isFocus, setIsFocus] = useState(false);

  const handleChange = useCallback(
    (e) => {
      onChange && onChange(e);
    },
    [onChange]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      onBlur && onBlur(e);
      setIsFocus(false);
    },
    [onBlur]
  );

  const handleFocus = useCallback(() => {
    setIsFocus(true);
  }, []);
  return (
    <Box width="100%" className={className}>
      <Box className="inputDetailsContainer">
        <Box className="inputLabelContainer">
          <InputFieldNameText>
            {label}
            {required && <span className="required">*</span>}
          </InputFieldNameText>
          {description && <InputFieldDescriptionText>{description}</InputFieldDescriptionText>}
        </Box>
        <Box className="inputFieldLoadingContainer">
          {loading && (
            <Box className="inputFieldLoadingContainer">
              <CircularProgress size={14} color="secondary" />
            </Box>
          )}
        </Box>
      </Box>
      <CustomTextInput
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
        onBlur={handleBlur}
        onFocus={handleFocus}
        color="secondary"
        fullWidth
        type={type}
        error={!!error && !isFocus}
        {...rest}
      />
      {error && <Box className="errorContainer">{error}</Box>}
    </Box>
  );
};
