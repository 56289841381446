import cn from "classnames";
import { SelectedRoute, SuggestedRoute, RouteMetadata } from "module/eddm/dto";
import { formatNumber, toFixed2 } from "utils/formators";
import { usePostHog } from "posthog-js/react";
import { EmitAnalyticsEvent } from "module/analytics/application/EmitAnalyticsEvent";
import { GoogleMap } from "@react-google-maps/api";
import { RouteIcon } from "components/icons/components/RouteIcon";
import { DeleteIcon } from "static/media/delete-icon";
import CheckIcon from "@material-ui/icons/Check";
import { ResidencesIcon } from "components/icons/components/ResidencesIcon";
import { MAP_TYPES } from "pages/constants";
import {
  updateSuggestedRoutesOnDataLayer,
  handleHoverMultipleSelectedRoutes,
  handleHoverSelectedMissionUpdate,
} from "components/audience-map/utils";
import { getOverlayPath } from "Hooks/useOverlaysFetch";
import { makeStyles } from "@material-ui/core";
import { SuggestionStart } from "static/media";
import { useStore } from "store";
import { styles } from "./styles";
import { SuggestedRouteCard } from "./components/index";

const useStyles = makeStyles(() => styles);

interface SuggestionAddressCardProps {
  suggestedRoutes: SuggestedRoute[];
  setSelectedRoutes: (selectedRoutes: SelectedRoute[] | ((selectedRoutes: SelectedRoute[]) => SelectedRoute[])) => void;
  googleMap: GoogleMap | null;
  setShouldAutoSave: (shouldAutoSave: boolean) => void;
  setIsSingleRouteSelection: (isSingleSelection: boolean) => void;
  setIsUndoOrRedo: (isUndoOrRedo: boolean) => void;
}

export const SuggestedRoutesBlock = ({
  suggestedRoutes,
  setSelectedRoutes,
  googleMap,
  setShouldAutoSave,
  setIsSingleRouteSelection,
  setIsUndoOrRedo,
}: SuggestionAddressCardProps) => {
  const classes = useStyles();
  const {
    user,
    client: { name: clientName },
    campaign: { id: campaignId },
    costsCalculationData: { costPerFlyer },
    setSuggestedRoutes,
    setSuggestionsEnabled,
    suggestionsEnabled,
    dismissedSuggestedRoutes,
    setDismissedSuggestedRoutes,
  } = useStore();
  const posthog = usePostHog();
  const posthogEventTracker = new EmitAnalyticsEvent(posthog);

  const totalSuggestedAddresses = suggestedRoutes.reduce((acc, suggestedRoute) => {
    acc += suggestedRoute.properties.APT_COUNT + suggestedRoute.properties.HOME_COUNT;
    return acc;
  }, 0);

  const handleRouteDelete = (route: SuggestedRoute) => {
    if (!route || !googleMap) return;
    const updatedRoutes = suggestedRoutes.filter((suggestedRoute) => suggestedRoute._id !== route._id);
    let newSuggestionsEnabled = suggestionsEnabled;
    posthogEventTracker.run({
      eventName: "Dismissed one suggested route",
      userId: user?.id,
      clientName,
      device: navigator.userAgent,
      additionalPayload: {
        campaignId,
        fitScore: `${route.suitabilityScore}%`,
      },
    });
    if (!dismissedSuggestedRoutes.find((dismissedRoute) => dismissedRoute._id === route._id)) {
      setDismissedSuggestedRoutes([...dismissedSuggestedRoutes, route]);
    }
    if (!updatedRoutes.length) {
      newSuggestionsEnabled = false;
      setSuggestionsEnabled(newSuggestionsEnabled);
    }
    // @ts-ignore
    updateSuggestedRoutesOnDataLayer({
      // @ts-ignore
      dataLayer: googleMap.data,
      // @ts-ignore
      suggestedRoutes: updatedRoutes,
      suggestionsEnabled: newSuggestionsEnabled,
    });
    setSuggestedRoutes(updatedRoutes);
  };

  const handleSelectSuggestedRoutes = () => {
    setSuggestionsEnabled(false);
    setIsUndoOrRedo(false);
    setShouldAutoSave(true);
    posthogEventTracker.run({
      eventName: "Selected all suggested routes",
      userId: user?.id,
      clientName,
      device: navigator.userAgent,
      additionalPayload: {
        campaignId,
        fitScores: suggestedRoutes.map((suggestedRoute) => `${suggestedRoute.suitabilityScore}%`).join(", "),
      },
    });
    //@ts-ignore
    googleMap?.data.forEach((feature) => {
      const isMission = feature.getProperty("type") === "mission";
      const featureId = feature.getProperty("id");
      const isSuggestedFeature = suggestedRoutes.find((suggestedRoute) => suggestedRoute._id === featureId);
      if (!!isSuggestedFeature && isMission) {
        feature.setProperty("selected", true);
        feature.setProperty("suggested", false);
      }
    });
    setSelectedRoutes((routes: SelectedRoute[]): SelectedRoute[] => {
      const updatedRoutes: SelectedRoute[] = [...routes];
      suggestedRoutes.forEach((route: SuggestedRoute) => {
        if (!updatedRoutes.find((r: SelectedRoute) => r.id === route._id)) {
          updatedRoutes.push({
            id: route._id,
            paths: getOverlayPath({
              coords: route.geometry.coordinates,
              isMultiPolygon: route.geometry.type == "MultiPolygon",
            }),
            type: MAP_TYPES.POLYGON,
            metadata: route.properties as RouteMetadata,
          });
        }
      });
      return updatedRoutes;
    });
  };

  const handleRouteSelect = (route: SuggestedRoute) => {
    if (!route || !googleMap) return;
    setIsSingleRouteSelection(true);
    setIsUndoOrRedo(false);
    setShouldAutoSave(true);
    //@ts-ignore
    googleMap?.data.forEach((feature) => {
      const featureId = feature.getProperty("id");
      if (featureId === route._id) {
        feature.setProperty("selected", true);
        feature.setProperty("suggested", false);
      }
    });
    posthogEventTracker.run({
      eventName: "Selected one suggested route",
      userId: user?.id,
      clientName,
      device: navigator.userAgent,
      additionalPayload: {
        campaignId,
        fitScore: `${route.suitabilityScore}%`,
      },
    });
    setSelectedRoutes((routes: SelectedRoute[]): SelectedRoute[] => {
      const updatedRoutes: SelectedRoute[] = [...routes];
      if (!updatedRoutes.find((r: SelectedRoute) => r.id === route._id)) {
        updatedRoutes.push({
          id: route._id,
          paths: getOverlayPath({
            coords: route.geometry.coordinates,
            isMultiPolygon: route.geometry.type == "MultiPolygon",
          }),
          type: MAP_TYPES.POLYGON,
          metadata: route.properties as RouteMetadata,
        });
      }
      return updatedRoutes;
    });
  };

  const handleDismissAllSuggestedRoutes = () => {
    setDismissedSuggestedRoutes([...dismissedSuggestedRoutes, ...suggestedRoutes]);
    posthogEventTracker.run({
      eventName: "Dismissed all suggested routes",
      userId: user?.id,
      clientName,
      device: navigator.userAgent,
      additionalPayload: {
        campaignId,
        fitScores: suggestedRoutes.map((suggestedRoute) => `${suggestedRoute.suitabilityScore}%`).join(", "),
      },
    });
    //@ts-ignore
    googleMap?.data.forEach((feature) => {
      const isMission = feature.getProperty("type") === "mission";
      const isSuggested = feature.getProperty("suggested");
      if (isSuggested && isMission) {
        feature.setProperty("suggested", false);
      }
    });
    setSuggestedRoutes([]);
    setSuggestionsEnabled(false);
  };

  return (
    <div className={cn(classes.addressCardContainer)}>
      <span className={classes.topTriangle}></span>
      {suggestedRoutes.length && (
        <div className={classes.buttons}>
          <button className={classes.dismissAllButton} onClick={handleDismissAllSuggestedRoutes}>
            Dismiss all
            <DeleteIcon disabled={false} width={"16"} height={"16"} />
          </button>
          <button className={classes.addAllButton} onClick={handleSelectSuggestedRoutes}>
            Add all
            <CheckIcon fontSize="small" className={classes.checkIcon} />
          </button>
        </div>
      )}
      <div className={classes.addressHeader}>
        <div
          className={cn(classes.addressTitle)}
          onMouseOver={() => {
            handleHoverMultipleSelectedRoutes({
              mapInit: googleMap,
              selectedRoutes: suggestedRoutes.map((route) => ({ ...route, id: route._id })),
            });
          }}
          onMouseLeave={() => handleHoverSelectedMissionUpdate({ mapInit: googleMap, hoveredMission: null })}
        >
          <SuggestionStart stroke="#EE4360" />
          <span className={classes.name}>Suggested routes</span>
        </div>
      </div>
      <div
        className={cn(classes.addressStatistics, {
          [classes.addressStatisticsEmpty]: !suggestedRoutes?.length,
        })}
      >
        <div className={classes.statisticsValueContainer}>
          <span className={cn(classes.statisticLabel)}>Routes</span>
          <span className={cn(classes.statisticsValue)}>
            <RouteIcon stroke="#2E3860" /> {suggestedRoutes?.length || 0}
          </span>
        </div>
        <div className={classes.statisticsValueContainer}>
          <span className={cn(classes.statisticLabel)}>Residences</span>
          <span className={cn(classes.statisticsValue)}>
            <ResidencesIcon stroke="#2E3860" />
            {formatNumber(totalSuggestedAddresses)}
          </span>
        </div>
        <div className={cn(classes.statisticsValueContainer, classes.statisticsCostValue)}>
          <span className={cn(classes.statisticLabel, classes.costLabel)}>Costs</span>
          <span className={cn(classes.costValue)}>
            $ {formatNumber(toFixed2(costPerFlyer * totalSuggestedAddresses))}
          </span>
        </div>
      </div>
      {!!suggestedRoutes?.length &&
        suggestedRoutes.map((route, i) => (
          <SuggestedRouteCard
            key={`${route.properties.ZIP}-${route.properties.CROUTE}`}
            route={route}
            className={cn({
              [classes.firstRoute]: i === 0,
              [classes.lastRoute]: i === suggestedRoutes.length - 1,
            })}
            handleRouteDelete={handleRouteDelete}
            handleRouteSelect={handleRouteSelect}
            googleMap={googleMap}
          />
        ))}
    </div>
  );
};
