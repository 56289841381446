export const Download = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1009 12.8633L10.1009 2.8291"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5312 10.4238L10.1013 12.8638L7.67125 10.4238"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9611 6.77344H14.7386C16.4344 6.77344 17.8086 8.1476 17.8086 9.84427V13.9143C17.8086 15.6059 16.4378 16.9768 14.7461 16.9768H5.46276C3.76693 16.9768 2.39193 15.6018 2.39193 13.9059L2.39193 9.8351C2.39193 8.14427 3.76359 6.77344 5.45443 6.77344L6.23943 6.77344"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
