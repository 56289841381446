import cn from "classnames";
import { useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { FlyerTypeCard, FlyerTypeData } from "components/FlyerTypeSelect/FlyerTypeCard";
import { Download } from "static/media/download";
import { useMutation } from "@apollo/client";
import { DragAndDropArea } from "pages/flyer/flyerTypePage/components/DragAndDropArea";
import { UseGetFlyerInfoResponse } from "pages/flyer/flyerTypePage/useGetFlyerInfo";
import { DeleteFile } from "shared/graphql/mutations/deleteFile";
import { CAMPAIGN_SUBTYPES_ENUM } from "shared/constants";
import { DesignThumbnailPreviewer } from "../DesignThumbnailPreviewer";
import { FileRejection } from "../../Design/hooks/useADMDesignDragAndDrop";
import { FORMAT_OPTIONS, ADM_FORMAT, ADM_FORMAT_PREVIEW, ADM_FORMATS_TEMPLATES } from "../../constants";
import { useCampaignData } from "../../context/CampaignDataContext";
import classes from "./DesignSelectionCard.module.css";
import { ToggleText } from "../ToggleText";
import { TitleText } from "../TitleText";
import checkCircleIcon from "../../../../../static/media/check-circle.svg";
import { TriggerAnalyticsFn } from "../../hooks/usePosthogAnalytics/usePosthogAnalytics";

interface DesignSelectionCardProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  triggerAnalyticsEvent: TriggerAnalyticsFn;
  setFlyerSelectModalOpen: (value: boolean) => void;
  files: File[];
  fileError: string | null;
  setFiles: (files: File[]) => void;
  onDrop: (acceptedFiles: File[]) => void;
  onDropRejected: (rejectedFiles: FileRejection[]) => void;
  flyerInfo: UseGetFlyerInfoResponse;
  isReadOnly?: boolean;
}

export const DesignSelectionCard = ({
  isOpen,
  setIsOpen,
  setFlyerSelectModalOpen,
  files,
  setFiles,
  fileError,
  onDrop,
  onDropRejected,
  flyerInfo,
  triggerAnalyticsEvent,
  isReadOnly,
}: DesignSelectionCardProps) => {
  const { campaignData, campaignId } = useCampaignData();
  const [isDeletingFile, setDeletingFile] = useState(false);
  const [deleteFile] = useMutation(DeleteFile);
  const flyerType = campaignData ? campaignData.flyerType : ADM_FORMAT.POSTCARD_9x6;
  const currentFlyerTypeOption = FORMAT_OPTIONS.find((option) => option.value === flyerType);

  const { thumbnailWidth, thumbnailHeight } = ADM_FORMAT_PREVIEW[flyerType as ADM_FORMAT];

  const getCurrentFlyerTypeData = () => {
    const currentFlyerType =
      FORMAT_OPTIONS.find((option) => option.value === campaignData?.flyerType) || FORMAT_OPTIONS[0];

    return {
      dimensions: currentFlyerType.label,
      label: currentFlyerType.topText,
      worth: currentFlyerType.bottomText,
      type: currentFlyerType.value,
      image: currentFlyerType.image,
    };
  };

  const handleDeleteFile = async () => {
    try {
      setDeletingFile(true);

      const flyerKey = flyerInfo?.flyerInfo?.flyerKey;
      if (flyerKey) {
        await deleteFile({
          variables: {
            fileKey: flyerKey,
            campaignId: `${campaignId}`,
          },
        });
        await flyerInfo.getFlyerInfo();
        setFiles([]);
      } else {
        throw new Error("There was an error while deleting the file");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeletingFile(false);
    }
  };

  const handleTemplateDownload = () => {
    const link = document.createElement("a");
    link.href = ADM_FORMATS_TEMPLATES[flyerType];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    triggerAnalyticsEvent("Downloaded design template file", {
      channel: CAMPAIGN_SUBTYPES_ENUM.ADM,
      artworkFormat: flyerType.replace("_", " "),
    });
  };

  return (
    <Grid className={cn(classes.designSelectionCardContainer, { [classes.closed]: !isOpen })} xs={12}>
      <Box className={classes.designSelectionCardHeaderContainer}>
        <div className={classes.titleContainer}>
          <TitleText>Design selection</TitleText>
          {!!flyerInfo?.flyerInfo?.s3Link && <img src={checkCircleIcon} alt="" className={classes.checkIcon} />}
        </div>

        <div className={classes.seeMoreToggle} onClick={() => setIsOpen(!isOpen)}>
          <ToggleText>See {isOpen ? "less" : "more"}</ToggleText>
          <KeyboardArrowDownIcon
            fontSize="small"
            className={cn(classes.toggleIcon, { [classes.toggleIconOpen]: isOpen })}
          />
        </div>
      </Box>
      <Box className={cn(classes.designSelectionContent)}>
        <span className={classes.designSectionSubtitle}>Upload your design in PDF format</span>
        <div className={classes.dragAndDropAndFormatWrapper}>
          <div className={classes.designFileWrapper}>
            <span>Design file: </span>
            {flyerInfo?.flyerInfo?.s3Link || flyerInfo?.isLoading ? (
              <div className={classes.previewWrapper}>
                <DesignThumbnailPreviewer
                  height={thumbnailHeight}
                  width={thumbnailWidth}
                  flyerInfo={flyerInfo?.flyerInfo}
                  isLoading={flyerInfo?.isLoading || isDeletingFile}
                  onDeleteFile={handleDeleteFile}
                  allowDelete={!isReadOnly}
                />
              </div>
            ) : (
              <div className={classes.dragAndDropWrapper}>
                <DragAndDropArea
                  files={files}
                  newVersion
                  onDrop={onDrop}
                  onDropRejected={onDropRejected}
                  fileType="application/pdf"
                  error={fileError}
                  secondLineMessage="Two sides in one PDF"
                  alwaysDesktop
                />
              </div>
            )}
          </div>
          <div className={classes.selectedFormatWrapper}>
            <span className={classes.selectedFormatTitle}>Selected format</span>
            <FlyerTypeCard
              flyerTypeData={getCurrentFlyerTypeData() as FlyerTypeData}
              onClick={() => {}}
              selectedFlyerType={campaignData?.flyerType || ""}
              variant="secondary"
            />
            {!isReadOnly && (
              <span className={classes.editButton} onClick={() => setFlyerSelectModalOpen(true)}>
                Edit
              </span>
            )}
          </div>
        </div>
        <div className={classes.uploadInstructions}>
          <Typography>Upload flyer design in PDF format. PDF file must:</Typography>
          <Typography>
            <div className={classes.customListBullet} /> Be in CMYK color profile
          </Typography>
          <Typography>
            <div className={classes.customListBullet} /> Contain both front & back sides of the flyer
          </Typography>
          <Typography>
            <div className={classes.customListBullet} /> Contain bleed line, indicating where the edges are cut.
          </Typography>
        </div>
        <button className={classes.downloadTemplateButton} onClick={handleTemplateDownload}>
          <Download /> Download Postcard {currentFlyerTypeOption?.label} template
        </button>
      </Box>
    </Grid>
  );
};
