const EditIcon2 = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none" {...props}>
    <g clipPath="url(#clip0_7062_10948)">
      <path
        d="M3.82812 10.7502L10.5781 4.00015C10.7094 3.86883 10.8136 3.71293 10.8847 3.54135C10.9558 3.36977 10.9923 3.18587 10.9923 3.00015C10.9923 2.81443 10.9558 2.63054 10.8847 2.45895C10.8136 2.28737 10.7094 2.13147 10.5781 2.00015C10.4468 1.86883 10.2909 1.76466 10.1193 1.69359C9.94774 1.62252 9.76384 1.58594 9.57812 1.58594C9.39241 1.58594 9.20851 1.62252 9.03693 1.69359C8.86535 1.76466 8.70945 1.86883 8.57812 2.00015L1.82812 8.75015M3.82812 10.7502L1.07812 11.5002L1.82812 8.75015M3.82812 10.7502L1.82812 8.75015"
        stroke="#4F5B94"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7062_10948">
        <rect width="12" height="12" fill="white" transform="translate(0.078125 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default EditIcon2;
