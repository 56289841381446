import { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import { Box } from "@material-ui/core";
import CalendarIcon from "static/media/calendar-icon";
import cn from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import { useStyles } from "./styles";

interface CustomInputProps {
  value?: string;
  onClick?: () => void;
  disabled?: boolean;
  onlyValue?: boolean;
  isUS?: boolean;
  ref?: React.Ref<HTMLSpanElement>;
}

const OutlinedCustomInput = forwardRef<HTMLSpanElement, CustomInputProps>(
  ({ value, onClick, disabled, onlyValue, isUS }, ref) => {
    const classes = useStyles();
    const mockFormat = isUS ? "mm / dd / yy" : "dd / mm / yy";

    return (
      <span className={classes.wrapper} ref={ref}>
        {onlyValue ? (
          <span>{value}</span>
        ) : (
          <Box pb={1} className={classes.datePickerContainer}>
            {disabled ? (
              <span className={classes.disabledText}>{value || mockFormat}</span>
            ) : (
              <span onClick={onClick} className={classes.text}>
                {value || mockFormat}
              </span>
            )}
            <CalendarIcon
              fill={disabled ? "#C2C8D1" : "black"}
              onClick={disabled ? undefined : onClick}
              className={disabled ? classes.calendarIconDisabled : classes.calendarIcon}
            />
          </Box>
        )}
      </span>
    );
  }
);

interface DatePickerProps {
  value: Date | null;
  onChange: (date: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  error?: string;
  onlyValue?: boolean;
  isUS?: boolean;
  outlined?: boolean;
  name?: string;
}

export const CustomDatePicker = ({
  value,
  onChange,
  minDate,
  maxDate,
  disabled,
  error,
  onlyValue,
  isUS,
  name,
}: DatePickerProps) => {
  const classes = useStyles();

  const filterDate = (date: Date) => {
    return date.getDay() !== 0; // Filter out Sundays
  };

  return (
    <div>
      <ReactDatePicker
        className={classes.datePickerContainer}
        dateFormat={isUS ? "MMMM dd, yyyy" : "dd / MM / yyyy"}
        selected={value}
        onChange={onChange}
        customInput={<OutlinedCustomInput onlyValue={onlyValue} isUS={isUS} />}
        minDate={minDate}
        maxDate={maxDate}
        name={name}
        disabled={disabled}
        wrapperClassName={cn(classes.wrapper, !!error && classes.errorContainer)}
        filterDate={filterDate}
      />
    </div>
  );
};
