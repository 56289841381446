import { useHistory } from "react-router";
import { usePostHog } from "posthog-js/react";
import { LoadingScreen } from "components/audience-map/components/LoadingScreen";
import { MAP_TYPES, CAMPAIGN_INTERNAL_STATUSES } from "pages/constants";
import { SelectedRoute, Route, DistributionLocation, Coordinates, RouteMetadata } from "module/eddm/dto";
import { CampaignExtraDataPayload } from "module/campaign/dto";
import { LocationLatLngType } from "components/place-autocomplete/type";
import { VisiblePostCodeType } from "store/types";
import { Box, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useEffect, useState, useRef, useMemo, useContext } from "react";
import { NextButton } from "components/next-button";
import { CustomTooltipContent } from "components/tooltip-content";
import cn from "classnames";
import { LightTooltip } from "components/light-tooltip";
import { ProgressBarContext } from "components/progress-bar";
import { checkIsNeedUpdateLastActiveStepState } from "utils";
import { generatePath } from "react-router-dom";
import { EDDM_ROUTING } from "config/routing";
import { radiusToZoom } from "./utils";
import { createQuote, updateCampaignExtraData, updateSelfServeCampaignData, updateCampaign } from "../../../../graphQL";
import { IntersectedRoutesMap, IntersectedRoute } from "./types";
import { styles } from "./stylesV2";
import { MAP_EXTRA_STYLES } from "../../options";
import { RouteStatistics } from "./components/RouteStatistics";
import { AreaStatistics } from "./components/RouteStatistics/AreaStatistics";
import { AddressBlockV2 } from "./components/AddressesBlock/AddressBlockV2";
import { useEDDMCostsCalculation } from "../../../../Hooks/useEDDMCostsCalculation";
import {
  onMapInit,
  renderPolygonsOnEddmMap,
  handleHoverSelectedMissionUpdate,
  renderCircleSelectorOnMap,
  removeCircleFromMap,
  calculateCircleRadius,
} from "../../utils.js";

import { useStore } from "../../../../store";

const useStyles = makeStyles(() => styles);
interface AudienceMapEDDMV2Props {
  radius: number;
  setRadius: (radius: number) => void;
  selectedRoutes: SelectedRoute[];
  setSelectedRoutes: (selectedRoutes: SelectedRoute[] | ((selectedRoutes: SelectedRoute[]) => SelectedRoute[])) => void;
  setShouldAutoSave: (shouldAutoSave: boolean) => void;
  drawingMode: null | string;
  setDrawingMode: (drawingMode: null | string) => void;
  circleSelectorRadius: number;
  isUndoOrRedo: boolean;
  setIsUndoOrRedo: (isUndoOrRedo: boolean) => void;
  routes: Record<string, Route>;
  setRoutes: (routes: Record<string, Route> | Record<string, unknown>) => void;
  fetchRoutesAndUpdateMap: (focusedLocation: DistributionLocation, radius: number) => void;
  setCircleSelectorRadius: (selectorRadius: number) => void;
  isCalculatingMultipleSelection: boolean;
  setIsCalculatingMultipleSelection: (calculatingMultipleSelection: boolean) => void;
  setIsSingleRouteSelection: (isSingleSelection: boolean) => void;
  googleMap: GoogleMap;
  setGoogleMap: (googleMap: null | GoogleMap) => void;
  handleAutocompleteChanged: (autocompleteResult: LocationLatLngType) => void;
  getFilteredPostCodes?: (key: string) => VisiblePostCodeType[];
  shouldHideStatistics: boolean;
  setShouldHideStatistics: (shouldHideStatistics: boolean) => void;
}
export const AudienceMapEDDMV2 = ({
  radius,
  setRadius,
  selectedRoutes,
  setSelectedRoutes,
  setShouldAutoSave,
  drawingMode,
  setDrawingMode,
  circleSelectorRadius,
  isUndoOrRedo,
  setIsUndoOrRedo,
  routes,
  setRoutes,
  fetchRoutesAndUpdateMap,
  setCircleSelectorRadius,
  isCalculatingMultipleSelection,
  setIsCalculatingMultipleSelection,
  setIsSingleRouteSelection,
  googleMap,
  setGoogleMap,
  handleAutocompleteChanged,
  getFilteredPostCodes,
  shouldHideStatistics,
  setShouldHideStatistics,
}: AudienceMapEDDMV2Props) => {
  const classes = useStyles();
  const posthog = usePostHog();
  const {
    country,
    map: { center, zoom, loading, loadingPercentage },
    campaign: { isSubmitted, internalStatus, id: campaignId, lastActiveStep, subtype },
    client: { id: clientId, name: clientName },
    user,
    updateMap,
    hoveredRoute,
    setHoveredRoute,
    distributionLocations,
    setDistributionLocations,
    setFocusedLocation,
    costsCalculationData,
  } = useStore();
  const { recalculateEDDMCampaignCost } = useEDDMCostsCalculation();
  const { totalCost, tax } = costsCalculationData?.detailedCost || {};
  const { costPerFlyer } = costsCalculationData || {};
  const isCircleSelectorActive = drawingMode === MAP_TYPES.CIRCLE;

  const focusedLocation = useMemo(
    () => distributionLocations.find((location) => location.isFocused),
    [distributionLocations]
  );
  const [googleMapKey, setGoogleMapKey] = useState(new Date().getTime());
  const [mousePositionCoords, setMousePositionCoords] = useState<{ x: number; y: number } | null>();

  const runProgressBar = useContext(ProgressBarContext);
  const history = useHistory();
  const [isCalculatingRoutesIntercection, setIsCalculatingRoutesIntercection] = useState(false);
  const hideLocationsPins = zoom >= 10;

  // useRef
  const clickSaveButtonRef = useRef(false);
  const circleFeatureRef = useRef(null);
  const intersectedRoutesRef = useRef(null);

  // useMemo
  const isNextActive = useMemo(() => {
    return !!costsCalculationData.flyersAmount && costsCalculationData.flyersAmount > 1000;
  }, [costsCalculationData.flyersAmount, isSubmitted]);

  const TooltipContent = useMemo(() => {
    if (!isNextActive) {
      return (
        <CustomTooltipContent
          title="Not enough addresses!"
          content="To proceed, please select at least 1000 addresses."
        />
      );
    }

    return "";
  }, [isNextActive]);

  // useCallback
  const saveCampaign = async (isAutoSave?: boolean) => {
    if (!isSubmitted && distributionLocations) {
      const payloadCampaignExtraData: CampaignExtraDataPayload = {
        campaignId,
        totalCosts: totalCost,
        flyersCount: costsCalculationData?.flyersAmount || 0,
        taxes: tax,
      };

      const isNeedUpdateLastActiveStep = checkIsNeedUpdateLastActiveStepState({
        stateLastActiveStep: lastActiveStep,
        newLastActiveStep: "segments",
        channel: subtype,
      });

      if (isNeedUpdateLastActiveStep) {
        payloadCampaignExtraData.lastActiveStep = "segments";
      }

      await updateCampaignExtraData(payloadCampaignExtraData);
      await updateSelfServeCampaignData({
        campaignId: campaignId,
        distributionLocations: distributionLocations,
      });
      if (isNeedUpdateLastActiveStep && !isAutoSave) {
        updateCampaign({ lastActiveStep: "segments" });
      }
      await setQuoteNumber(campaignId);
      if (!isAutoSave) {
        nextBtnClicked(campaignId);
      }
    }
  };

  const setQuoteNumber = async (campaignId: string) => {
    const quote = await createQuote(campaignId);
    updateCampaign({ quote });
  };

  const headerActions = {
    HOME: {
      action: () => {
        console.log("redirect home");
      },
    },
    NEXT: {
      isActive: true,
      action: async () => {
        if (isSubmitted) {
          runProgressBar(60);
          nextBtnClicked(campaignId);
          runProgressBar(80);
          runProgressBar(-1);
        } else {
          clickSaveButtonRef.current = false;
          await saveCampaign();
        }
      },
      hintPlacement: "bottom",
      hint: "",
    },
  };

  const nextBtnClicked = (id: string) => {
    return !internalStatus || internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT
      ? history.push(generatePath(EDDM_ROUTING.EDDM_UPLOAD_FLYER, { campaignId: id, clientId }))
      : history.push(generatePath(EDDM_ROUTING.EDDM_CAMPAIGN_DETAILS, { campaignId: id, clientId }));
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));

  const geocoder = useMemo(
    () => (window?.google?.maps?.Geocoder ? new window.google.maps.Geocoder() : null),
    [window.google]
  );
  // useEffect(() => {
  //   if (drawingManager) {
  //     drawingManager.setDrawingMode(drawingMode);
  //   }
  // }, [drawingMode, drawingManager]);

  useEffect(() => {
    if (geocoder && googleMap && !Object.values(routes).length && !focusedLocation) {
      geocoder.geocode({ address: country.name }, (results, status) => {
        if (status === "OK") {
          // @ts-ignore GoogleMap does not have setCenter set but it is actually working
          googleMap.setCenter(results[0].geometry?.location);
          updateMap({ zoom: 5 });
        }
      });
    }
  }, [geocoder, googleMap, center]);

  useEffect(() => {
    handleHoverSelectedMissionUpdate({ mapInit: googleMap, hoveredMission: hoveredRoute?.id || "" });
  }, [hoveredRoute]);

  useEffect(() => {
    if (Object.values(routes).length) {
      // LOOK FOR ALL THE SELECTED ROUTES IN NON FOCUSED LOCATION
      const selectedRoutesFromNonFocusedLocations = distributionLocations.reduce(
        (acc: SelectedRoute[], curr: DistributionLocation) => {
          if (curr.isFocused) {
            return acc;
          }
          return [...acc, ...curr.selectedRoutes];
        },
        []
      );

      // LOOK FOR THE SELECTED ROUTES THAT ARE PART OF THE ROUTES FETCHED FOR THE CURRENT FOCUSED LOCATIONS
      // AND REMOVE THE ROUTES THAT ARE ALREADY SELECTED IN SOME OTHER LOCATION
      const selectedRoutesOnCurrentFocusedLocation = Object.values(routes)
        .reduce((acc: SelectedRoute[], curr: Route) => {
          const selectedRoute: SelectedRoute | undefined = selectedRoutes.find((r: SelectedRoute) => r.id === curr.id);
          if (selectedRoute) {
            acc.push(selectedRoute);
          }
          return acc;
        }, [])
        .filter((r) => !selectedRoutesFromNonFocusedLocations.find((route) => route.id === r.id));
      // SAVE SELECTED ROUTES ON FOCUSED LOCATION AND REMOVE THE UNSELECTED ROUTES FROM OTHER LOCATIONS
      setDistributionLocations(
        distributionLocations.map((l) => {
          if (l.isFocused) {
            return { ...l, selectedRoutes: selectedRoutesOnCurrentFocusedLocation };
          } else {
            return {
              ...l,
              selectedRoutes: l?.selectedRoutes?.filter((r) => selectedRoutes.find((route) => route.id === r.id)) || [],
            };
          }
        })
      );
    }
  }, [selectedRoutes, routes]);

  useEffect(() => {
    // SEARCH NEW ROUTES DEPENDING ON SEARCHED LOCATION AND SELECTED RADIUS
    if (focusedLocation) {
      fetchRoutesAndUpdateMap(focusedLocation, focusedLocation.radius);
    }
  }, [focusedLocation?.name]);

  useEffect(() => {
    if (routes && Object.values(routes).length && googleMap) {
      renderPolygonsOnEddmMap({
        googleMap,
        routes,
        handleRouteSelect,
        selectedRoutes,
        isSubmitted,
        setHoveredRoute,
        setIsCalculatingMultipleSelection,
        intersectedRoutesRef,
        handleMultipleRoutesSelect,
        posthog,
        posthogBasePayload:
          user?.id && clientName && navigator.userAgent
            ? { userId: user?.id, clientName, device: navigator.userAgent }
            : null,
        setMousePositionCoords,
        selectedTargets: [],
        suggestedRoutes: [],
        suggestionsEnabled: false,
      });
      updateMap({ zoom: radiusToZoom[radius] });

      if (isUndoOrRedo && !isCircleSelectorActive) return;

      renderCircleSelectorOnMap({
        googleMap,
        circleSelectorRadius,
        circleFeatureRef,
        intersectedRoutesRef,
        setIsCalculatingRoutesIntercection,
        setMousePositionCoords,
      });
      setDrawingMode(MAP_TYPES.CIRCLE);
      setCircleSelectorRadius(calculateCircleRadius(radius));
    }
  }, [googleMap]);

  useEffect(() => {
    if (drawingMode === MAP_TYPES.CIRCLE && googleMap) {
      renderCircleSelectorOnMap({
        googleMap,
        circleSelectorRadius,
        circleFeatureRef,
        intersectedRoutesRef,
        setIsCalculatingRoutesIntercection,
        setMousePositionCoords,
      });
    } else if (drawingMode !== MAP_TYPES.CIRCLE && googleMap) {
      removeCircleFromMap({ googleMap, circleFeatureRef, setHoveredRoute, setMousePositionCoords });
    }
  }, [drawingMode, circleSelectorRadius]);

  useEffect(() => {
    // RESET GOOGLE MAP ON EVERY ROUTES FETCH
    if (focusedLocation?.selectedRoutes?.length && Object.values(routes).length) {
      const selectedLocationsToDeleteInFocusedLocation = focusedLocation.selectedRoutes.filter(
        (route) => !Object.values(routes).find((r) => r.id === route.id)
      );
      if (selectedLocationsToDeleteInFocusedLocation.length) {
        setSelectedRoutes((selectedRoutes: SelectedRoute[]): SelectedRoute[] => {
          return selectedRoutes.filter(
            (route) => !selectedLocationsToDeleteInFocusedLocation.find((r) => r.id === route.id)
          );
        });
      }
    }
    setGoogleMap(null);
    setGoogleMapKey(new Date().getTime());
  }, [routes]);

  useEffect(() => {
    recalculateEDDMCampaignCost();
    if (!distributionLocations?.length) {
      setRoutes({});
    }
  }, [distributionLocations]);

  const handleRouteSelect = ({
    routeId,
    paths,
    selected,
    metadata,
  }: {
    routeId: string;
    paths: Coordinates;
    selected: boolean;
    metadata: RouteMetadata;
  }) => {
    if (isSubmitted) {
      return;
    }
    setShouldAutoSave(true);
    setIsUndoOrRedo(false);
    setIsSingleRouteSelection(true);
    setSelectedRoutes((routes: SelectedRoute[]): SelectedRoute[] => {
      const currentMissionIndex = routes.findIndex((r) => r.id === routeId);
      if (selected && currentMissionIndex === -1) {
        return [
          ...routes,
          {
            id: routeId,
            paths,
            type: MAP_TYPES.POLYGON,
            metadata,
          },
        ] as SelectedRoute[];
      } else if (!selected && currentMissionIndex !== -1) {
        return routes.toSpliced(currentMissionIndex, 1);
      } else {
        return routes;
      }
    });
  };

  const handleMultipleRoutesSelect = ({
    newSelectedRoutesOnLocation,
  }: {
    newSelectedRoutesOnLocation: IntersectedRoutesMap;
  }) => {
    if (isSubmitted) {
      return;
    }
    setShouldAutoSave(true);
    setIsUndoOrRedo(false);
    setSelectedRoutes((routes: SelectedRoute[]): SelectedRoute[] => {
      const updatedRoutes: SelectedRoute[] = [...routes];
      newSelectedRoutesOnLocation.forEach((route: IntersectedRoute, routeId: string) => {
        if (!updatedRoutes.find((r: SelectedRoute) => r.id === routeId)) {
          updatedRoutes.push({
            id: routeId,
            paths: route.paths,
            type: MAP_TYPES.POLYGON,
            metadata: route.metadata,
          });
        }
      });
      return updatedRoutes;
    });
  };

  const onAddressCardClick = (address: DistributionLocation) => {
    setShouldAutoSave(true);
    setIsUndoOrRedo(false);
    setFocusedLocation(address);
    setRadius(address.radius);
  };

  const handleDeleteLocation = (deletedLocation: DistributionLocation) => {
    setShouldAutoSave(true);
    setIsUndoOrRedo(false);
    const newDistributionLocations = distributionLocations
      .filter((location) => location.name !== deletedLocation.name)
      .map((location, i, array) => {
        if (i === 0 && deletedLocation.isFocused) {
          setRadius(location.radius);
          return { ...location, isFocused: true };
        } else {
          return location;
        }
      });
    if (shouldHideStatistics && !newDistributionLocations.length) setShouldHideStatistics(false);
    setDistributionLocations(newDistributionLocations);

    if (deletedLocation?.selectedRoutes?.length) {
      setSelectedRoutes((selectedRoutes) => {
        return selectedRoutes.filter((route) => !deletedLocation?.selectedRoutes.find((r) => r.id === route.id));
      });
    }
  };

  const handleDragEnd = () => {
    setShouldHideStatistics(false);
    document.addEventListener(
      "mouseup",
      (event) => {
        setMousePositionCoords({ x: event.offsetX, y: event.offsetY });
      },
      { once: true }
    );
  };

  return (
    <Box className={classes.container}>
      <Box className={cn(classes.mapWrapper, { [classes.noLocationsOnMap]: !distributionLocations.length })}>
        {(loading || isCalculatingMultipleSelection) && (
          <div className={classes.locationsLoading}>
            <LoadingScreen
              loadingPercentage={loadingPercentage}
              isCalculatingMultipleSelection={isCalculatingMultipleSelection}
            />
          </div>
        )}
        <GoogleMap
          key={googleMapKey}
          onLoad={(map) => {
            onMapInit({ newMap: map, mapInit: googleMap, setMapInit: setGoogleMap });
          }}
          mapContainerStyle={{
            width: "100vw",
            height: isMobile ? "calc(100vh - 80px)" : "calc(100vh - 68px)",
            // height: "calc(100vh - 68px)",
            marginLeft: 0,
          }}
          onDragStart={() => {
            setShouldHideStatistics(true);
          }}
          onDragEnd={handleDragEnd}
          center={center}
          zoom={zoom}
          onZoomChanged={() => {
            // @ts-ignore
            if (googleMap && googleMap.getZoom() !== zoom) {
              updateMap({
                // @ts-ignore
                zoom: googleMap.getZoom(),
              });
            }
          }}
          options={{
            clickableIcons: false,
            zoomControl: true,
            disableDefaultUI: true,
            keyboardShortcuts: false,
            draggable: true,
            styles: MAP_EXTRA_STYLES,
            gestureHandling: "greedy",
          }}
          // onClick={handleMapClick}
        >
          <div className="mob-main-map-container">
            <AddressBlockV2
              addresses={distributionLocations}
              onAddressCardClick={onAddressCardClick}
              handleDeleteLocation={handleDeleteLocation}
              handleAutocompleteChanged={handleAutocompleteChanged}
              getFilteredPostCodes={getFilteredPostCodes}
              selectedRoutes={selectedRoutes}
              setSelectedRoutes={setSelectedRoutes}
              googleMap={googleMap}
              setShouldAutoSave={setShouldAutoSave}
              setIsUndoOrRedo={setIsUndoOrRedo}
              setIsSingleRouteSelection={setIsSingleRouteSelection}
              setShouldHideStatistics={setShouldHideStatistics}
            />
            {distributionLocations.length && hideLocationsPins ? (
              isCircleSelectorActive ? (
                !shouldHideStatistics &&
                //@ts-ignore
                !!intersectedRoutesRef?.current?.size && (
                  <AreaStatistics
                    isCalculatingRoutesIntercection={isCalculatingRoutesIntercection}
                    intersectedRoutesRef={intersectedRoutesRef}
                    mousePositionCoords={mousePositionCoords}
                  />
                )
              ) : (
                <RouteStatistics
                  route={hoveredRoute}
                  selectedRoutes={selectedRoutes}
                  costPerFlyer={costPerFlyer}
                  mousePositionCoords={mousePositionCoords}
                />
              )
            ) : null}
            {!!distributionLocations.length &&
              !hideLocationsPins &&
              distributionLocations.map((location) => {
                return (
                  <Marker
                    key={location.name}
                    position={{ lng: location.lng, lat: location.lat }}
                    onClick={() => {
                      setShouldAutoSave(true);
                      setIsUndoOrRedo(false);
                      setFocusedLocation(location);
                      setRadius(location.radius);
                    }}
                  />
                );
              })}
            <Box className={"mob-next-button desk-hidden"} style={loading ? { pointerEvents: "none" } : {}}>
              {!isNextActive ? (
                <LightTooltip title={TooltipContent} placement="top">
                  <span>
                    <NextButton isNextActive={isNextActive} />
                  </span>
                </LightTooltip>
              ) : (
                <NextButton
                  loading={!clickSaveButtonRef.current}
                  isNextActive={isNextActive}
                  onClick={headerActions.NEXT.action}
                />
              )}
            </Box>
          </div>
          {/* <DrawingManager
            onLoad={(loadedDrawingManager) => onDrawingManagerLoad({ loadedDrawingManager, setDrawingManager })}
            drawingMode={drawingMode}
            options={BROAD_AREAS_MAP_OPTIONS}
          /> */}
        </GoogleMap>
      </Box>
    </Box>
  );
};
