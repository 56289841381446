import { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { FlyerTypeModal } from "components/header/components/EDDMSummary/FlyerTypeModal/FlyerTypeModal";
import { FlyerTypeData } from "components/FlyerTypeSelect/FlyerTypeCard/FlyerTypeCard";
import { useGetFlyerInfo } from "pages/flyer/flyerTypePage/useGetFlyerInfo";
import {
  ADM_FORMAT,
  ADM_NAVIGATION,
  FORMAT_OPTIONS,
  ADM_STEP,
  MINIMUM_BUSINESS_DAYS,
} from "pages/adm/builder/constants";
import CheckoutSidebar from "pages/campaign/checkout/sidebar";
import CheckoutContainer from "pages/campaign/checkout/container";
import { useADMDesignDragAndDrop } from "./hooks/useADMDesignDragAndDrop";
import { useADMSubmit } from "./hooks/useADMSubmit";
import { AlertInvalidDates } from "../components/AlertInvalidDates";
import { DesignSelectionCard } from "../components/DesignSelectionCard";
import { CampaignSummaryCard } from "../components/CampaignSummaryCard";
import { DesignPreviewModal } from "../components/DesignPreviewModal";
import { useCampaignDetails } from "./hooks/useCampaignDetails";
import { useCampaignData } from "../context/CampaignDataContext";
import { CampaignDetailsCard } from "../components/CampaignDetailsCard";
import { useDesignPage } from "./hooks/useDesignPage";
import { parseTimestampStringDate } from "../utils";
import { OrderDetailsModal } from "../components/OrderDetailsModal";
import { TermsOfServiceModal } from "../components/TermsOfServiceModal";
import { useCampaignCostPreviewCalculation } from "../hooks/useCampaignCostPreviewCalculation";
import { usePosthogAnalytics } from "../hooks/usePosthogAnalytics/usePosthogAnalytics";
import "./Design.css";

export enum DESIGN_STEPS {
  DESIGN_SELECTION = "DESIGN_SELECTION",
  CAMPAIGN_DETAILS = "CAMPAIGN_DETAILS",
}

const BUTTON_TITLE_MAP = {
  [DESIGN_STEPS.DESIGN_SELECTION]: "Continue to Details",
  [DESIGN_STEPS.CAMPAIGN_DETAILS]: "Continue to Checkout",
};

export const Design = () => {
  const [files, setFiles] = useState<File[]>([]);
  const {
    campaignData,
    activeAudienceDetails,
    campaignId,
    user,
    refetchCampaign,
    shouldDisplayDatesAlert,
    setActiveTabIndex,
    country,
    isReadOnly,
  } = useCampaignData();
  const { triggerAnalyticsEvent } = usePosthogAnalytics(campaignId || "");
  const flyerInfo = useGetFlyerInfo();

  const { onDrop, onDropRejected, fileError } = useADMDesignDragAndDrop({
    setFiles,
    maxFileSize: 25,
    triggerAnalyticsEvent,
  });

  const { methods, onSubmitCampaignDetails } = useCampaignDetails();

  const { fetchStripeCheckout, onSubmit, showCheckout, setShowCheckout } = useADMSubmit();
  const {
    currentStep,
    isLoading,
    isDetailsCardOpen,
    setIsDetailsCardOpen,
    isNextEnabled,
    isCheckoutEnabled,
    onClickSecondaryButton,
    onEditFlyerFormat,
    isDesignCardOpen,
    setIsDesignCardOpen,
    isSelectFlyerModalOpen,
    setFlyerSelectModalOpen,
    isPreviewModalOpen,
    setIsPreviewModalOpen,
    isOrderDetailsModalOpen,
    setIsOrderDetailsModalOpen,
    onClickNextButton,
    handleContinueToCheckout,
    isTermsOfServiceModalOpen,
    setIsTermsOfServiceModalOpen,
    handleCampaignSubmission,
  } = useDesignPage({
    isFlyerUploaded: !!flyerInfo?.flyerInfo?.isFlyerUploaded,
    campaignData,
    refetchCampaign,
    onSubmit,
    triggerAnalyticsEvent,
    onSubmitCampaignDetails,
  });
  const { campaignCostPreview } = useCampaignCostPreviewCalculation();

  const flyerType = campaignData ? campaignData.flyerType : ADM_FORMAT.POSTCARD_9x6;

  useEffect(() => {
    if (files.length) {
      setIsPreviewModalOpen(true);
    }
  }, [files]);

  const checkoutComponent = () => {
    return <CheckoutContainer fetchStripeCheckout={fetchStripeCheckout} countryCode={country?.code} />;
  };

  return (
    <Grid container spacing={3}>
      <DesignPreviewModal
        files={files}
        setFiles={setFiles}
        setIsOpen={setIsPreviewModalOpen}
        isOpen={isPreviewModalOpen}
        flyerType={flyerType}
        flyerInfo={flyerInfo}
        onDrop={onDrop}
        onDropRejected={onDropRejected}
        triggerAnalyticsEvent={triggerAnalyticsEvent}
      />

      <CheckoutSidebar show={showCheckout} onClose={() => setShowCheckout(false)}>
        {showCheckout && checkoutComponent()}
      </CheckoutSidebar>

      {isSelectFlyerModalOpen && (
        <FlyerTypeModal
          setFlyerSelectModalOpen={setFlyerSelectModalOpen}
          flyerType={flyerType}
          campaignId={campaignId as string}
          userId={user.id}
          clientName={user.clientName}
          options={FORMAT_OPTIONS.map(
            (option) =>
              ({
                dimensions: option.topText,
                label: option.label,
                worth: option.bottomText,
                type: option.value,
                image: option.image,
              } as FlyerTypeData)
          )}
          onModalSubmit={() => refetchCampaign()}
          submitEventName="Changed ADM piece format on the modal"
          variant="secondary"
        />
      )}
      {isTermsOfServiceModalOpen && (
        <TermsOfServiceModal
          setIsTermsOfServiceModalOpen={() => setIsTermsOfServiceModalOpen(false)}
          onSubmit={() => handleCampaignSubmission(methods.getValues())}
        />
      )}
      <OrderDetailsModal
        isOpen={isOrderDetailsModalOpen}
        setIsOpen={setIsOrderDetailsModalOpen}
        submitter={user.firstName + " " + user.lastName}
        onContinueToCheckout={handleContinueToCheckout}
        flyerType={flyerType}
        flyerInfo={flyerInfo}
        isReadOnly={isReadOnly}
        isCheckoutEnabled={isCheckoutEnabled}
      />
      <Grid item xs={12} md={8}>
        <DesignSelectionCard
          isOpen={isDesignCardOpen}
          setIsOpen={setIsDesignCardOpen}
          setFlyerSelectModalOpen={setFlyerSelectModalOpen}
          files={files}
          setFiles={setFiles}
          onDrop={onDrop}
          onDropRejected={onDropRejected}
          fileError={fileError}
          flyerInfo={flyerInfo}
          triggerAnalyticsEvent={triggerAnalyticsEvent}
          isReadOnly={isReadOnly}
        />
        <CampaignDetailsCard
          form={methods}
          isOpen={isDetailsCardOpen}
          setIsOpen={setIsDetailsCardOpen}
          isReadOnly={isReadOnly}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <div className="summaryCardSectionContainer">
          <CampaignSummaryCard
            campaign={{
              campaignName: campaignData?.campaignName,
              campaignStartDate: parseTimestampStringDate(campaignData?.startDate) ?? undefined,
              format: campaignData?.flyerType,
            }}
            targeting={activeAudienceDetails}
            flyerQuote={{
              flyersAmount: activeAudienceDetails.reduce((acc, audience) => acc + audience.metadata.rowsAmount, 0),
              totalCost: campaignCostPreview?.total || 0,
            }}
            onClickNext={() => onClickNextButton()}
            buttonText={BUTTON_TITLE_MAP[currentStep]}
            isNextEnabled={isNextEnabled}
            isLoading={isLoading}
            onClickSecondaryButton={onClickSecondaryButton}
            secondaryButtonText="See Order Details"
            onEditFlyerFormat={onEditFlyerFormat}
            isReadOnly={isReadOnly}
          />
          {!isReadOnly && shouldDisplayDatesAlert && (
            <AlertInvalidDates
              dateLimit={MINIMUM_BUSINESS_DAYS}
              onDatesChange={() =>
                setActiveTabIndex(ADM_NAVIGATION.findIndex((item) => item.step === ADM_STEP.SEGMENTS))
              }
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};
