import { useMutation } from "@apollo/client";
import ROUTES from "config/routing";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { generatePath, useParams } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import { useGetFlyerInfo } from "pages/flyer/flyerTypePage/useGetFlyerInfo";
import { generateRoom as generateRoomMutation } from "shared/graphql/mutations/generateFlyerBuilderRoom";
import { ReactComponent as CreateDesign } from "static/media/design-flyer.svg";
import { ReactComponent as UploadDesign } from "static/media/upload-design.svg";
import { ReactComponent as SkipUploadDesign } from "static/media/skip-upload-design.svg";
import { ReactComponent as UseExistedDesign } from "static/media/use-exist-design.svg";
import { gtagWrapper } from "utils";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { FeatureFlagContext } from "context/featureFlagContext";
import { getRedirectedCampaigns } from "utils/getRedirectedCampaigns";
import { GtagOptions } from "../builder/drawer";
import { updateCampaignExtraData } from "../../../graphQL";
import { useStore } from "../../../store";

export enum FlyerStatus {
  flyerUploaded = "FLYER_UPLOADED",
  flyerCreated = "FLYER_CREATED",
  flyerAbsent = "FLYER_ABSENT",
}

export const useGetDataForPage = (flyerStatus: FlyerStatus) => {
  const history = useHistory();
  const posthog = usePostHog();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));

  const { campaignId, clientId: clientIdParam } = useParams();
  const readyToUpload = getRedirectedCampaigns(campaignId);

  const [generateRoom] = useMutation(generateRoomMutation);
  const flyerInfo = useGetFlyerInfo();
  const { featureFlags } = useContext(FeatureFlagContext);
  const isSkipEnabled = featureFlags.isSkipEnabled;

  const {
    campaign: { isSubmitted },
    updateCampaign,
    updateErrors,
  } = useStore();

  const generateRoomHandler = async () => {
    await generateRoom({ variables: { campaignId } });
  };

  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const firstLevelHeaders = {
    [FlyerStatus.flyerUploaded]: "You have uploaded a design for this campaign already",
    [FlyerStatus.flyerCreated]: "You have created a design for this campaign already",
    [FlyerStatus.flyerAbsent]: "Do you have a flyer design or would you like to create one?",
  };

  const secondLevelHeaders = {
    [FlyerStatus.flyerUploaded]: "Would you like to continue with this design, edit it or upload another one?",
    [FlyerStatus.flyerCreated]: "Would you like to continue with this design, edit it or upload another one?",
    [FlyerStatus.flyerAbsent]: "The flyer will be printed and distributed by us",
  };

  const firstLevelHeaderComponent = firstLevelHeaders[flyerStatus];
  const secondLevelHeaderComponent = secondLevelHeaders[flyerStatus];
  const showDesignPreview = flyerStatus === FlyerStatus.flyerCreated || flyerStatus === FlyerStatus.flyerUploaded;
  const isFlyerUploaded = flyerStatus === FlyerStatus.flyerUploaded;

  const gtagPush = (type: string, clientId: string, userId: string) => {
    const gtagOptions: GtagOptions = {
      event: "ss_design_type",
      client_id: clientId?.toString(),
      user_id: userId,
      campaign_id: campaignId,
      design_type: type,
    };
    gtagWrapper(gtagOptions);
  };

  const designButton = {
    icon: flyerStatus === FlyerStatus.flyerCreated ? UseExistedDesign : CreateDesign,
    title: flyerStatus === FlyerStatus.flyerCreated ? "Edit existing Design" : "Design a flyer",
    description:
      flyerStatus === FlyerStatus.flyerCreated
        ? "Use our flyer design tool to easily edit existing design"
        : "Use our flyer design tool to easily create a new design from scratch",
    action: async (clientId: string, userId: string) => {
      if (posthog) {
        posthog.capture("Selected Design Flyer", {
          event_category: "Flyer Builder",
        });
      }
      gtagPush("flyer_builder", clientId, userId);
      if (flyerStatus === FlyerStatus.flyerUploaded) {
        setIsConfirmationModalOpen(true);
      } else if (flyerStatus === FlyerStatus.flyerAbsent && !flyerInfo.flyerInfo?.activeRoomId) {
        await generateRoomHandler();
        history.push(generatePath(ROUTES.FLYER_BUILDER, { campaignId, clientId: clientIdParam }));
      } else {
        history.push(generatePath(ROUTES.FLYER_BUILDER, { campaignId, clientId: clientIdParam }));
      }
    },
  };

  const uploadButton = {
    icon: UploadDesign,
    title: isFlyerUploaded ? "Upload a Different design" : "Upload design",
    description: isFlyerUploaded
      ? "If you have another design in pdf format, you can upload it here"
      : "If you have design in pdf format, you can upload it here",
    action: async (clientId: string, userId: string) => {
      if (isSubmitted) {
        return;
      }
      if (posthog) {
        posthog.capture("Upload Design Selected", {
          event_category: "Flyer Builder",
        });
      }
      gtagPush("flyer_upload", clientId, userId);
      await updateCampaignExtraData({
        campaignId: campaignId,
        qrCodeLink: "",
      });
      updateCampaign({ qrCodeLink: "" });
      const uploadFlyerParams: Record<string, any> = { campaignId, clientId: clientIdParam };
      if (isFlyerUploaded) {
        uploadFlyerParams.hideUploadedFile = true;
      }
      history.push(generatePath(ROUTES.UPLOAD_FLYER, uploadFlyerParams));
    },
  };

  const useExistingDesignButton = {
    icon: CreateDesign,
    title: "Use Existing Design",
    description: "Continue with existing design",
    action: (clientId: string, userId: string) => {
      if (posthog) {
        posthog.capture("Selected Existing Design", {
          event_category: "Flyer Builder",
        });
      }
      gtagPush("flyer_existing", clientId, userId);

      if (isMobile) {
        history.push(generatePath(ROUTES.CAMPAIGN_DETAILS, { campaignId, clientId }));
      } else {
        flyerStatus === FlyerStatus.flyerCreated
          ? history.push(generatePath(ROUTES.FLYER_BUILDER, { campaignId, clientId: clientIdParam }))
          : history.push(generatePath(ROUTES.UPLOAD_FLYER, { campaignId, clientId: clientIdParam }));
      }
    },
  };

  const skipUploadingDesignButton = {
    icon: SkipUploadDesign,
    title: "Design it later",
    description: "Explore and set your campaign before designing or uploading the design.",
    action: (clientId: string) => {
      updateErrors({ notExistDesignFile: true });
      updateCampaign({ lastActiveStep: "design" });
      history.push(generatePath(ROUTES.CAMPAIGN_DETAILS, { campaignId, clientId }));
    },
  };

  return {
    firstLevelHeaderComponent,
    secondLevelHeaderComponent,
    showDesignPreview,
    buttons: [
      ...(flyerStatus !== FlyerStatus.flyerAbsent ? [useExistingDesignButton] : isMobile ? [] : [designButton]),
      uploadButton,
      ...(isSkipEnabled && flyerStatus === FlyerStatus.flyerAbsent && !readyToUpload
        ? [skipUploadingDesignButton]
        : []),
    ],
    isUploadPopupOpen,
    setIsUploadPopupOpen,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
  };
};
