import { Box } from "@material-ui/core";
import { RadioCardOption } from "../RadioCardField";
import checkCircleIcon from "../../../../static/media/check-circle.svg";
import { useStyles } from "./styles";

interface CustomRadioCardProps {
  option: RadioCardOption & {
    topText?: string;
    bottomText?: string;
  };
  selected: boolean;
  onClick: () => void;
  disabled?: boolean;
  onBlur?: () => void;
}

export const CustomRadioCard = ({ option, selected, onClick, disabled, onBlur }: CustomRadioCardProps) => {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.card} ${selected ? "selected" : ""} ${disabled ? "disabled" : ""}`}
      onClick={disabled ? undefined : onClick}
      onBlur={onBlur}
      tabIndex={disabled ? -1 : 0}
      role="radio"
      aria-checked={selected}
    >
      {selected && <img src={checkCircleIcon} alt="" className={classes.checkIcon} />}
      {option.image && (
        <Box className={classes.imageContainer}>
          <img src={option.image} alt="" className={classes.image} />
        </Box>
      )}
      <Box className={classes.content}>
        {option.topText && <span className={classes.topText}>{option.topText}</span>}
        <span className={classes.label}>{option.label}</span>
        {option.bottomText && <span className={classes.description}>{option.bottomText}</span>}
      </Box>
    </Box>
  );
};
