import { useEffect, useState, useMemo } from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CloseIcon from "@material-ui/icons/Close";
import { useDropzone } from "react-dropzone";
import { Drawer, CircularProgress } from "@material-ui/core";
import { Upload } from "static/media/upload";
import { PossibleFlyerType } from "pages/flyer/builder/drawer";
import { UseGetFlyerInfoResponse } from "pages/flyer/flyerTypePage/useGetFlyerInfo";
import { PrimaryButton } from "pages/adm/builder/components/PrimaryButton";
import { SecondaryButton } from "pages/adm/builder/components/SecondaryButton";
import { FileRejection } from "../../Design/hooks/useADMDesignDragAndDrop";
import { ADM_FORMAT_PREVIEW, ADM_FORMAT } from "../../constants";
import { ADMPDFPreview } from "./ADMPDFPreviewer/ADMPDFPreviewer";
import { useUploadFile } from "./hooks/useUploadFile";
import classes from "./DesignPreviewModal.module.css";
import { TriggerAnalyticsFn } from "../../hooks/usePosthogAnalytics/usePosthogAnalytics";

interface DesignPreviewModalProps {
  files: File[];
  setFiles: (files: File[]) => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  flyerType: PossibleFlyerType;
  flyerInfo: UseGetFlyerInfoResponse;
  onDrop: (acceptedFiles: File[]) => void;
  onDropRejected: (rejectedFiles: FileRejection[]) => void;
  triggerAnalyticsEvent: TriggerAnalyticsFn;
}

export const DesignPreviewModal = ({
  files,
  setFiles,
  isOpen,
  setIsOpen,
  flyerType,
  flyerInfo,
  onDrop,
  onDropRejected,
  triggerAnalyticsEvent,
}: DesignPreviewModalProps) => {
  const [fileBuffer, setFileBuffer] = useState<string | ArrayBuffer | null>(null);
  const { width, height, widthInInch, heightInInch } = ADM_FORMAT_PREVIEW[flyerType as ADM_FORMAT];
  const { triggerFileUpload, uploadingFile, error } = useUploadFile(flyerInfo, setIsOpen);
  const file = useMemo(() => files[0], [files]);
  const { open } = useDropzone({
    onDrop,
    onDropRejected,
    maxFiles: 1,
    accept: "application/pdf",
    multiple: false,
    maxSize: 25 * 1048576,
  });

  const getFileBuffer = async (file: File) => {
    if (!file) return setFileBuffer(null);
    const newFileBuffer = await file.arrayBuffer();
    setFileBuffer(newFileBuffer);
  };

  useEffect(() => {
    getFileBuffer(files[0]);
  }, [files]);

  const handleClose = () => {
    setFiles([]);
    setIsOpen(false);
  };

  const handleFileSubmit = async () => {
    if (!file) return;
    try {
      triggerFileUpload(file);
      triggerAnalyticsEvent("Confirmed ADM design file");
    } catch (e) {
      console.log("error uploading the file", e);
    }
  };

  const handleUploadNewFile = () => {
    open();
    triggerAnalyticsEvent("Changed ADM design file");
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      SlideProps={{
        timeout: 300,
      }}
    >
      <div className={classes.modalContent}>
        <div className={classes.modalHeader}>
          <div className={classes.closeContainer}>
            <button className={classes.closeButton} onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </button>
          </div>
          <div className={classes.titleWrapper}>
            <span className={classes.title}>Your design</span>
          </div>
          {!error && (
            <div className={classes.reviewDesignAdvise}>
              <span className={classes.reviewDesignAdviseTitle}>
                <ErrorOutlineIcon fontSize="small" /> <span>Review the design </span>
              </span>
              <span className={classes.reviewDesignAdviseContent}>
                Avoid placing crucial content in the bleed area, and ensure your artwork has a placeholder for the USPS
                address box on the back
              </span>
            </div>
          )}
          {error && (
            <div className={classes.designErrorContainer}>
              <span className={classes.designErrorTitle}>
                <ErrorOutlineIcon fontSize="small" /> <span>Error uploading the file </span>
              </span>
              <span className={classes.designErrorContent}>{error}</span>
            </div>
          )}
        </div>
        {!!fileBuffer && (
          <ADMPDFPreview
            file={fileBuffer}
            width={width}
            height={height}
            widthInInch={widthInInch}
            heightInInch={heightInInch}
            padding={0}
          />
        )}
        <div className={classes.footer}>
          <div>
            <span className={classes.fileName}>{file?.name}</span>
          </div>
          <div className={classes.buttonsContainer}>
            <SecondaryButton className={classes.uploadButton} onClick={handleUploadNewFile} disabled={uploadingFile}>
              <Upload disabled={uploadingFile} /> Upload new file
            </SecondaryButton>
            <PrimaryButton disabled={uploadingFile} onClick={handleFileSubmit}>
              Save design
              {uploadingFile && <CircularProgress size={20} color="secondary" className={classes.circularProgress} />}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
