import { styled } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CampaignCostPreview, ADMCampaignData } from "pages/adm/builder/types";
import { useCouponCode } from "pages/adm/builder/hooks/useCouponCode";
import { CountryType } from "store/types";
import { useCampaignData } from "pages/adm/builder/context/CampaignDataContext";
import { CouponCodeInput } from "components/form/CouponCodeInput";

import "./QuoteSection.css";
import { QuoteTable } from "./components/QuoteTable";

export type QuoteSectionProps = {
  campaign: ADMCampaignData;
  campaignCostPreview: CampaignCostPreview;
  country: CountryType;
  onUpdateCampaignCostWithCoupon: (couponCode: string | null, amountOff: number, percentOff: number) => Promise<void>;
  printingRef: React.RefObject<HTMLDivElement>;
  isReadOnly: boolean;
};

export const QuoteSection = ({
  campaignCostPreview,
  campaign,
  country,
  onUpdateCampaignCostWithCoupon,
  printingRef,
  isReadOnly,
}: QuoteSectionProps) => {
  const {
    isCouponCodeOpen,
    couponErrorMessage,
    setCouponErrorMessage,
    handleOpenCouponCode,
    handleApplyCouponCode,
    showCoupon,
    setIsCouponCodeOpen,
  } = useCouponCode({ campaignCostPreview, campaign, country, onUpdateCampaignCostWithCoupon });
  return (
    <div className="quoteSection" ref={printingRef}>
      <div className="quoteSectionTitle">Quote</div>
      <QuoteTable campaignCostPreview={campaignCostPreview} isSubmitted={!!campaign?.isSubmitted} />
      {showCoupon && !isReadOnly && (
        <>
          {!isCouponCodeOpen && (
            <span className="quoteCouponCodeLink" onClick={handleOpenCouponCode}>
              I have a coupon code
            </span>
          )}
          {isCouponCodeOpen && (
            <div className="quoteCouponCodeContainer">
              <CouponCodeInput
                stripeCouponCode={campaign.stripeCouponCode ?? ""}
                errorMessage={couponErrorMessage}
                setCouponErrorMessage={setCouponErrorMessage}
                onApply={handleApplyCouponCode}
                onClose={() => setIsCouponCodeOpen(false)}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
