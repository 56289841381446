import { SelectedRoute, DistributionLocation } from "module/eddm/dto";

const getSelectedRoutesFromDistributionLocations = (distributionLocations: DistributionLocation[]) => {
  if (!distributionLocations || !distributionLocations.length) {
    return [];
  }
  const selectedRoutes: SelectedRoute[] = [];
  distributionLocations.forEach((location) => selectedRoutes.push(...location.selectedRoutes));
  return selectedRoutes;
};

const radiusToZoom: Record<number, number> = {
  1: 15,
  2: 14,
  5: 13,
  10: 12,
  30: 11,
};

export { getSelectedRoutesFromDistributionLocations, radiusToZoom };
