export const stylesV2 = {
  addressesContainer: {
    position: "absolute" as const,
    left: "0px",
    top: "0px",
    backgroundColor: "#FFFFFF",
    width: "360px",
    height: "calc(100vh - 80px)",
    display: "flex",
    flexDirection: "column" as const,
  },
  addressBlockHeader: {
    borderTop: "1px solid #E2E2FC",
    borderBottom: "1px solid #E2E2FC",
    padding: "16px",
  },
  locationsCardsContainer: {
    width: "100%",
    padding: "20px 16px",
    overflow: "scroll",
  },
  hideScroll: {
    overflow: "hidden",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    fontWeight: 400,
    fontFamily: "'Proxima Nova', 'sans-serif'",
    fontSize: "14px",
    color: "#2E3860",
    marginBottom: "5px",
  },
  searchBarWrapper: {
    display: "flex",
    width: "100%",
  },
  selectedRoutes: {
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    width: "fit-content",
    cursor: "default",
    padding: "4px 5px 4px 2px",
    position: "relative" as const,
    overflow: "hidden",
    transition: "color 0.4s ease-out", // Add transition for text color
    "&:hover": {
      color: "white",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "#4F5B94",
      transform: "translateX(-101%)",
      transition: "transform 0.4s ease-out",
      zIndex: 0,
    },
    "&:hover::before": {
      transform: "translateX(0)",
    },
    "& span, & svg": {
      position: "relative",
      zIndex: 1,
      display: "flex",
      alignItems: "center",
    },
  },
  selectedRoutesCheck: {
    marginRight: "8px",
  },
  selectedLocationsBlock: {
    position: "absolute" as const,
    height: "50vh",
    width: "100%",
    border: "1px solid #B9C6E4",
    borderBottom: "none",
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    paddingTop: "8px",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    background: "white",
    zIndex: 1,
  },
  selectedLocationsBlockNoSuggestions: {
    height: "67vh",
  },
  expandLocationsBlockButton: {
    width: "46px",
    height: "4px",
    backgroundColor: "#E2E2FC",
    marginBottom: "10px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  emptyStateContainer: {
    height: "50%",
    flexDirection: "column" as const,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  emptyStateText: {
    width: "184px",
    marginTop: "10px",
    textAlign: "center" as const,
  },
};
