export const styles = {
  darkScreen: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1300,
  },
  content: {
    padding: "12px 4px 0",
    overflowY: "auto",
  },
  sidebar: {
    position: "fixed",
    top: 0,
    right: "-100%",
    width: "100%",
    height: "100vh",
    backgroundColor: "white",
    boxShadow: "-2px 0 5px rgba(0, 0, 0, 0.2)",
    transition: "right 0.3s ease-in-out",
    zIndex: 1400,
    overflowY: "auto",
    "@media (min-width: 768px)": {
      right: "-1110px",
      width: "1100px",
    },
  },
  sidebarOpen: {
    right: 0,
  },
  closeButton: {
    margin: "8px 0",
  },
};
