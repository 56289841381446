import { useEffect, useState } from "react";
import cn from "classnames";
import { DeleteIcon } from "static/media/delete-icon";
import BounceLoader from "components/loaders/bounce-loader";
import classes from "./DesignThumbnailPreviewer.module.css";

export const DesignThumbnailPreviewer = ({
  flyerInfo,
  width,
  height,
  allowDelete = false,
  isLoading,
  onDeleteFile = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const loadAndRenderPDF = async (file) => {
      setLoading(true);
      let loadingTask;

      try {
        // Detect if file is a URL (string) or an ArrayBuffer
        if (typeof file === "string") {
          // eslint-disable-next-line no-undef
          loadingTask = pdfjsLib.getDocument(file);
        } else {
          // eslint-disable-next-line no-undef
          loadingTask = pdfjsLib.getDocument({ data: file });
        }

        const pdf = await loadingTask.promise;
        const totalPages = Math.min(pdf.numPages, 2); //only front and back in case there's more
        const pagesArray = [];

        for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
          const page = await pdf.getPage(pageNum);
          const viewport = page.getViewport({ scale: 1 });
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          canvas.width = viewport.width;
          canvas.height = viewport.height;

          await page.render({
            canvasContext: context,
            viewport,
          }).promise;

          const formattedHeight = (viewport.height / viewport.width) * width;
          const formattedWidth = (viewport.width / viewport.height) * height;
          const newCanvasSize = {};

          if (formattedHeight > height) {
            newCanvasSize.height = height;
            newCanvasSize.width = formattedWidth;
          } else {
            newCanvasSize.width = width;
            newCanvasSize.height = formattedHeight;
          }

          pagesArray.push({
            canvas: canvas,
            width: viewport.width,
            height: viewport.height,
            styleWidth: newCanvasSize.width,
            styleHeight: newCanvasSize.height,
          });
        }

        setPages(pagesArray);
      } catch (error) {
        console.error("Error loading PDF:", error);
        window.location.reload();
      }

      setLoading(false);
    };

    if (flyerInfo?.s3Link) {
      loadAndRenderPDF(flyerInfo?.s3Link);
    }
  }, [flyerInfo?.s3Link, width, height]);

  return (
    <>
      {loading || isLoading ? (
        <div className={classes.loaderContainer}>
          <BounceLoader />
        </div>
      ) : (
        <div className={classes.pdfPreviewContainer}>
          <div className={classes.pdfPreview}>
            {pages.map((page, index) => (
              <div
                style={{
                  width: width,
                  height: height + 20,
                  display: "flex",
                  flexDirection: "column",
                }}
                key={index}
              >
                <p className={classes.sideTitle}>{index === 0 ? "Front" : "Back"}</p>
                <div className={classes.previewWrapper}>
                  <div
                    style={{
                      width: width,
                      height: height,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      border: "1px dashed #4F5B94",
                      zIndex: 2,
                    }}
                  >
                    <div className={cn(classes.cornerMark, classes.leftTop)} />
                    <div className={cn(classes.cornerMark, classes.leftBottom)} />
                    <div className={cn(classes.cornerMark, classes.rightTop)} />
                    <div className={cn(classes.cornerMark, classes.rightBottom)} />
                  </div>
                  <img
                    src={page.canvas.toDataURL("image/png", 1.0)}
                    alt={`Page ${index + 1}`}
                    style={{
                      width: page.styleWidth,
                      height: page.styleHeight,
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                    className={classes.previewImage}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className={classes.nameAndDelete}>
            <span className={classes.fileName}>{flyerInfo?.flyerName}</span>
            {allowDelete && (
              <DeleteIcon
                width={"16"}
                height={"16"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDeleteFile();
                }}
                className={classes.deleteIcon}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
