import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { ApolloLink, concat } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { QueryClient, QueryClientProvider } from "react-query";
import CampaignDataHOC from "campaign-data-hoc";
import DMCampaignDataHOC from "dm-campaign-data-hoc";
import { DialogModal } from "components/dialog-modal";
import ProgressBar from "components/progress-bar";
import SnackBar from "components/snack-bar";
import ROUTING, { EDDM_ROUTING, ADM_ROUTING } from "config/routing";
import Auth from "pages/auth";
import AccessDenied from "pages/campaign/access-denied";
import Audience from "pages/campaign/audience";
import Details from "pages/campaign/details";
import Quote from "pages/campaign/quote";
import Builder from "pages/flyer/builder";
import SelectFlyerTypePage from "pages/flyer/flyerTypePage";
import UploadDesignPage from "pages/flyer/upload";
import { Error } from "pages/error";
import { Segments } from "pages/eddm/segments";
import { Builder as ADMBuilder } from "pages/adm/builder";
import { CampaignDataProvider } from "pages/adm/builder/context/CampaignDataContext";
import { ClientConfigProvider } from "pages/adm/builder/context/ClientConfigContext";
import CONFIG from "config/config";
import { FeatureFlagContextProvider } from "context/featureFlagContext";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { EDDMSubmitContainer } from "pages/eddm/submit";
import GlobalStyles from "./global-styles";
import "./style.css";

import theme from "./theme";
import Login from "./pages/auth/components/login";

const httpLink = new HttpLink({ uri: CONFIG.FLYER_API_URL });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem("apiKey");
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

export const NewApp = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <DialogModal>
          <SnackBar>
            <ProgressBar>
              <Auth>
                <Router>
                  <Switch>
                    {/* COMMON ROUTES */}
                    <Route path={ROUTING.LOGIN} component={Login} /> {/* TODO: SHOW LOGIN ONLY FOR DEVS */}
                    <Route path={[ROUTING.ERROR]} component={Error} />
                    <Route path={ROUTING.ACCESS_DENIED} component={AccessDenied} />
                    {/* EDDM ROUTES */}
                    <Route path={EDDM_ROUTING.EDDM_CAMPAIGN}>
                      <DMCampaignDataHOC>
                        <FeatureFlagContextProvider>
                          <Switch>
                            {/* <Route path={ROUTING.CAMPAIGN_CHANNEL} component={Channel} /> */}
                            <Route path={EDDM_ROUTING.EDDM_CAMPAIGN_DETAILS} component={Details} />
                            <Route path={EDDM_ROUTING.EDDM_CAMPAIGN_SEGMENTS} component={Segments} />
                            <Route
                              path={[EDDM_ROUTING.EDDM_SUBMIT, EDDM_ROUTING.EDDM_SUCCESS]}
                              component={EDDMSubmitContainer}
                            />
                            <Redirect to={ROUTING.ERROR} />
                          </Switch>
                        </FeatureFlagContextProvider>
                      </DMCampaignDataHOC>
                    </Route>
                    <Route path={ADM_ROUTING.ADM_CAMPAIGN}>
                      <QueryClientProvider client={queryClient}>
                        <FeatureFlagContextProvider>
                          <CampaignDataProvider>
                            <ClientConfigProvider>
                              <Switch>
                                <Route path={ADM_ROUTING.ADM_CAMPAIGN_BUILDER} component={ADMBuilder} />
                                <Redirect to={ROUTING.ERROR} />
                              </Switch>
                            </ClientConfigProvider>
                          </CampaignDataProvider>
                        </FeatureFlagContextProvider>
                      </QueryClientProvider>
                    </Route>
                    <Route path={EDDM_ROUTING.EDDM_FLYER}>
                      <DMCampaignDataHOC>
                        <FeatureFlagContextProvider>
                          <Switch>
                            <Route path={EDDM_ROUTING.EDDM_FLYER_BUILDER} component={() => <div>builder</div>} />
                            <Route path={EDDM_ROUTING.EDDM_UPLOAD_FLYER} component={UploadDesignPage} />
                            <Route path={EDDM_ROUTING.EDDM_FLYER} component={SelectFlyerTypePage} />
                          </Switch>
                        </FeatureFlagContextProvider>
                      </DMCampaignDataHOC>
                    </Route>
                    {/* H2H and D2D ROUTES */}
                    <Route path={ROUTING.CAMPAIGN}>
                      <CampaignDataHOC>
                        <FeatureFlagContextProvider>
                          <Switch>
                            {/* UNCOMMENTED CHANNEL PAGE FOR TESTING PURPOSES, SHOULD BE REMOVED BEFORE MOVING IT TO PLATFORM*/}
                            {/* <Route path={ROUTING.CAMPAIGN_CHANNEL} component={Channel} /> */}
                            <Route path={ROUTING.CAMPAIGN_DETAILS} component={Details} />
                            <Route path={[ROUTING.CAMPAIGN_AUDIENCE]} component={Audience} />
                            <Route path={[ROUTING.SUBMIT, ROUTING.SUCCESS]} component={Quote} />
                            <Redirect to={ROUTING.ERROR} />
                          </Switch>
                        </FeatureFlagContextProvider>
                      </CampaignDataHOC>
                    </Route>
                    <Route path={ROUTING.FLYER}>
                      <CampaignDataHOC>
                        <FeatureFlagContextProvider>
                          <Switch>
                            <Route path={ROUTING.FLYER_BUILDER} component={Builder} />
                            <Route path={ROUTING.UPLOAD_FLYER} component={UploadDesignPage} />
                            <Route path={ROUTING.FLYER} component={SelectFlyerTypePage} />
                          </Switch>
                        </FeatureFlagContextProvider>
                      </CampaignDataHOC>
                    </Route>
                    <Redirect to={ROUTING.ERROR} />
                  </Switch>
                </Router>
              </Auth>
            </ProgressBar>
          </SnackBar>
        </DialogModal>
      </ThemeProvider>
    </ApolloProvider>
  );
};
