import { Icon } from "components/icons";
import "./AlertInvalidDates.css";

export interface AlertInvalidDatesProps {
  dateLimit: number;
  onDatesChange?: () => void;
}

export const AlertInvalidDates = ({ dateLimit, onDatesChange }: AlertInvalidDatesProps) => {
  return (
    <div className="alert-container">
      <div className="alert-header">
        <div style={{ display: "flex" }}>
          <Icon type="Attention" size="s" style={{ marginRight: "8px" }} />
          <span className="alert-title">Please update date</span>
        </div>
        {onDatesChange && (
          <button className="alert-edit-button" onClick={onDatesChange}>
            Edit dates
          </button>
        )}
      </div>
      <p className="alert-text">
        {`Your Campaign launch date should be at least ${dateLimit} business day${
          dateLimit === 1 ? "" : "s"
        } from today. Please select a new campaign date.`}
      </p>
    </div>
  );
};

export default AlertInvalidDates;
