import { Theme } from "@material-ui/core";

const style = (theme: Theme) => {
  return {
    headerWrapper: {
      background: "#F9F9FF",
      boxShadow: "0px 2px 14px rgba(39, 55, 84, 0.08)",
      width: "fit-content",
      minWidth: "100vw",
      height: "auto",
      position: "relative" as const,
      top: 0,
      zIndex: 3,
    },
    notVerifiedWrapper: {
      height: "auto",
    },
    eddmHeaderWrapper: {
      background: "#FFFFFF",
      boxShadow: "none",
      height: "auto",
    },
    gridWrapper: {
      position: "relative" as const,
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridTemplateRows: "100%",
      height: "60px",
      width: "100%",
      padding: "0 20px",
    },
    firstHeaderItem: {
      flex: 1,
      maxWidth: "33%",
      [theme.breakpoints.down(1520)]: {
        maxWidth: "25%",
      },
      [theme.breakpoints.down(1200)]: {
        maxWidth: "15%",
      },
    },
    middleHeaderItem: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "33%",
      [theme.breakpoints.down(1520)]: {
        maxWidth: "25%",
      },
      [theme.breakpoints.down(1200)]: {
        maxWidth: "35%",
      },
    },
    lastHeaderItem: {
      flex: 1,
      maxWidth: "33%",
      [theme.breakpoints.down(1520)]: {
        maxWidth: "50%",
      },
    },
    homeBtn: {
      marginRight: "40px",
    },
    tabsWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tabWrapper: {
      display: "flex",
      alignItems: "center",
      marginLeft: "6px",
    },
    tab: {
      cursor: "default",
      color: "rgba(0, 0, 0, 0.26)",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
    },
    homeBtnContainer: {
      marginRight: 0,
      "@media (min-width: 768px)": {
        marginRight: "16px",
      },
    },
    activeTab: {
      color: "#000000",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "20px",
    },
    link: {
      cursor: "pointer",
    },
    alertLink: {
      color: "#0C172C",
      marginLeft: 20,
      textDecoration: "underline",
      fontSize: 16,
    },
    separator: {
      background: "black",
      height: "2px",
      width: "30px",
      margin: "0 12px",
    },
    disabled: {
      background: "rgba(0, 0, 0, 0.26)",
    },
    newNavigationBtnsWrapper: {
      gridColumn: "1 / 3",
      display: "flex",
      alignItems: "center",
      marginRight: 0,
    },
    stepsContainer: {
      gridColumn: "4 / 7",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "@media (min-width: 1100px)": {
        gridColumn: "4 / 8",
      },
      "@media (min-width: 1200px)": {
        gridColumn: "5 / 8",
      },
      "@media (min-width: 1400px)": {
        gridColumn: "5 / 9",
      },
    },
    item: {
      padding: "10px",
      borderRadius: "8px",
      display: "flex",
      color: "rgb(46, 56, 96);",
      height: "100%",
      alignItems: "center",
    },
    displayNone: {
      display: "none",
    },
    itemCanBeActive: {
      cursor: "pointer",
      "&:hover": {
        background: "#E2E2FC",
      },
    },
    dotsContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dot: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "#d3d3d3",
      margin: "0 4px",
    },
    activeDot: {
      backgroundColor: "#000",
      width: 10,
      height: 10,
    },
    mobTabWrapper: {
      position: "relative" as const,
      display: "flex !important",
      flexDirection: "column" as const,
      height: "90%",
      alignItems: "center",
      justifyContent: "space-around",
    },
  };
};

export default style;
