import { Button, styled } from "@material-ui/core";

export const SecondaryButton = styled(Button)({
  display: "flex",
  height: "44px",
  width: "100%",
  borderRadius: "8px",
  background: "#FFFFFF",
  color: "#2E3860",
  border: "1px solid #E2E2FC",
  textTransform: "none",
  "&:hover": {
    background: "#F5F5F5",
  },
  "& .MuiButton-label": {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 700,
  },
  "&:disabled": {
    background: "#E4E4EA",
    color: "#C2C5DD",
  },
});
