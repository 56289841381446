import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    width: "100%",
  },
  text: {
    paddingLeft: 6,
    fontSize: "16px",
    cursor: "pointer",
    width: "100%",
  },
  disabledText: {
    paddingLeft: 6,
    color: "#C2C8D1",
  },
  datePickerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "44px",
    padding: "0px 12px",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: 8,
    border: "1px solid #E2E2FC",
    "&:focus-within": {
      borderColor: theme.palette.primary.main,
    },
    width: "100%",
  },
  errorContainer: {
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: 8,
  },
  calendarIcon: {
    marginTop: 2,
    cursor: "pointer",
  },
  calendarIconDisabled: {
    marginTop: 2,
    cursor: "default",
  },
}));
