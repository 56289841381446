import { MenuItem, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SelectOption } from "../SelectField";

const useStyles = makeStyles(() => ({
  menuItem: {
    display: "flex",
    flexDirection: "column",
    padding: "8px 16px",
    alignItems: "flex-start",
    gap: "4px",
    alignSelf: "stretch",
    "&:hover": {
      backgroundColor: "#F7F7FD",
    },
    "&.Mui-selected": {
      backgroundColor: "#E2E2FC",
      "&:hover": {
        backgroundColor: "#E2E2FC",
      },
    },
  },
  label: {
    color: "var(--blue-deep-dark, #011533)",
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: "Proxima Nova",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "120%",
    width: "100%",
  },
  description: {
    color: "#707087",
    fontSize: "12px",
    lineHeight: "15px",
    width: "100%",
  },
}));

interface CustomMenuItemProps {
  option: SelectOption;
  value: string | number;
  onClick: () => void;
  style?: React.CSSProperties;
}

export const CustomMenuItem = ({ option, value, onClick, style = {} }: CustomMenuItemProps) => {
  const classes = useStyles();

  return (
    <MenuItem
      className={classes.menuItem}
      selected={value === option.value}
      value={option.value}
      onClick={onClick}
      style={style}
    >
      <Box className={classes.label}>{option.label}</Box>
      {option.description && <Box className={classes.description}>{option.description}</Box>}
    </MenuItem>
  );
};
