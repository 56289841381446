import CloseIcon from "@material-ui/icons/Close";
import classes from "./AudienceCreationFailiedModal.module.css";
import { PrimaryButton } from "../../PrimaryButton";

interface AudienceCreationFailedModalProps {
  handleClose: () => void;
  handleResetModal: () => void;
}

export const AudienceCreationFailedModal = ({ handleClose, handleResetModal }: AudienceCreationFailedModalProps) => {
  return (
    <div
      className={classes.modalBackground}
      onClick={(e) => {
        e.stopPropagation();
        handleClose();
      }}
    >
      <div className={classes.modalContent}>
        <div className={classes.modalHeader}>
          <button className={classes.closeButton} onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </button>
        </div>
        <div className={classes.modalTitleContainer}>
          <span className={classes.modalTitle}>Audience creation failed</span>
        </div>
        <div className={classes.modalDescriptionContainer}>
          <span>Please, try again or contact us via chat if this problem persists.</span>
        </div>
        <div className={classes.modalFooter}>
          <PrimaryButton
            onClick={(e) => {
              e.stopPropagation();
              handleResetModal();
            }}
          >
            Try again
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};
