export const EmptyTargetingImage = () => {
  return (
    <svg width="240" height="183" viewBox="0 0 240 183" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M239.877 116.8C240.114 113.223 240.01 109.633 239.549 106.074C237.393 89.3574 227.626 73.5964 213.247 62.4724C208.119 58.503 202.28 54.9502 198.945 49.7129C193.322 40.8921 193.748 30.7543 186.771 22.0438C179.787 13.3271 169.608 6.52161 158.132 3.03004C123.904 -7.3834 75.9887 9.57214 80.3072 45.7741C80.3212 45.9028 80.3421 46.0927 80.3561 46.3316C80.9282 53.8537 74.2097 60.1753 65.6493 60.3529C34.073 61.0084 6.892 82.3866 1.07348 109.461C-2.66601 126.876 3.45949 145.964 17.9639 158.282C28.7917 167.477 43.324 172.53 57.8634 175.997C87.0118 182.956 117.681 184.414 147.737 181.755C175.866 179.262 205.545 172.163 223.733 153.161C233.445 143.023 239.005 130.025 239.877 116.8Z"
        fill="white"
      />
      <path
        d="M146.478 114.4H142.566C142.279 114.401 141.995 114.346 141.73 114.237C141.465 114.128 141.224 113.968 141.021 113.766C140.817 113.564 140.656 113.324 140.546 113.059C140.435 112.794 140.378 112.511 140.378 112.224L141.73 106.925L134.32 108.908L135.946 115.29C137.66 138.663 106.897 141.221 105.731 115.906L86.6678 120.574V120.565L74.2207 123.276L82.5072 156.387L99.7891 152.213C102.173 156.821 103.918 160.019 103.918 160.019H157.231L146.478 114.4Z"
        fill="#2E3860"
      />
      <path
        d="M67.9277 158.95C88.9618 151.959 125.893 144.751 125.893 144.751L87.5769 121.649C87.5769 121.649 78.2435 123.121 69.1382 125.302"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.3797 159.043C35.0844 157.275 38.0216 128.398 55.329 102.882C63.0859 90.269 65.3174 86.8415 71.2792 84.8794C73.6115 84.1129 76.1094 84.1144 78.7607 85.2875C88.1878 89.4603 98.2063 93.8033 91.4967 95.7112C84.7871 97.6192 68.9513 110.644 68.9513 110.644C68.9513 110.644 69.1018 127.561 68.2652 133.727C67.7299 137.63 66.8085 141.469 65.5145 145.189"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.0138 81.3525C98.5474 88.4302 99.6022 97.2804 100.669 103.028"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.261 89.1981C138.129 89.3668 142.13 90.2974 141.478 95.175C140.909 99.4427 137.114 99.4141 134.699 98.9397"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.494 105.655L135.951 115.293C137.616 138.042 108.492 141.073 105.881 117.848"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129.163 90.2406L135.777 90.7797C135.777 90.7797 133.691 104.663 132.805 108.412C131.918 112.162 131.22 113.851 126.587 117.598C121.954 121.345 111.705 123.614 106.44 118.22C101.175 112.826 99.9414 101.833 99.9414 101.833C102.302 100.96 108.333 100.446 108.333 100.446"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.128 91.3622C136.128 91.3622 139.267 84.8583 140.191 79.5018C141.059 74.4676 138.42 69.4123 133.691 67.4938C129.732 65.8825 128.444 66.4066 128.444 66.4066C128.444 66.4066 130.796 62.2232 124.682 56.0626C123.614 54.985 122.337 54.1368 120.93 53.57C119.523 53.0032 118.015 52.7297 116.499 52.7662C109.019 52.965 106.691 59.2882 106.691 59.2882C106.691 59.2882 98.7163 44.544 82.2831 53.2134C80.6279 54.0884 78.7545 56.5339 77.8562 58.1829C72.4076 68.167 90.2462 80.059 99.5469 81.0212C109.758 82.0754 116.173 80.8044 119.293 78.0968C116.946 79.7217 121.12 94.6917 136.128 91.3622Z"
        fill="#2E3860"
      />
      <path
        d="M107.757 96.2203L107.275 105.9L110.604 104.814"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.956 89.7164C117.419 89.1118 116.729 88.6626 115.96 88.4157C115.19 88.1689 114.368 88.1333 113.58 88.3129"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.484 86.1805C105.484 86.1805 102.812 85.2679 101.963 87.4454"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.69 107.754C118.164 107.031 113.034 110.633 115.038 110.957C117.043 111.281 119.821 109.307 118.69 107.754Z"
        fill="#2E3860"
      />
      <path
        d="M97.1782 107.091L116.67 100.499C116.67 100.499 119.278 99.5046 119.186 96.1194C119.095 92.7342 114.841 91.4677 114.841 91.4677L94.0664 88.5327L97.1782 107.091Z"
        fill="#EE4360"
      />
      <path
        d="M76.3208 107.091L95.8131 100.499C95.8131 100.499 98.4223 99.5046 98.3305 96.1194C98.2387 92.7342 93.9833 91.4677 93.9833 91.4677L83.9347 92.1348L73.209 88.5327L76.3208 107.091Z"
        fill="#EE4360"
      />
      <path
        d="M93.8494 107.693C99.1565 107.693 103.459 103.388 103.459 98.0768C103.459 92.7657 99.1565 88.4602 93.8494 88.4602C88.5424 88.4602 84.2402 92.7657 84.2402 98.0768C84.2402 103.388 88.5424 107.693 93.8494 107.693Z"
        fill="#2E3860"
      />
      <path
        d="M72.8455 107.831C78.1526 107.831 82.4548 103.525 82.4548 98.2139C82.4548 92.9028 78.1526 88.5973 72.8455 88.5973C67.5385 88.5973 63.2363 92.9028 63.2363 98.2139C63.2363 103.525 67.5385 107.831 72.8455 107.831Z"
        fill="#2E3860"
      />
      <path
        d="M80 83.3812C83.8672 80.648 91.3005 79.7279 92.9106 79.9673C94.4559 80.1977 93.5591 82.7532 88.9381 84.1627"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.3379 84.0946C87.0026 82.9501 95.247 87.2931 96.5742 88.5626C97.9961 89.9179 94.1395 92.0562 86.9002 87.8503C86.9002 87.8503 84.7786 86.4167 83.0647 86.5793"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.8945 84.2934C82.6927 83.2824 90.9917 87.4818 92.3323 88.7163C93.7884 90.0485 90.5707 91.4272 83.1577 87.293C83.1577 87.293 80.7006 85.0297 78.9472 85.1895"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.6309 84.6582C77.0262 82.5499 86.3766 87.4396 87.7986 88.7979C89.2205 90.1562 85.3639 92.2901 78.1246 88.0856C78.1246 88.0856 76.003 86.652 74.2906 86.8146"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M201.083 70.3473C204.57 69.3023 208.626 68.6653 213.341 68.5945C213.341 68.5945 215.133 75.2626 212.943 83.5962"
        stroke="#EE4360"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M201.021 84.653C201.917 79.9154 201.948 75.0492 201.111 70.3003"
        stroke="#EE4360"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M201.111 70.3005C199.687 62.1235 195.589 52.9466 187.067 43.1206C187.067 43.1206 177.963 48.0193 171.186 58.4355"
        stroke="#EE4360"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.975 103.49C163.975 103.49 155.729 69.7025 129.825 77.7831C129.825 77.7831 130.488 85.2101 134.154 92.6297"
        stroke="#EE4360"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M181.582 77.2624C178.559 66.6881 171.143 54.9752 154.942 45.0062C154.942 45.0062 147.621 54.9 144.735 68.6425"
        stroke="#EE4360"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.164 82.3568C155.139 77.0034 157.874 70.8352 163.469 63.9337C163.469 63.9337 201.371 81.5105 182.367 105.23"
        stroke="#EE4360"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.865 105.249C177.588 99.5599 191.004 81.8897 212.928 83.8655"
        stroke="#EE4360"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M212.922 83.8612C216.032 84.1521 219.103 84.7863 222.08 85.7526C222.08 85.7526 216.643 98.5873 204.12 106.454"
        stroke="#EE4360"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M197.366 142.866L203.412 115.269H207.058C207.343 115.27 207.626 115.218 207.89 115.118C208.154 115.017 208.394 114.869 208.596 114.681C208.799 114.494 208.96 114.272 209.069 114.027C209.179 113.782 209.236 113.519 209.237 113.254L209.403 108.944C209.403 108.679 209.346 108.416 209.237 108.17C209.127 107.925 208.966 107.702 208.764 107.514C208.562 107.327 208.321 107.178 208.057 107.077C207.793 106.976 207.509 106.924 207.224 106.925H143.791C143.505 106.924 143.222 106.976 142.958 107.077C142.694 107.178 142.454 107.327 142.251 107.515C142.049 107.702 141.889 107.925 141.779 108.17C141.67 108.416 141.613 108.679 141.613 108.944L141.447 113.254C141.448 113.519 141.505 113.782 141.614 114.027C141.724 114.271 141.885 114.494 142.087 114.681C142.289 114.868 142.529 115.016 142.793 115.117C143.057 115.218 143.34 115.27 143.625 115.269H147.524L158.489 160.914"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M186.48 139.979C184.744 137.491 183.415 134.838 185.079 134.127C187.368 133.15 187.337 135.616 190.787 137.971C194.237 140.327 197.469 140.768 200.891 145.049"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M186.596 140.346C180.705 137.145 172.151 136.96 170.098 137.052C168.846 137.107 168.416 138.169 169.364 139.508"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183.824 143.673C177.853 140.536 169.347 139.362 167.177 139.532C165.38 139.673 165.359 141.976 168.23 144.28"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M182.405 147.68C176.952 145.239 170.612 144.09 168.766 144.191C166.79 144.299 166.717 147.343 170.326 150.139"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M182.146 151.726C177.532 149.809 172.94 148.757 171.462 148.934C168.552 149.284 170.981 154.487 184.793 158.637"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M184.797 158.637C213.212 166.394 211.36 141.771 186.779 140.235"
        stroke="#2E3860"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M223.649 160.462H16.8418" stroke="#2E3860" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
