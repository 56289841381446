import { useCallback } from "react";
import { usePostHog } from "posthog-js/react";
import { EmitAnalyticsEvent } from "module/analytics/application/EmitAnalyticsEvent";
import { authorization } from "module/auth";

export type TriggerAnalyticsFn = (eventName: string, eventProperties?: Record<string, any>) => void;

export const usePosthogAnalytics = (campaignId: string): { triggerAnalyticsEvent: TriggerAnalyticsFn } => {
  const posthog = usePostHog();
  const triggerAnalyticsEvent = useCallback(
    (eventName: string, eventProperties: Record<string, any> = {}) => {
      const posthogEventTracker = new EmitAnalyticsEvent(posthog);
      const user = authorization.getUser();
      const payload = {
        eventName,
        userId: user?.id,
        clientName: user?.clientName,
        device: navigator.userAgent,
        additionalPayload: { ...eventProperties, campaignId },
      };
      posthogEventTracker.run(payload);
    },
    [posthog, campaignId]
  );

  return { triggerAnalyticsEvent };
};
