import { Box } from "@material-ui/core";

import { DescriptionText } from "../DescriptionText";

import "./TotalSection.css";
import { TotalText } from "../TotalText";

export type TotalSectionProps = {
  total: number;
  units: number;
};

export const TotalSection = ({ total, units }: TotalSectionProps) => {
  return (
    <div className="totalSectionContainer">
      <TotalText>Total</TotalText>
      <Box className="totalSectionDetails">
        <TotalText>$ {isNaN(total) ? "-" : total}</TotalText>
        <DescriptionText>{isNaN(units) ? "-" : `${units} ${units > 1 ? "units" : "unit"}`}</DescriptionText>
      </Box>
    </div>
  );
};
