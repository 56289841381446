import { withStyles } from "@material-ui/core/styles";
import { Select } from "@material-ui/core";

export const CustomSelect = withStyles((theme) => ({
  root: {
    display: "flex",
    height: "44px",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: 8,
    border: ({ error }: { error?: boolean }) => (error ? `1px solid ${theme.palette.error.main}` : "1px solid #E2E2FC"),
    "&:focus": {
      borderColor: ({ error }: { error?: boolean }) => (error ? theme.palette.error.main : theme.palette.primary.main),
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
      borderRadius: 8,
    },
    "&.Mui-error": {
      border: `1px solid ${theme.palette.error.main}`,
      borderRadius: 8,
    },
  },
  select: {
    padding: "0px 12px",
    borderRadius: 8,
    "&:focus": {
      borderRadius: 8,
      backgroundColor: "transparent",
    },
  },
  icon: {
    right: 12,
  },
  paper: {
    marginTop: 8,
    borderRadius: 8,
  },
  list: {
    padding: 0,
  },
}))(Select);
