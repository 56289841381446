import CONFIG from "config/config";
import LogRocket from "logrocket";
import { REGEX_FOR_CAMPAIGN_NAME } from "../constants";
import { getFilteredCities } from "./filters";
import { NAVIGATION, CHANNEL_NAVIGATION } from "../pages/constants";

export function getCurrencyCode(country) {
  switch (country.currencySign) {
    case "$":
      if (country.name === "Canada") {
        return "CAD";
      } else if (country.name === "Australia") {
        return "AUD";
      } else if (country.name === "New Zealand") {
        return "NZD";
      } else {
        return "USD";
      }
    case "€":
      return "EUR";
    case "£":
      return "GBP";
    case "CHF":
      return "CHF";

    default:
      return "USD";
  }
}

export const sortAscCitiesList = (citiesList) =>
  citiesList.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

export const getCitiesList = async ({ channel, country }) => {
  const cities = await getFilteredCities({
    country,
    channel,
  });

  return sortAscCitiesList(cities);
};

export const getLogRocketSessionURL = ({ short = false }) => {
  let logRocketURL = "";
  LogRocket.getSessionURL((sessionURL) => (logRocketURL = sessionURL.split("?")[0]));
  if (short) {
    logRocketURL = logRocketURL.split("oppizi")[1];
  }
  return logRocketURL;
};

export const gtagWrapper = ({ event, ...params } = {}) => {
  try {
    if (window.dataLayer && event) {
      //console.log(event, params);
      window.dataLayer.push({
        event,
        params,
      });
    }
  } catch (error) {
    console.error("ERROR_gtag", error);
  }
};

const isProduction = () => CONFIG.ENV === "production";
const hubspotToken = CONFIG.REACT_APP_HUBSPOT_API_TOKEN;
export const postHubSpot = async (params = {}) => {
  const { email } = params;
  const hubSpotStages = [
    "INTERESTED | SIGNUP", // "INTERESTED | SIGNUP"
    "CAMPAIGN STARTED", // "CAMPAIGN STARTED"
    "TARGET | REACH", // "TARGET | REACH"
    "DESIGN FLYER", // "DESIGN FLYER"
    "DETAILS PAGE", // "DETAILS PAGE"
    "QUOTE", // "QUOTE"
    "CLOSED WON", // "CLOSED WON"
  ];

  // Disallow Oppizi emails
  if (params && !email.includes("oppizi.com")) {
    const {
      deal_id,
      pipeline: deal_pipeline_label,
      stage_id,
      campaign_id,
      first_name: firstname,
      last_name: lastname,
      campaign_name,
      amount,
      logrocket_sessions,
      company_name,
      currency: deal_currency_code,
      company_id,
      contact_id,
      email,
      country,
      city,
      channel_type,
      flyer_type_chosen,
      flyer_type,
      flyer_weight,
      design_file_url,
      launch_date,
      campaign_duration,
      qr_code_url: url,
      comments,
      coupon_code,
      tax,
      total,
      invoice_number,
      number_of_missions,
      number_of_flyers,
      sub_total,
      currency,
      distribution_cost,
      distribution_unit_cost,
      printing_cost,
      printing_unit_cost,
      hasLocations: locations,
      missions,
    } = params;
    const body = {
      deal: {
        deal_id,
        deal_pipeline_label,
        deal_stage_label: hubSpotStages[stage_id],
        campaign_id,
        firstname,
        lastname,
        campaign_name,
        amount,
        logrocket_sessions,
        company_name,
        deal_currency_code,
        country,
        company_id,
        contact_id,
        city,
        channel_type,
        flyer_type_chosen,
        flyer_type,
        flyer_weight,
        design_file_url,
        launch_date,
        campaign_duration,
        url,
        comments,
        coupon_code,
        tax,
        total,
        invoice_number,
        number_of_missions,
        number_of_flyers,
        sub_total,
        currency,
        distribution_cost,
        distribution_unit_cost,
        printing_cost,
        printing_unit_cost,
        locations,
        missions,
      },
      contact: {
        email,
        contact_id,
        firstname,
        lastname,
        country,
      },
      company: {
        company_id,
        company_name,
        country,
      },
    };

    return await fetch(
      isProduction() ? "https://hubspot.oppizi.com/deal/" : "https://phoebe-hubspot.oppizi.com/deal/",
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: new Headers({
          Authorization: `Bearer ${hubspotToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      }
    )
      .then((result) => result.json())
      .catch((err) => {
        console.error(err);
      });
  }
  return {
    deal_id: null,
    success: false,
    message: "Please provide paremeters",
  };
};

const specialCharacter = "#";
/**
 * seperate by special character like '#': we can change this character
 */
export const convertStringSeperatedBySpecialToArray = (str) => (str ? str.split(specialCharacter) : []);

export const convertArrayToStringSeperatedBySpecial = (arr) => (arr ? arr.join(specialCharacter) : "");

export const createMinimumMissionLimitError = ({ acc = {}, isD2D, missionLimit }) => {
  return {
    ...acc,
    title: `Not enough ${isD2D ? "areas" : "distributors"}!`,
    message: acc.message
      ? acc.message.split("to continue.").join(`and ${missionLimit} ${isD2D ? "areas" : "distributors"} to continue.`)
      : `Please, select at least ${missionLimit} ${isD2D ? "areas" : "distributors"} to continue.`,
  };
};

export const checkIsNeedUpdateLastActiveStepState = ({ stateLastActiveStep, newLastActiveStep, channel }) => {
  const navigation = channel && CHANNEL_NAVIGATION[channel] ? CHANNEL_NAVIGATION[channel] : NAVIGATION;
  if (stateLastActiveStep === "home") {
    return true;
  } else {
    const stateStep = navigation.find((item) => item.step === stateLastActiveStep);
    const newStep = navigation.find((item) => item.step === newLastActiveStep);

    return stateStep && newStep && stateStep.index < newStep.index;
  }
};

export const formatCampaignName = (name) => {
  const cleanName = name.replace(REGEX_FOR_CAMPAIGN_NAME, "");
  if (cleanName.length > 32) {
    return cleanName.slice(0, 32);
  } else {
    return cleanName;
  }
};

export const validateCampaignName = (campaignName) => {
  if (!campaignName) {
    return null;
  }
  const trimmedCampaignName = campaignName.trim();
  const noSpaceCampaignName = campaignName.replace(/\s/g, "");

  if (!trimmedCampaignName.length) {
    return "Latin characters required";
  }

  if (noSpaceCampaignName.length < 4) {
    return "Campaign name is too short (minimum 4 characters)";
  }

  if (trimmedCampaignName.length > 32) {
    return "Campaign name is too long (maximum 32 characters)";
  }

  const isLangError = REGEX_FOR_CAMPAIGN_NAME.test(campaignName);

  if (isLangError) {
    return "Campaign name contains invalid characters.";
  }

  return null;
};

/* Hide part of the email for security reasons */
export const hideEmail = (email) => {
  if (!email) return "";
  const [name, domain] = email.split("@");
  const hiddenName = name.slice(0, 4) + "*".repeat(name.length - 4);
  return `${hiddenName}@${domain}`;
};

export const getMobileEmailBody = (name) => {
  return `<div stlye="width: 100%;">
            <p style="color: #00112B; font-size: 14px; padding-bottom: 10px;">Hi ${name},</p>
            <p style="color: #00112B; font-size: 14px; padding-bottom: 10px;">We noticed that you started creating a campaign on mobile.</p>
            <p style="color: #00112B; font-size: 14px; padding-bottom: 10px;">To continue designing your Oppizi campaign seamlessly, access our Campaign Creation Tool on desktop by clicking here: </p>
            <table style="margin: 20px auto;">
              <tbody>
                <tr>
                  <td>
                    <a href="https://app.oppizi.com" 
                      style="text-decoration: none;
                              color: #FFFFFF !important;
                              background-color: #F42156;
                              padding: 10px 20px;
                              line-height: 2em;
                              font-weight: bold;
                              text-align: center;
                              cursor: pointer;
                              display: inline-block;
                              font-size: 14px;
                              border-radius: 16px;"
                      >
                      Continue to create campaign   
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <p style="color: #526181; font-size: 12px; ">If you are having trouble clicking the button, copy and paste this URL into your web browser</p> 
            <a href="https://app.oppizi.com" style="color: #F42156 !important;">https://app.oppizi.com</a>
          </div>`;
};
