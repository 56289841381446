import { Box } from "@material-ui/core";
import dayjs from "dayjs";

import EditIcon2 from "static/media/edit-icon-2";
import { SummaryContentText } from "../SummaryContentText";
import { SummaryTitleText } from "../SummaryTitleText";
import { TitleText } from "../TitleText";
import { CampaignInfoFormData } from "../../../../schemas/campaignInformation";
import { FORMAT_OPTIONS } from "../../../../constants";

import "./CampaignDetailsSection.css";
import { DescriptionText } from "../DescriptionText";

export const CampaignDetailsSection = ({
  campaign,
  onEditFlyerFormat,
  isReadOnly,
}: {
  campaign: Partial<CampaignInfoFormData>;
  onEditFlyerFormat?: () => void;
  isReadOnly?: boolean;
}) => {
  const format = FORMAT_OPTIONS.find((option) => option.value === campaign.format);

  return (
    <div className="campaignDetailsSectionContainer">
      <TitleText>Summary</TitleText>
      <Box className="campaignSummarySection">
        <SummaryTitleText>Campaign name</SummaryTitleText>
        <SummaryContentText>{campaign.campaignName}</SummaryContentText>
      </Box>
      <Box className="campaignSummarySection">
        <SummaryTitleText>Launch date</SummaryTitleText>
        <SummaryContentText>{dayjs(campaign.campaignStartDate).format("MMM D, YYYY")}</SummaryContentText>
      </Box>
      <Box className="campaignSummarySection">
        <SummaryTitleText>Format</SummaryTitleText>
        <Box className="campaignSummaryFormatContainer">
          <SummaryContentText>{`${format?.topText} ${format?.label}`}</SummaryContentText>
          <DescriptionText className="campaignSummaryFormatBottomText">{format?.bottomText}</DescriptionText>
          {!isReadOnly && onEditFlyerFormat && (
            <EditIcon2 className="campaignSummaryFormatEditIcon" onClick={onEditFlyerFormat} />
          )}
        </Box>
      </Box>
    </div>
  );
};
