import { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import { LinearProgress, Typography } from "@material-ui/core";
import { FileStick } from "static/media/FileStick";
import classes from "./CreatingAudienceModal.module.css";
import { SecondaryButton } from "../../SecondaryButton";
import { CancelAudienceCreationModal } from "./CancelAudienceCreationModal";

interface CreatingAudienceModalProps {
  file: File;
  submitProgress: number;
  abortController: AbortController | null;
}

const BorderLinearProgress = withStyles(() => ({
  root: {
    width: "100%",
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#E2E2FC",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#273754",
  },
}))(LinearProgress);

export const CreatingAudienceModal = ({ file, submitProgress, abortController }: CreatingAudienceModalProps) => {
  const [isCancellingAudienceCreation, setIsCancellingAudienceCreation] = useState(false);

  const handleClose = () => {
    setIsCancellingAudienceCreation(true);
  };

  const handleCancelCreation = async () => {
    if (abortController) {
      abortController.abort();
    }
    handleClose();
  };

  return (
    <>
      {isCancellingAudienceCreation && (
        <CancelAudienceCreationModal
          setIsCancellingAudienceCreation={setIsCancellingAudienceCreation}
          cancelAudienceCreation={handleCancelCreation}
        />
      )}
      <div
        className={classes.modalBackground}
        onClick={(e) => {
          e.stopPropagation();
          handleClose();
        }}
      >
        <div className={classes.modalContent}>
          <div className={classes.modalHeader}>
            <button className={classes.closeButton} onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </button>
          </div>
          <div className={classes.modalTitleContainer}>
            <span className={classes.modalTitle}>Creating your audience</span>
          </div>
          <div className={classes.descriptionWrapper}>
            <Typography>
              Once completed, <b>you will automatically proceed to the next step.</b>
            </Typography>
            <Typography>Please, don't close this window.</Typography>
          </div>
          <div className={classes.uploadedCSVFile}>
            <div className={classes.fileNameContainer}>
              <FileStick className={classes.fileStick} /> <span className={classes.fileName}>{file.name} </span>
            </div>
            <div className={classes.submittingProcess}>
              <span className={classes.submittingProcessLabel}>Reading your file:</span>
              <div className={classes.progressBarContainer}>
                <span className={classes.submittingProcessLabel}>{submitProgress}%</span>
                <BorderLinearProgress variant="determinate" value={submitProgress} />
              </div>
            </div>
          </div>
          <div className={classes.modalFooter}>
            <SecondaryButton className={classes.cancelButton} onClick={handleClose}>
              Cancel
            </SecondaryButton>
          </div>
        </div>
      </div>
    </>
  );
};
