import { useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Box, CircularProgress } from "@material-ui/core";
import { CustomTextInput } from "../InputField/components/CustomTextInput";
import "./CouponCodeInput.css";

interface CouponCodeInputProps {
  stripeCouponCode?: string;
  errorMessage?: string;
  setCouponErrorMessage: (message: string) => void;
  onApply: ({ stripeCouponCode, saveLogs }: { stripeCouponCode: string; saveLogs: boolean }) => Promise<boolean>;
  onClose: () => void;
  disabled?: boolean;
  placeholder?: string;
}

export const CouponCodeInput = ({
  stripeCouponCode = "",
  errorMessage,
  setCouponErrorMessage,
  onApply,
  onClose,
  disabled,
  placeholder = "Enter coupon code",
}: CouponCodeInputProps) => {
  const [inputValue, setInputValue] = useState(stripeCouponCode);
  const [isApplying, setIsApplying] = useState(false);

  useEffect(() => {
    setInputValue(stripeCouponCode);
  }, [stripeCouponCode]);

  const handleChange = (value: string) => {
    if (!stripeCouponCode) {
      setInputValue(value);
      setCouponErrorMessage("");
    }
  };

  const handleApply = async () => {
    if (!inputValue || isApplying) return;

    setIsApplying(true);
    try {
      await onApply({ stripeCouponCode: inputValue, saveLogs: false });
    } finally {
      setIsApplying(false);
    }
  };

  const handleClear = async () => {
    setInputValue("");
    setIsApplying(true);
    try {
      await onApply({ stripeCouponCode: "", saveLogs: false });
    } finally {
      setIsApplying(false);
    }
    setCouponErrorMessage("");
    onClose();
  };

  return (
    <Box className="couponInputWrapper">
      <Box className="couponInputContainer">
        <Box className="couponInputFieldContainer">
          <CustomTextInput
            value={inputValue}
            onChange={(e) => handleChange(e.target.value)}
            placeholder={placeholder}
            disabled={disabled || !!stripeCouponCode}
            error={!!errorMessage}
          />
          <button
            className="applyButton"
            onClick={handleApply}
            disabled={disabled || !inputValue || isApplying || !!stripeCouponCode}
          >
            {isApplying ? <CircularProgress size={20} color="secondary" /> : "Add Coupon"}
          </button>
        </Box>
        {errorMessage && <span className="couponError">{errorMessage}</span>}
      </Box>
      {stripeCouponCode && (
        <Box className="appliedCouponChip">
          <span className="chipLabel">{stripeCouponCode}</span>
          <button className="removeButton" onClick={handleClear}>
            <CloseIcon fontSize="small" />
          </button>
        </Box>
      )}
    </Box>
  );
};
