import React, { createContext, useEffect, useState } from "react";
import usePosthog from "Hooks/usePosthog/usePosthog";
import posthog from "posthog-js";

interface FeatureFlagsObj {
  [key: string]: boolean;
}

const defaultValues: { featureFlags: FeatureFlagsObj; loading: boolean } = {
  featureFlags: {},
  loading: true,
};

export const FeatureFlagContext = createContext(defaultValues);

export const FeatureFlagContextProvider = ({ children }: { children: React.ReactNode }) => {
  usePosthog();

  const flagsMap = {
    isSkipEnabled: "enable-skip-design",
    isAdmEnabled: "is_adm_feature_enabled",
  };

  const arrayOfFlags = Object.entries(flagsMap);

  const fixedValueFlags = {
    isSkipEnabled: true,
  };

  const [featureFlags, setFeatureFlags] = useState<FeatureFlagsObj>({});
  const [loading, setLoading] = useState(true);

  const evaluateFeatureFlags = () => {
    try {
      if (!posthog) {
        console.warn("PostHog is not initialized. Defaulting flags to false.");
        const disabledFlags = arrayOfFlags.reduce((acc, [alias]) => {
          acc[alias] = false;
          return acc;
        }, {} as FeatureFlagsObj);
        setFeatureFlags({ ...disabledFlags, ...fixedValueFlags });
        setLoading(false);
        return;
      }

      const currentFlags = arrayOfFlags.reduce((acc, [alias, realFlag]) => {
        acc[alias] = posthog.isFeatureEnabled(realFlag) || false;
        return acc;
      }, {} as FeatureFlagsObj);

      setFeatureFlags({ ...currentFlags, ...fixedValueFlags });
      setLoading(false);
    } catch (error) {
      console.error("Error evaluating feature flags:", error);
      const disabledFlags = arrayOfFlags.reduce((acc, [alias]) => {
        acc[alias] = false;
        return acc;
      }, {} as FeatureFlagsObj);
      setFeatureFlags({ ...disabledFlags, ...fixedValueFlags });
      setLoading(false);
    }
  };

  useEffect(() => {
    evaluateFeatureFlags();

    if (posthog) {
      posthog.onFeatureFlags(() => {
        evaluateFeatureFlags();
      });
    }
  }, []);

  return <FeatureFlagContext.Provider value={{ featureFlags, loading }}>{children}</FeatureFlagContext.Provider>;
};
