import { useState, useCallback, useEffect, useMemo } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import moment from "moment";
import { debounce } from "lodash";

import { isValidCampaignName } from "components/audience-form/components/campaign-location/utils";
import { checkIsNeedUpdateLastActiveStepState } from "utils";
import { Audience } from "../../types";

import { CampaignInfoFormData, campaignInfoFormSchema } from "../../schemas/campaignInformation";
import {
  getNextDayByBusinessDays,
  campaignHasUpdatedFields,
  parseTimestampStringDate,
  isCampaignInfoFormFullyFilled,
} from "../../utils";
import { useCampaignData } from "../../context/CampaignDataContext";
import { updateCampaignExtraData, updateCampaign } from "../../../../../graphQL";
import { ADM_FORMAT, ADM_NAVIGATION, MINIMUM_BUSINESS_DAYS } from "../../constants";
import { SEGMENT_STEPS } from "../Segments";
import { usePosthogAnalytics } from "../../hooks/usePosthogAnalytics/usePosthogAnalytics";

const useSegments = () => {
  const {
    activeAudienceDetails,
    campaignData,
    refetchCampaign,
    campaignId,
    clientId,
    campaignAudiences,
    activeTabIndex,
    setActiveTabIndex,
    isReadOnly,
  } = useCampaignData();

  const { triggerAnalyticsEvent } = usePosthogAnalytics(campaignId || "");

  const [isCampaignInfoOpen, setIsCampaignInfoOpen] = useState(true);
  const [isTargetingCardOpen, setIsTargetingCardOpen] = useState(true);
  const [isCreateAudienceModalOpen, setIsCreateAudienceModalOpen] = useState(false);
  const [clickedAudience, setClickedAudience] = useState<Audience | null>(null);
  const [currentStep, setCurrentStep] = useState(SEGMENT_STEPS.CAMPAIGN_INFO);
  const [isLoading, setIsLoading] = useState(false);
  const [checkingCampaignName, setCheckingCampaignName] = useState(false);

  const methods = useForm<CampaignInfoFormData>({
    resolver: zodResolver(campaignInfoFormSchema),
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      campaignName: "",
      updateAddressOnMove: "yes",
      campaignStartDate: getNextDayByBusinessDays(new Date(), MINIMUM_BUSINESS_DAYS),
      format: ADM_FORMAT.POSTCARD_9x6,
    },
  });

  const currentCampaignName = methods.watch("campaignName");
  const isValid = methods.formState.isValid;
  const currentValues = methods.watch();

  const validateAndSaveCampaignNameDebounced = useMemo(
    () =>
      debounce(
        (newCampaignName: string) => {
          handleSaveCampaignName(newCampaignName);
        },
        2000,
        { maxWait: 2000 }
      ),
    []
  );

  useEffect(() => {
    return () => {
      validateAndSaveCampaignNameDebounced.cancel();
    };
  }, []);

  const handleSaveCampaignName = async (newCampaignName: string) => {
    if (methods.formState.errors.campaignName) {
      return;
    }
    setCheckingCampaignName(true);
    const trimmedCampaignName = newCampaignName.trim();
    const isValid = await isValidCampaignName({ campaignName: trimmedCampaignName, clientId, campaignId });

    if (isValid) {
      try {
        await updateCampaign({
          campaignId,
          name: newCampaignName,
        });
      } catch (error) {
        methods.setError("campaignName", { type: "manual", message: "Error updating campaign name." });
      }
    } else {
      methods.setError("campaignName", {
        type: "manual",
        message: "Duplicated campaign name. Please choose another.",
      });
    }
    setCheckingCampaignName(false);
  };

  useEffect(() => {
    if (campaignData && campaignHasUpdatedFields(campaignData, currentValues)) {
      const startDate = parseTimestampStringDate(campaignData.startDate);
      const newValues = {
        campaignName: campaignData?.campaignName || "",
        updateAddressOnMove: "yes",
        campaignStartDate: startDate || getNextDayByBusinessDays(new Date(), 5),
        format: campaignData?.flyerType || ADM_FORMAT.POSTCARD_9x6,
      };
      methods.reset(newValues);
    }
  }, [campaignData, methods]);

  useEffect(() => {
    if (currentStep === SEGMENT_STEPS.TARGETING) {
      setIsCampaignInfoOpen(false);
      setIsTargetingCardOpen(true);
    }
    if (currentStep === SEGMENT_STEPS.CAMPAIGN_INFO) {
      setIsCampaignInfoOpen(true);
      setIsTargetingCardOpen(false);
    }
  }, [currentStep]);

  useEffect(() => {
    const isCampaignNameChanged =
      campaignData?.campaignName && currentCampaignName && campaignData?.campaignName !== currentCampaignName;

    if (isCampaignNameChanged) {
      validateAndSaveCampaignNameDebounced(currentCampaignName);
    }
  }, [currentCampaignName]);

  const onSubmitCampaignInfo = useCallback(
    async (data: CampaignInfoFormData) => {
      const payloadCampaignExtraData = {
        campaignId,
        startDate: `${moment(data.campaignStartDate).format("YYYY-MM-DD")}T00:00:00.000Z`,
        flyerType: data.format,
      };
      await updateCampaignExtraData(payloadCampaignExtraData);
      await updateCampaign({
        campaignId,
        name: data.campaignName,
      });
      refetchCampaign();
    },
    [refetchCampaign]
  );

  const onSubmitSegments = useCallback(async () => {
    const shouldUpdateLastActiveStep = checkIsNeedUpdateLastActiveStepState({
      stateLastActiveStep: campaignData?.lastActiveStep,
      newLastActiveStep: ADM_NAVIGATION[0].step,
      channel: campaignData?.subtype,
    });
    if (shouldUpdateLastActiveStep) {
      await updateCampaignExtraData({ campaignId, lastActiveStep: ADM_NAVIGATION[0].step });
      refetchCampaign();
    }
    setActiveTabIndex(activeTabIndex + 1);
  }, [refetchCampaign, campaignData]);

  const onSubmit = useCallback(
    async (data: CampaignInfoFormData) => {
      switch (currentStep) {
        case SEGMENT_STEPS.CAMPAIGN_INFO:
          setCurrentStep(SEGMENT_STEPS.TARGETING);
          break;
        case SEGMENT_STEPS.TARGETING:
          setIsLoading(true);
          await onSubmitCampaignInfo(data);
          await onSubmitSegments();
          setIsLoading(false);
          break;
      }
      const eventName = `Continued to cb ${currentStep === SEGMENT_STEPS.CAMPAIGN_INFO ? "targeting" : "design"} step`;
      triggerAnalyticsEvent(eventName, {
        campaignId,
        campaignName: data.campaignName,
        campaignStartDate: data.campaignStartDate,
        campaignFormat: data.format,
        channel: "ADM",
      });
    },
    [currentStep, onSubmitCampaignInfo, triggerAnalyticsEvent]
  );

  const isNextEnabled = useMemo(() => {
    const isAudienceSelected = campaignAudiences.length > 0;
    const isCampaignInfoFilled = isValid && isCampaignInfoFormFullyFilled(currentValues);
    switch (currentStep) {
      case SEGMENT_STEPS.CAMPAIGN_INFO:
        return isCampaignInfoFilled;
      case SEGMENT_STEPS.TARGETING:
        return isAudienceSelected && isCampaignInfoFilled;
      default:
        return false;
    }
  }, [currentStep, isValid, currentValues, campaignAudiences]);

  return {
    campaignId,
    isCreateAudienceModalOpen,
    setIsCreateAudienceModalOpen,
    clickedAudience,
    checkingCampaignName,
    triggerAnalyticsEvent,
    methods,
    isCampaignInfoOpen,
    setIsCampaignInfoOpen,
    isTargetingCardOpen,
    setIsTargetingCardOpen,
    setClickedAudience,
    currentValues,
    activeAudienceDetails,
    currentStep,
    isNextEnabled,
    isLoading,
    onSubmit,
    isReadOnly,
  };
};

export default useSegments;
