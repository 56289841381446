import { useCallback, useState } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { InputFieldNameText } from "../InputField/components/InputFieldNameText";
import { InputFieldDescriptionText } from "../InputField/components/InputFieldDescriptionText";
import { CustomRadioCard } from "./components/CustomRadioCard";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "flex-start",
    alignContent: "flex-start",
    gap: "4px 8px",
    alignSelf: "stretch",
    flexWrap: "wrap",
  },
}));

export type RadioCardOption = {
  value: string;
  label: string;
  topText?: string;
  bottomText?: string;
  image?: string;
};

export type RadioCardFieldProps = {
  className?: string;
  description?: string;
  error?: string;
  label: string;
  name: string;
  options: RadioCardOption[];
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  required?: boolean;
};

export const RadioCardField = ({
  className,
  description,
  disabled,
  error,
  label,
  name,
  onBlur,
  onChange,
  options,
  value,
  required,
}: RadioCardFieldProps) => {
  const classes = useStyles();
  const [isFocus, setIsFocus] = useState(false);

  const handleChange = useCallback(
    (newValue: string) => {
      onChange(newValue);
    },
    [onChange]
  );

  const handleBlur = useCallback(() => {
    onBlur && onBlur();
    setIsFocus(false);
  }, [onBlur]);

  return (
    <Box width="100%" className={className}>
      <Box className="inputLabelContainer">
        <InputFieldNameText>
          {label}
          {required && <span className="required">*</span>}
        </InputFieldNameText>
        {description && <InputFieldDescriptionText>{description}</InputFieldDescriptionText>}
      </Box>
      <Box className={classes.container}>
        {options.map((option) => (
          <CustomRadioCard
            key={option.value}
            option={option}
            selected={value === option.value}
            onClick={() => handleChange(option.value)}
            disabled={disabled}
            onBlur={handleBlur}
          />
        ))}
      </Box>
      {error && <Box className="errorContainer">{error}</Box>}
    </Box>
  );
};
