import { CampaignCostPreview } from "pages/adm/builder/types";

export type QuoteTableProps = {
  campaignCostPreview: CampaignCostPreview;
  isSubmitted: boolean;
};

export const QuoteTable = ({ campaignCostPreview, isSubmitted }: QuoteTableProps) => {
  return (
    <div className="quoteTableSection">
      <div className="quoteTableHeader">
        <div>Description</div>
        <div>Quantity</div>
        <div>Total</div>
      </div>
      {campaignCostPreview.lines.map((line, index) => (
        <div key={index} className="quoteTableLine">
          <div>{line.title}</div>
          <div>{line.quantity.toLocaleString()}</div>
          <div>{`${line.price < 0 ? "-" : ""}$${Math.abs(line.price).toLocaleString()}`}</div>
        </div>
      ))}
      {campaignCostPreview.total && (
        <div className="quoteTableSubtotalLine">
          <div></div>
          <div>Subtotal</div>
          <div>
            $
            {campaignCostPreview?.amountOff
              ? (campaignCostPreview.subtotal - campaignCostPreview.amountOff).toLocaleString()
              : campaignCostPreview.subtotal}
          </div>
        </div>
      )}
      {isSubmitted && campaignCostPreview.tax && (
        <div className="quoteTableSubtotalLine">
          <div></div>
          <div>Taxes</div>
          <div>${campaignCostPreview.tax.toLocaleString()}</div>
        </div>
      )}

      {campaignCostPreview.total && (
        <div className="quoteTableTotalLine">
          <div></div>
          <div>Total {!isSubmitted ? "(Before Tax)" : ""}</div>
          <div>${campaignCostPreview.total.toLocaleString()}</div>
        </div>
      )}
    </div>
  );
};
