export const FileStick = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.6663 3.99996V11.6666C10.6663 13.14 9.47301 14.3333 7.99967 14.3333C6.52634 14.3333 5.33301 13.14 5.33301 11.6666V3.33329C5.33301 2.41329 6.07967 1.66663 6.99967 1.66663C7.91967 1.66663 8.66634 2.41329 8.66634 3.33329V10.3333C8.66634 10.7 8.36634 11 7.99967 11C7.63301 11 7.33301 10.7 7.33301 10.3333V3.99996H6.33301V10.3333C6.33301 11.2533 7.07967 12 7.99967 12C8.91967 12 9.66634 11.2533 9.66634 10.3333V3.33329C9.66634 1.85996 8.47301 0.666626 6.99967 0.666626C5.52634 0.666626 4.33301 1.85996 4.33301 3.33329V11.6666C4.33301 13.6933 5.97301 15.3333 7.99967 15.3333C10.0263 15.3333 11.6663 13.6933 11.6663 11.6666V3.99996H10.6663Z"
        fill="#4F5B94"
      />
    </svg>
  );
};
