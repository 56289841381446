import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    padding: "4px 8px",
    alignItems: "center",
    gap: "8px",
    borderRadius: 8,
    border: "1px solid #E2E2FC",
    background: "#FFF",
    cursor: "pointer",
    transition: "all 0.2s ease",
    flex: "1 0 140px",
    maxWidth: "230px",
    position: "relative",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
    "&.selected": {
      borderColor: theme.palette.primary.main,
      background: "#F7F7FD",
    },
    "&.disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
  },
  checkIcon: {
    position: "absolute",
    left: "5px",
    top: "6px",
    width: "19px",
    height: "19px",
    display: "none",
    "$card.selected &": {
      display: "block",
    },
  },
  imageContainer: {
    display: "flex",
    width: "49px",
    height: "40px",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  content: {
    display: "flex",
    padding: "8px 0px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "2px",
  },
  topText: {
    color: "#707087",
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: "Proxima Nova",
    fontSize: "12px",
    fontWeight: 400,
  },
  label: {
    color: "#011533",
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: "Proxima Nova",
    fontSize: "16px",
    fontWeight: 600,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  description: {
    color: "#AEAFC7",
    fontSize: "12px",
  },
}));
