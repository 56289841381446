export const styles = {
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  pdfPreviewContainer: {
    height: "calc(100vh - 180px)",
    width: "97%",
    overflow: "scroll",
  },
  pdfPreviewContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  page: {
    marginBottom: "40px",
  },
  sideTitle: {
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "center",
    color: "rgba(112, 112, 135, 1)",
    marginBottom: "10px",
  },
  previewWrapper: {
    position: "relative",
  },
  widthInInch: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 700,
    fontSize: "16.96px",
    color: "#707087",
    marginTop: "5px",
  },
  arrow: {
    position: "absolute",
    width: "10px",
    height: "10px",
  },
  arrowTop: {
    top: "1px",
    left: "-4px",
    borderTop: "2px solid #707087",
    borderLeft: "2px solid #707087",
    transform: "rotate(45deg)",
  },
  arrowBottom: {
    bottom: "1px",
    left: "-4px",
    borderBottom: "2px solid #707087",
    borderRight: "2px solid #707087",
    transform: "rotate(45deg)",
  },
  arrowLeft: {
    left: "1px",
    top: "-4px",
    borderTop: "2px solid #707087",
    borderLeft: "2px solid #707087",
    transform: "rotate(-45deg)",
  },
  arrowRight: {
    right: "1px",
    top: "-4px",
    borderTop: "2px solid #707087",
    borderRight: "2px solid #707087",
    transform: "rotate(45deg)",
  },
  outBleedingLine: {
    backgroundColor: "#AEAFC7",
    position: "absolute",
  },
  leftOutBleedingLine: {
    height: "100%",
    width: "1px",
    top: 0,
    left: 10,
  },
  rightOutBleedingLine: {
    height: "100%",
    width: "1px",
    top: 0,
    right: 10,
  },
  topOutBleedingLine: {
    width: "100%",
    height: "1px",
    top: 10,
    left: 0,
  },
  bottomOutBleedingLine: {
    width: "100%",
    height: "1px",
    bottom: 10,
    left: 0,
  },
  innerBleedingLine: {
    border: "1px dashed #23BB32",
    position: "absolute",
    top: "18px",
    left: "18px",
    width: "calc(100% - 36px)",
    height: "calc(100% - 36px)",
  },
};
