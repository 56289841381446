import React, { useState } from "react";
import cn from "classnames";
import { GoogleMap } from "@react-google-maps/api";
// import { usePostHog } from "posthog-js/react";
// import { EmitAnalyticsEvent } from "module/analytics/application/EmitAnalyticsEvent";
import { formatNumber, toFixed2 } from "utils/formators";
import { SuggestedRoute } from "module/eddm/dto";
import { makeStyles } from "@material-ui/core";
import { DeleteIcon } from "static/media/delete-icon";
import { ResidencesIcon } from "components/icons/components/ResidencesIcon";
import { handleHoverSelectedMissionUpdate } from "components/audience-map/utils";
import { StarIcon } from "components/icons/components/Star";
import CheckIcon from "@material-ui/icons/Check";
import { useStore } from "store";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);

interface SuggestionRouteCardProps {
  route: SuggestedRoute;
  className: string;
  handleRouteDelete: (route: SuggestedRoute) => void;
  handleRouteSelect: (route: SuggestedRoute) => void;
  googleMap: GoogleMap | null;
}

export const SuggestedRouteCard = ({
  route,
  className,
  handleRouteDelete,
  handleRouteSelect,
  googleMap,
}: SuggestionRouteCardProps) => {
  const classes = useStyles();
  const {
    // user: { id: userId },
    // client: { name: clientName },
    campaign: { isSubmitted },
    costsCalculationData: { costPerFlyer },
  } = useStore();
  const [isHovered, setIsHovered] = useState(false);
  // const posthog = usePostHog();
  // const posthogEventTracker = new EmitAnalyticsEvent(posthog);

  const totalAddresses = route?.properties ? route?.properties?.HOME_COUNT + route?.properties?.APT_COUNT : 0;

  return (
    <div
      className={cn(classes.routeCardContainer, { [className]: !!className })}
      onMouseOver={() => {
        setIsHovered(true);
        handleHoverSelectedMissionUpdate({ mapInit: googleMap, hoveredMission: route._id });
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        handleHoverSelectedMissionUpdate({ mapInit: googleMap, hoveredMission: null });
      }}
    >
      <div className={classes.routeHeader}>
        <div className={cn(classes.routeTitle, { active: isHovered })}>
          <span>
            {route.properties.ZIP} - {route.properties.CROUTE}
          </span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button className={classes.iconButton}>
            <DeleteIcon
              disabled={isSubmitted}
              width={"16"}
              height={"16"}
              onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                e.preventDefault();
                e.stopPropagation();
                if (isSubmitted) return;
                // posthogEventTracker.run({
                //   eventName: `Deleted single route`,
                //   userId: userId,
                //   clientName,
                //   device: navigator.userAgent,
                // });
                handleRouteDelete(route);
              }}
            />
          </button>
          <button className={classes.selectButton} onClick={() => handleRouteSelect(route)}>
            <CheckIcon fontSize="small" />
          </button>
        </div>
      </div>
      <div className={classes.routeStatistics}>
        <div className={classes.residences}>
          <StarIcon stroke="#2E3860" />
          <span>{route.suitabilityScore}%</span>
        </div>
        <div className={classes.residences}>
          <ResidencesIcon stroke="#2E3860" />
          <span>{formatNumber(totalAddresses)}</span>
        </div>
        <div className={classes.costContainer}>
          <span className={classes.statisticLabel}>Cost</span>
          <span className={classes.cost}>${formatNumber(toFixed2(costPerFlyer * totalAddresses))}</span>
        </div>
      </div>
    </div>
  );
};
