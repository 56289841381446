import { apiClient } from "module/api";

const MAX_POLLING_TIMEOUT = 30000;

export const checkCreatedAudienceZip = async (audienceId, signal, startTime = Date.now()) => {
  if (signal.aborted) {
    const abortError = new Error(`Polling aborted: Audience ${audienceId} creation was canceled.`);
    abortError.name = "AbortError";
    throw abortError;
  }

  if (Date.now() - startTime > MAX_POLLING_TIMEOUT) {
    throw new Error(`Polling timeout: Audience ${audienceId} was not created within 30 seconds.`);
  }

  const response = await apiClient.checkCreatedAudiencesZips({
    audiencesIds: [audienceId],
  });

  const data = await response.json();

  if (!data[audienceId]) {
    await new Promise((resolve, reject) => {
      const timeout = setTimeout(resolve, 1000);

      signal.addEventListener(
        "abort",
        () => {
          clearTimeout(timeout);
          reject(new Error(`Polling aborted: Audience ${audienceId} creation was canceled.`));
        },
        { once: true }
      );
    });

    return checkCreatedAudienceZip(audienceId, signal, startTime);
  } else {
    return data[audienceId];
  }
};
