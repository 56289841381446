import { useRef, useEffect } from "react";
import cn from "classnames";
import { usePostHog } from "posthog-js/react";
import { EmitAnalyticsEvent } from "module/analytics/application/EmitAnalyticsEvent";
import { formatNumber, toFixed2 } from "utils/formators";
import { DistributionLocation } from "module/eddm/dto";
import { getRouteResidences } from "utils/eddm";
import { Grow, makeStyles } from "@material-ui/core";
import { EditIcon } from "components/icons/components/EditIcon";
import CloseIcon from "@material-ui/icons/Close";
import { useStore } from "store";
import { DMDocumentNames } from "shared/constants";
import { styles } from "./styles";

interface EDDMSummaryProps {
  grow: boolean;
  setGrow: (grow: boolean) => void;
  isNextActive: boolean;
  onContinue: () => void;
  setFlyerSelectModalOpen: (value: boolean) => void;
}

const useStyles = makeStyles(() => styles);

export const EDDMSummary = ({ grow, setGrow, isNextActive, onContinue, setFlyerSelectModalOpen }: EDDMSummaryProps) => {
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const {
    user,
    campaign: { flyerType, id: campaignId },
    client: { name: clientName },
    distributionLocations,
    costsCalculationData: { costPerFlyer, detailedCost, flyersAmount },
  } = useStore();
  const { subtotal } = detailedCost || {};
  const posthog = usePostHog();
  const postHogTracker = new EmitAnalyticsEvent(posthog);

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setGrow(false);
    }
  };

  useEffect(() => {
    if (grow) {
      postHogTracker.run({
        eventName: "Clicked to see cost summary",
        userId: user?.id,
        clientName,
        device: navigator.userAgent,
        additionalPayload: {
          campaignId,
        },
      });
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [grow]);

  return (
    <Grow in={grow} {...(grow ? { timeout: 1000 } : {})} style={{ transformOrigin: "top right" }}>
      <div className={classes.eddmSummaryContainer} ref={containerRef}>
        <div className={classes.eddmSummarySection}>
          <div className={classes.flexCenterSpacebetween}>
            <span className={classes.bold18px}>Summary</span>
            <CloseIcon fontSize="small" onClick={() => setGrow(false)} style={{ cursor: "pointer" }} />
          </div>
        </div>
        <div className={cn(classes.flexCenterSpacebetween, classes.marginTop12)}>
          <span className={classes.postcardFormat}>Postcard format </span>
          <button className={cn(classes.bold14px, classes.flyerType)} onClick={() => setFlyerSelectModalOpen(true)}>
            <span>{DMDocumentNames[flyerType as keyof typeof DMDocumentNames]}</span> <EditIcon />
          </button>
        </div>

        <div className={cn(classes.eddmSummarySection, classes.marginTop12)}>
          <div className={classes.flexCenterStart}>
            <span className={classes.bold18px}>Segments</span>
          </div>
          <div className={classes.locationsWrapper}>
            {distributionLocations?.map((distributionLocation: DistributionLocation) => {
              const totalAddresses = getRouteResidences(distributionLocation.selectedRoutes);
              return (
                <div
                  className={cn(classes.flexCenterSpacebetween, classes.marginTop12)}
                  key={distributionLocation.name}
                >
                  <div className={classes.columnStart}>
                    <span>{distributionLocation.name}</span>
                    <span>{distributionLocation.selectedRoutes.length} routes</span>
                  </div>
                  <div className={classes.columnEnd}>
                    <span className={classes.bold14px}> $ {formatNumber(toFixed2(costPerFlyer * totalAddresses))}</span>
                    <span style={{ display: "flex", alignItems: "center" }}>{totalAddresses} residences</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={cn(classes.flexCenterSpacebetween, classes.marginTop12)}>
          <span className={classes.bold18px}>Total</span>
          <div className={classes.columnEnd}>
            <span className={classes.bold18px}>$ {formatNumber(subtotal)}</span>
            <span>
              {formatNumber(flyersAmount) || "Set"} {flyersAmount ? "R" : "r"}esidences
            </span>
          </div>
        </div>

        <button
          className={cn(classes.continueButton, { [classes.buttonDisabled]: !isNextActive })}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onContinue();
          }}
          disabled={!isNextActive}
        >
          Continue
        </button>
      </div>
    </Grow>
  );
};
