import { useState } from "react";
import { CAMPAIGN_SUBTYPES_ENUM } from "shared/constants";
import { TriggerAnalyticsFn } from "../../hooks/usePosthogAnalytics/usePosthogAnalytics";

interface UseDragAndDropProps {
  setFiles: (files: File[]) => void;
  maxFileSize?: number; // in MB
  triggerAnalyticsEvent: TriggerAnalyticsFn;
}

export interface FileRejection {
  errors: { code: string }[];
}

export const useADMDesignDragAndDrop = ({ setFiles, maxFileSize = 25, triggerAnalyticsEvent }: UseDragAndDropProps) => {
  const [fileError, setFileError] = useState<string | null>(null);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.some((f: File) => f.size > maxFileSize * 1048576)) {
      setFiles([]);
      setFileError(`Error: The file exceeded the ${maxFileSize}mb limit`);
    } else {
      setFileError(null);
      setFiles(acceptedFiles);
      triggerAnalyticsEvent("Clicked to upload design file", {
        file_size: acceptedFiles.reduce((acc, file) => acc + file.size, 0),
        channel: CAMPAIGN_SUBTYPES_ENUM.ADM,
      });
    }
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-invalid-type") {
          setFileError("Error: You can upload only PDF file");
        }
        if (err.code === "file-too-large") {
          setFileError(`Error: The file exceeded the ${maxFileSize}mb limit`);
        }
      });
    });
  };

  return {
    fileError,
    onDrop,
    onDropRejected,
  };
};
