import { useState } from "react";
import cn from "classnames";
import { CustomTextInput } from "components/form/InputField/components/CustomTextInput";
import { Typography, CircularProgress } from "@material-ui/core";
import { SelectField } from "components/form/SelectField";
import { FileStick } from "static/media/FileStick";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useParams } from "react-router-dom";
import { downloadStringAsFile } from "utils/files";
import CloseIcon from "@material-ui/icons/Close";
import { DeleteIcon } from "static/media/delete-icon";
import { DragAndDropArea } from "pages/flyer/flyerTypePage/components/DragAndDropArea";
import { Download } from "static/media/download";
import { apiClient } from "module/api";
import { AudienceCreationFailedModal } from "./AudienceCreationFailedModal";
import { Audience } from "../../types";
import { useADMDesignDragAndDrop } from "./useADMDesignDragAndDrop";
import { audienceHeadersData, templateDefaultRecipients } from "./templateData";
import { useCampaignData } from "../../context/CampaignDataContext";
import { CreatingAudienceModal } from "./CreatingAudienceModal";
import { checkCreatedAudienceZip } from "./utils";
import classes from "./AudienceCreationModal.module.css";

const requiredFields = ["first_name", "last_name", "address_1", "city", "state", "zip_code"];
const headersMapInitialState = {
  first_name: "",
  last_name: "",
  address_1: "",
  address_2: "",
  city: "",
  state: "",
  zip_code: "",
};

interface AudienceCreationModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  audience: Audience | null;
  triggerAnalyticsEvent: (eventName: string, eventProperties?: Record<string, any>) => void;
}

const getNewAudienceName = (audiences: Audience[]) => {
  if (!audiences?.length) return "New Audience";
  const audiencesWithDefaultName = audiences.filter((audience) => audience.name.includes("New Audience"));
  if (!audiencesWithDefaultName.length) return "New Audience";
  const maxAudienceNumber = audiencesWithDefaultName.reduce((acc: number, curr: Audience) => {
    const currentNumber = curr.name.split("New Audience ")[1];
    if (currentNumber && Number(currentNumber) > acc) {
      acc = Number(currentNumber);
      return acc;
    } else {
      return acc;
    }
  }, 0);
  if (audiencesWithDefaultName) {
    return `New Audience ${maxAudienceNumber + 1}`;
  } else {
    return "New Audience";
  }
};

export const AudienceCreationModal = ({
  isOpen,
  setIsOpen,
  audience,
  triggerAnalyticsEvent,
}: AudienceCreationModalProps) => {
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const [submitProgress, setSubmitProgress] = useState<number>(0);
  const { refetchAudiences, refetchCampaignAudiences, audiences, campaignAudiences } = useCampaignData();
  const [openFailModal, setOpenFailModal] = useState(false);
  const { clientId, campaignId } = useParams();
  const [files, setFiles] = useState<Array<any>>([]);
  const [audienceNameError, setAudienceNameError] = useState<string>("");
  const [audienceName, setAudienceName] = useState<string>(audience?.name || getNewAudienceName(audiences));
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [headersMap, setHeadersMap] = useState<{ [key: string]: string }>(
    audience?.metadata?.headersMap || headersMapInitialState
  );
  const [interactedWithMapper, setInteractedWithMapper] = useState<boolean>(false);

  const {
    isUploading,
    fileError,
    setFileMetadata,
    onDrop,
    onDropRejected,
    fileMetadata,
    uploadHandler,
    getPresignedFileUrl,
  } = useADMDesignDragAndDrop({
    setHeadersMap,
    headersMapInitialState,
    audience,
    setFiles,
    maxFileSize: 175,
    clientId,
    triggerAnalyticsEvent,
  });

  const handleTemplateDownload = () => {
    const headers = audienceHeadersData.map((headerData) => headerData.value);
    const printingCostsTemplate =
      headers.join(",") + "\n" + templateDefaultRecipients.map((recipient) => recipient.join(",") + "\n").join("");
    downloadStringAsFile(printingCostsTemplate, "ADMAudienceTemplate.csv", "text/csv");
    triggerAnalyticsEvent("Downloaded ADM audience template");
  };

  const handleDeleteFile = () => {
    // TODO DELETE FILE FROM S3
    setFiles([]);
    setFileMetadata(null);
  };

  const handleResetModal = () => {
    setFiles([]);
    setAudienceName(audience?.name || getNewAudienceName(audiences));
    setFileMetadata(null);
    setHeadersMap(audience?.metadata?.headersMap || headersMapInitialState);
    setOpenFailModal(false);
  };

  const isValidFileMetadata = () => {
    if (!fileMetadata || !fileMetadata.headers || !fileMetadata.rowsAmount || !fileMetadata.firstRow) return false;
    return true;
  };

  const disabledSubmit = () => {
    const requiredFieldsMissing = requiredFields.filter((field) => !headersMap[field]);
    return (
      !!fileError ||
      !files?.length ||
      !!requiredFieldsMissing.length ||
      !fileMetadata ||
      !isValidFileMetadata() ||
      !!audienceNameError
    );
  };

  const handleHeaderMapChange = (headerName: string, value: string) => {
    setHeadersMap((prev) => ({ ...prev, [headerName]: value }));
    if (!interactedWithMapper) {
      triggerAnalyticsEvent("Mapped ADM audience CSV columns");
      setInteractedWithMapper(true);
    }
  };

  const handleAudienceSubmit = async () => {
    if (disabledSubmit()) return;
    const controller = new AbortController();
    let createdOrUpdatedAudienceId;
    let shouldCloseModal = false;
    setAbortController(controller);
    try {
      setIsSubmitting(true);
      setSubmitProgress(0);
      triggerAnalyticsEvent("Saved uploaded ADM audience without filters");
      if (audience) {
        // Edit audience
        let newFileUrl;
        let presignedUrlData;
        const fileChanged = files[0] && audience.csv_url !== fileMetadata?.fileUrl;
        if (fileChanged) {
          presignedUrlData = await getPresignedFileUrl(files[0]);
          setSubmitProgress(25);
          if (!presignedUrlData) {
            throw new Error("Failed to get the presigened url for the new file");
          }
          newFileUrl = presignedUrlData.dbURL;
        }
        const updatedAudienceResponse = await apiClient.updateAudience({
          audienceId: audience.id,
          body: {
            name: audienceName,
            csv_url: newFileUrl || fileMetadata?.fileUrl,
            metadata: { ...fileMetadata, fileUrl: newFileUrl || fileMetadata?.fileUrl, headersMap },
            client_id: String(clientId),
          },
        });
        if (updatedAudienceResponse.status !== 200) {
          throw new Error("Failed to edit the audience");
        }
        const updatedAudience = await updatedAudienceResponse.json();
        if (updatedAudience?.id) {
          createdOrUpdatedAudienceId = updatedAudience.id;
        }
        setSubmitProgress(50);

        if (fileChanged) {
          const deleteAudienceZipResponse = await apiClient.deleteAudienceZip(updatedAudience.id);
          if (deleteAudienceZipResponse.status !== 204) {
            throw new Error("Failed to delete the audience zip");
          }
          setSubmitProgress(60);
          const uploadStatus = await uploadHandler(files[0], presignedUrlData.url, controller.signal);
          setSubmitProgress(90);
          if (!uploadStatus) {
            throw new Error("Failed to upload the new file");
          }
          await checkCreatedAudienceZip(updatedAudience.id, controller.signal);
          setSubmitProgress(100);
        }
      } else {
        // Create audience
        const presignedUrlData = await getPresignedFileUrl(files[0]);
        if (!presignedUrlData) {
          throw new Error("Failed to upload the file");
        }
        setSubmitProgress(25);
        const createAudienceResponse = await apiClient.createAudience({
          name: audienceName,
          csv_url: presignedUrlData.dbURL,
          metadata: { ...fileMetadata, fileUrl: presignedUrlData.dbURL, headersMap },
          client_id: clientId,
        });
        setSubmitProgress(40);
        if (createAudienceResponse.status !== 201) {
          throw new Error("Failed to create the audience");
        }
        const createdAudience = await createAudienceResponse.json();
        if (createdAudience?.id) {
          createdOrUpdatedAudienceId = createdAudience.id;
        }
        const createCampaignAudienceResponse = await apiClient.createCampaignAudience({
          audience_id: createdAudience.id,
          campaign_id: campaignId,
        });
        setSubmitProgress(60);
        if (createCampaignAudienceResponse.status !== 201) {
          throw new Error("Failed to create the CampaignAudience");
        }

        const uploadStatus = await uploadHandler(files[0], presignedUrlData.url, controller.signal);
        setSubmitProgress(90);
        await checkCreatedAudienceZip(createdAudience.id, controller.signal);
        setSubmitProgress(100);
        if (!uploadStatus) {
          throw new Error("Failed to upload the new file");
        }
      }
      shouldCloseModal = true;
    } catch (e) {
      // @ts-ignore
      if (e?.name === "AbortError") {
        shouldCloseModal = true;
      } else {
        console.error("error while saving the audience: ", e);
        setOpenFailModal(true);
      }
      await rollbackAudienceCreationOrEdit(createdOrUpdatedAudienceId);
    } finally {
      setIsSubmitting(false);
      refetchAudiences();
      refetchCampaignAudiences();
      if (shouldCloseModal) {
        handleClose();
      }
    }
  };

  const rollbackAudienceCreationOrEdit = async (createdOrUpdatedAudienceId: string | undefined) => {
    if (!createdOrUpdatedAudienceId) return;
    try {
      await apiClient.deleteAudience(createdOrUpdatedAudienceId);
    } catch (e) {
      console.log("error while running rollbackAudienceCreationOrEdit", e);
    }
  };

  const getColumnSample = (column: string) => {
    if (!column || !fileMetadata?.headers) return "-";
    const currentIndex = fileMetadata?.headers.findIndex((header: string) => header === column);
    if (currentIndex === -1 || currentIndex === undefined) return "-";

    return fileMetadata?.firstRow[currentIndex];
  };

  const handleClose = () => {
    setFiles([]);
    setAudienceName("New Audience");
    setFileMetadata(null);
    setHeadersMap(headersMapInitialState);
    setIsOpen(false);
  };

  const handleAudienceNameChange = (newAudienceName: string) => {
    const isAnotherAudienceWithSameName = audiences.find((audience) => audience.name.trim() === newAudienceName.trim());
    if (isAnotherAudienceWithSameName && newAudienceName !== audience?.name) {
      setAudienceNameError("Another audience with this name already exists");
    } else {
      setAudienceNameError("");
    }
    setAudienceName(newAudienceName);
  };

  if (!isOpen) return null;

  if (isSubmitting && !!files[0]) {
    return <CreatingAudienceModal file={files[0]} submitProgress={submitProgress} abortController={abortController} />;
  }

  if (openFailModal) {
    return <AudienceCreationFailedModal handleClose={handleClose} handleResetModal={handleResetModal} />;
  }

  return (
    <div className={classes.modalBackground}>
      <div className={classes.modalContent}>
        <div className={classes.modalHeader}>
          <button className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>
        <div className={classes.scrollableContent}>
          <div className={classes.modalTitleContainer}>
            <span className={classes.modalTitle}>{audience ? "Edit" : "Create an"} audience</span>
          </div>
          <div className={classes.audienceNameWrapper}>
            <label>
              Audience name <span className={classes.required}>*</span>
            </label>
            <CustomTextInput
              disabled={false}
              value={audienceName}
              onChange={(e) => handleAudienceNameChange(e.target.value)}
            />
            <span className={classes.audienceNameError}>{audienceNameError}</span>
          </div>
          <div className={classes.audienceDescriptionWrapper}>
            <Typography>Follow the audience template, which should include at least:</Typography>
            <Typography>
              <div className={classes.customListBullet} /> 2 columns for addressee identification: First Name, and Last
              Name.
            </Typography>
            <Typography>
              <div className={classes.customListBullet} /> 4 columns for the complete address: Address, City, State, and
              Zip Code.
            </Typography>
          </div>
          <div className={classes.downloadTemplateButtonWrapper}>
            <button className={classes.downloadTemplateButton} onClick={handleTemplateDownload}>
              <Download /> Download audience template
            </button>
          </div>
          {isUploading ? (
            <CircularProgress size={20} color="secondary" style={{ marginTop: 10, alignSelf: "flex-start" }} />
          ) : files?.length ? (
            <div className={classes.uploadedCSVFile}>
              <FileStick className={classes.fileStick} />
              <div className={classes.fileData}>
                <span className={classes.fileName}>{files[0].name} </span>
                <span className={classes.rowsAmount}>{fileMetadata?.rowsAmount} rows</span>
              </div>
              <button className={classes.deleteIcon} onClick={handleDeleteFile}>
                <DeleteIcon />
              </button>
            </div>
          ) : (
            <div className={classes.dragAndDropWrapper}>
              <DragAndDropArea
                files={[]}
                newVersion
                onDrop={onDrop}
                onDropRejected={onDropRejected}
                fileType="text/csv"
                instructionsText="Click to select or drag and drop your CSV"
                maxFileSize={175}
                alwaysDesktop
              />
            </div>
          )}
          {fileError && (
            <div className={classes.fileError}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize: "1.3em" }} />
              {fileError}
            </div>
          )}

          {!fileError && !isUploading && !!files?.length && (
            <div className={classes.audienceHeadersMapperWrapper}>
              <span className={classes.setCsvColumnsTitle}>Set the CSV columns up</span>
              <div className={classes.audienceHeadersMapperRow}>
                <span>Field</span>
                <span>CSV Column</span>
                <span>Column sample</span>
              </div>
              {audienceHeadersData.map((audienceHeaderData) => {
                const isRequired = !!requiredFields.find((field) => field === audienceHeaderData.value);
                return (
                  <div key={audienceHeaderData.value} className={classes.audienceHeadersMapperRow}>
                    <span>
                      {audienceHeaderData.label} {isRequired && <span className={classes.required}>*</span>}
                    </span>
                    <SelectField
                      label=""
                      value={headersMap[audienceHeaderData.value]}
                      onChange={(value) => handleHeaderMapChange(audienceHeaderData.value, (value as string) || "")}
                      name={audienceHeaderData.value}
                      options={fileMetadata?.headers.map((header: string) => ({ value: header, label: header })) || []}
                      placeholder="Select the corresponding column"
                    />
                    <span>{getColumnSample(headersMap[audienceHeaderData.value])}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className={classes.footer}>
          <button
            className={cn(classes.createAudienceButtonSubmit, { [classes.cursorDefault]: disabledSubmit() })}
            disabled={disabledSubmit()}
            onClick={handleAudienceSubmit}
          >
            {`${audience ? "Save" : "Create"} audience`}
            {isSubmitting && (
              <CircularProgress size={20} color="secondary" style={{ position: "absolute", right: "10px" }} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
