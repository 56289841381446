export const styles = {
  flyerTypeCardContainer: {
    display: "flex",
    width: "229px",
    height: "78px",
    border: "1px solid #E2E2FC",
    borderRadius: "8px",
    padding: "12px",
    marginBottom: "12px",
    cursor: "pointer",
    background: "#fff",
  },
  checked: {
    position: "absolute" as const,
    top: "-8px",
    left: "-8px",
  },
  selected: {
    borderColor: "#4F5B94",
  },
  flyerTypeImageContainer: {
    position: "relative" as const,
    height: "100%",
    width: "30%",
    flex: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flyerTypeDataContainer: {
    height: "100%",
    flex: "70%",
    display: "flex",
    flexDirection: "column" as const,
    padding: "0px 15px",
    color: "#011533",
  },
  flyerTypeLabel: {
    fontWeight: 600,
    fontSize: "16px",
    color: "#011533",
  },
  flyerTypeDimensions: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#707087",
  },
  flyerTypeWorth: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#4F5B94",
  },

  secondary: {
    background: "#fff",
    borderColor: "#e2e2fc",
    cursor: "default",
    width: "100%",
    marginBottom: 0,
  },
};
