import { IconsProps } from "shared/models";

export const HealthInsurance = ({
  width = "13",
  height = "11",
  fill = "none",
  stroke = "#2E3860",
  ...props
}: IconsProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.99916 4.85704V8.28561M11.7134 11.7142H8.28488M11.7134 15.1428H8.28488M11.7134 6.57132H8.28488M15.142 9.99989H16.8563C17.311 9.99989 17.747 10.1805 18.0685 10.502C18.39 10.8235 18.5706 11.2595 18.5706 11.7142V16.857C18.5706 17.3117 18.39 17.7477 18.0685 18.0692C17.747 18.3907 17.311 18.5713 16.8563 18.5713H3.14202C2.68736 18.5713 2.25133 18.3907 1.92984 18.0692C1.60835 17.7477 1.42773 17.3117 1.42773 16.857V9.14275C1.42773 8.6881 1.60835 8.25206 1.92984 7.93057C2.25133 7.60908 2.68736 7.42847 3.14202 7.42847H4.85631M15.142 18.5713V3.14275C15.142 2.6881 14.9614 2.25206 14.6399 1.93057C14.3184 1.60908 13.8824 1.42847 13.4277 1.42847H6.57059C6.11593 1.42847 5.6799 1.60908 5.35841 1.93057C5.03692 2.25206 4.85631 2.6881 4.85631 3.14275V18.5713"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
