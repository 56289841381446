import { SelectOption } from "components/form/SelectField";
import { RadioCardOption } from "components/form/RadioCardField";
import { NavigationItem } from "./components/Header/types";
import OversizedFlyerTemplate from "../../../static/files/Oversized_12x15_postcard_template.zip";
import ADMPostcard6x4Templates from "../../../static/files/postcard-6x4-templates.zip";
import ADMPostcard9x6Templates from "../../../static/files/postcard-9x6-templates.zip";
import ADMPostcard11x6Templates from "../../../static/files/postcard-11x6-templates.zip";
import postcardImage from "../../../static/media/format-postcard.svg";
import letterImage from "../../../static/media/format-letter.svg";
import wideLetterImage from "../../../static/media/format-wide-letter.svg";
import flatImage from "../../../static/media/format-flat.svg";

const HEADER_TITLE = {
  DESIGN: "Design",
  SEGMENTS: "Segments",
  SUBMIT: "Submit",
};

const PAGE_TITLE = {
  DESIGN: "Now, select your design",
  SEGMENTS: "Define the audience for your campaign",
  SUBMIT: "Submit",
};

export const ADM_STEP = {
  SEGMENTS: "segments",
  DESIGN: "design",
  SUBMIT: "submit",
};

export const ADM_NAVIGATION: NavigationItem[] = [
  {
    title: HEADER_TITLE.SEGMENTS,
    pageTitle: PAGE_TITLE.SEGMENTS,
    step: ADM_STEP.SEGMENTS,
    index: 1,
  },
  {
    title: HEADER_TITLE.DESIGN,
    pageTitle: PAGE_TITLE.DESIGN,
    step: ADM_STEP.DESIGN,
    index: 2,
  },
  {
    title: HEADER_TITLE.SUBMIT,
    pageTitle: PAGE_TITLE.DESIGN,
    step: ADM_STEP.SUBMIT,
    index: 3,
  },
];

export const RECIPIENT_MOVED_OPTIONS: SelectOption[] = [
  {
    value: "yes",
    label: "Send to new address",
    description: "Send the campaign to the new address provided by USPS",
  },
  {
    value: "no",
    label: "Don't send",
    description: "Remove the addressee from the campaign",
  },
];

export enum ADM_FORMAT {
  POSTCARD_6x4 = "POSTCARD_6x4",
  POSTCARD_9x6 = "POSTCARD_9x6",
  POSTCARD_11x6 = "POSTCARD_11x6",
  POSTCARD_15x12 = "POSTCARD_15x12",
}

export const FORMAT_OPTIONS: RadioCardOption[] = [
  {
    value: ADM_FORMAT.POSTCARD_6x4,
    label: '6" x 4"',
    topText: "Postcard",
    bottomText: "$",
    image: postcardImage,
  },
  {
    value: ADM_FORMAT.POSTCARD_9x6,
    label: '9" x 6"',
    topText: "Postcard",
    bottomText: "$$",
    image: letterImage,
  },
  {
    value: ADM_FORMAT.POSTCARD_11x6,
    label: '11" x 6"',
    topText: "Postcard",
    bottomText: "$$$",
    image: wideLetterImage,
  },
  {
    value: ADM_FORMAT.POSTCARD_15x12,
    label: '15" x 12"',
    topText: "Postcard",
    bottomText: "$$$$",
    image: flatImage,
  },
];

export const ADM_FORMAT_PREVIEW = {
  [ADM_FORMAT.POSTCARD_6x4]: {
    width: 700,
    height: 466,
    thumbnailWidth: 184,
    thumbnailHeight: 122,
    widthInInch: 6,
    heightInInch: 4,
  },
  [ADM_FORMAT.POSTCARD_9x6]: {
    width: 700,
    height: 466,
    thumbnailWidth: 184,
    thumbnailHeight: 122,
    widthInInch: 9,
    heightInInch: 6,
  },
  [ADM_FORMAT.POSTCARD_11x6]: {
    width: 700,
    height: 381,
    thumbnailWidth: 184,
    thumbnailHeight: 100,
    widthInInch: 11,
    heightInInch: 6,
  },
  [ADM_FORMAT.POSTCARD_15x12]: {
    width: 700,
    height: 560,
    thumbnailWidth: 160,
    thumbnailHeight: 122,
    widthInInch: 15,
    heightInInch: 12,
  },
};

export const ADM_FORMATS_TEMPLATES = {
  [ADM_FORMAT.POSTCARD_6x4]: ADMPostcard6x4Templates,
  [ADM_FORMAT.POSTCARD_9x6]: ADMPostcard9x6Templates,
  [ADM_FORMAT.POSTCARD_11x6]: ADMPostcard11x6Templates,
  [ADM_FORMAT.POSTCARD_15x12]: OversizedFlyerTemplate,
};

export const MINIMUM_BUSINESS_DAYS = 5;
export const MAXIMUM_CAMPAIGN_DAYS = 360;
