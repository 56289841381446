import { useState, useEffect, useContext } from "react";
import { usePostHog } from "posthog-js/react";
import { authorization } from "module/auth";
import { ADMCampaignData, CampaignCostPreview } from "pages/adm/builder/types";
import { COUPON_CODE_ERROR_MESSAGE } from "pages/constants";
import { apiClient } from "module/api";
import { CountryType } from "store/types";
import { CAMPAIGN_LOG_ENUM_TYPES } from "shared/constants";
import { insertCampaignLogs } from "Logs/campaign/gql";
import { SnackBarContext } from "components/snack-bar";
import { replaceNullValue } from "Logs/campaign/utils";

type UseCouponCodeProps = {
  campaign: ADMCampaignData;
  country: CountryType;
  campaignCostPreview: CampaignCostPreview;
  onUpdateCampaignCostWithCoupon: (couponCode: string | null, amountOff: number, percentOff: number) => Promise<void>;
};

export const useCouponCode = ({
  campaign,
  country,
  campaignCostPreview,
  onUpdateCampaignCostWithCoupon,
}: UseCouponCodeProps) => {
  const posthog = usePostHog();
  const [isCouponCodeOpen, setIsCouponCodeOpen] = useState(false);
  const [couponErrorMessage, setCouponErrorMessage] = useState<string>("");
  const runSnackBar = useContext(SnackBarContext);

  const user = authorization.getUser();
  const isUSClient = user?.country?.code === "US";
  const currencySign = user?.country?.currencySign || "$";
  const isSubmitted = campaign.isSubmitted;
  const isPaymentSkipped = campaign.isPaymentSkipped;

  useEffect(() => {
    if (campaign.stripeCouponCode) {
      setIsCouponCodeOpen(true);
    }
  }, [campaign.stripeCouponCode]);

  const handleOpenCouponCode = () => {
    if (posthog) {
      posthog.capture("Opened Coupon Code Input", {
        event_category: "Quote",
      });
    }
    setIsCouponCodeOpen(true);
  };

  const getCustomerEmail = async (customerId: string) => {
    try {
      const res = await apiClient.getCustomer({
        id: customerId,
        countryCode: country?.code,
      });

      if (res.status === 200) {
        const { customer: responseData } = await res.json();

        if (responseData) {
          return responseData.email;
        }
      }

      return null;
    } catch (err) {
      return null;
    }
  };

  const getCurrencyCode = (country: CountryType) => {
    switch (country.currencySign) {
      case "$":
        if (country.name === "Canada") {
          return "CAD";
        } else if (country.name === "Australia") {
          return "AUD";
        } else if (country.name === "New Zealand") {
          return "NZD";
        } else {
          return "USD";
        }
      case "€":
        return "EUR";
      case "£":
        return "GBP";
      case "CHF":
        return "CHF";

      default:
        return "USD";
    }
  };

  const handleApplyCouponCode = async ({
    stripeCouponCode,
    saveLogs = false,
  }: {
    stripeCouponCode: string;
    saveLogs: boolean;
  }) => {
    if (stripeCouponCode) {
      const code = stripeCouponCode.trim();
      try {
        const res = await apiClient.getPromotionCode({
          promotionCode: code,
          countryCode: country?.code,
        });
        if (res.status !== 200) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }
        const { promotionCodes: responseData } = await res.json();
        if (!responseData) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }

        const { data } = responseData;
        if (!data || !data[0] || code !== data[0].code) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }

        const {
          id: promoId,
          coupon: couponObject,
          max_redemptions: max_redemptions_promotion_code,
          times_redeemed: times_redeemed_promotion_code,
          customer,
          restrictions,
          expires_at,
        } = data[0];
        if (!couponObject && !couponObject.id) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["NO_PROMO_EXIST"]);
        }
        if (
          max_redemptions_promotion_code &&
          times_redeemed_promotion_code &&
          max_redemptions_promotion_code <= times_redeemed_promotion_code
        ) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["EXCEED_USE_PROMO"]);
        }

        const { max_redemptions: max_redemptions_coupon, times_redeemed: times_redeemed_coupon } = couponObject;
        if (max_redemptions_coupon && times_redeemed_coupon && max_redemptions_coupon <= times_redeemed_coupon) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["EXCEED_USE_COUPON"]);
        }

        if (customer) {
          const customerEmail = await getCustomerEmail(customer);

          if (customerEmail !== user.email) {
            throw new Error(COUPON_CODE_ERROR_MESSAGE["NO_PERMISSION"]);
          }
        }

        /**
         * if coupon is fixed discount and has different currency
         */
        if (
          couponObject.amount_off > 0 &&
          couponObject.currency &&
          couponObject.currency.toUpperCase() !== getCurrencyCode(country).toUpperCase()
        ) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["INVALID_CURRENCY"]);
        }

        if (couponObject.duration_in_months && couponObject.created) {
          const expiredDate = new Date(Number(couponObject.created) * 1000);
          expiredDate.setMonth(expiredDate.getMonth() + Number(couponObject.duration_in_months));
          if (expiredDate < new Date()) {
            throw new Error(COUPON_CODE_ERROR_MESSAGE["COUPON_EXPRIED"]);
          }
        }

        /**
         * this promo code has some restrictions such as 'minimum_amount'
         */
        if (restrictions) {
          const { minimum_amount, minimum_amount_currency, first_time_transaction } = restrictions;

          if (minimum_amount) {
            if (minimum_amount / 100 > campaignCostPreview.subtotal) {
              throw new Error(COUPON_CODE_ERROR_MESSAGE["MINIMUM_ORDER"]);
            }

            if (
              minimum_amount > 0 &&
              minimum_amount_currency &&
              minimum_amount_currency.toUpperCase() !== getCurrencyCode(country).toUpperCase()
            ) {
              throw new Error(COUPON_CODE_ERROR_MESSAGE["INVALID_CURRENCY_IN_RESTRICTION"]);
            }
          }

          if (first_time_transaction) {
            // TO DO: SAVE THIS ON STRIPE CUSTOMER METADATA OR ON THE OPPIZI USER
            const didReedemed = !!localStorage.getItem(`first_time_transaction_${promoId}`);
            if (didReedemed) {
              throw new Error(COUPON_CODE_ERROR_MESSAGE["ONLY_FIRST"]);
            }
          }
        }

        if (expires_at) {
          if (new Date().getTime() / 1000 > expires_at) {
            throw new Error(COUPON_CODE_ERROR_MESSAGE["PROMO_EXPIRED"]);
          }
        }
        /**
         * if percent_off is a positive float larger than 0, and smaller or equal to 100,
         * that represents the discount the coupon will apply
         *
         * amount_off is a positive integer representing the amount to subtract from an invoice total
         *
         * amount_off = subtotal * percent_off / 100
         */
        let percent_off = null;
        let amount_off = 0;
        let flag = false;

        if (couponObject.percent_off > 0 && couponObject.percent_off < 100) {
          percent_off = couponObject.percent_off;
          amount_off = (campaignCostPreview.subtotal * percent_off) / 100;
          amount_off =
            Math.round(Number((((campaignCostPreview.subtotal * percent_off) / 100) * 100).toFixed(2))) / 100;
          flag = true;
        }
        if (couponObject.amount_off > 0 && couponObject.amount_off < campaignCostPreview.subtotal * 100) {
          amount_off = Math.round(Number(((couponObject.amount_off / 100) * 100).toFixed(2))) / 100;
          flag = true;
        }
        if (!flag) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["INCORRECT_DISCOUNT_VALUE"]);
        }

        if (campaignCostPreview.subtotal - amount_off <= 0.5) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["LESS_THAN"]);
        }

        await onUpdateCampaignCostWithCoupon(code, amount_off, percent_off);

        runSnackBar({
          type: "success",
          vertical: "top",
          msg: `Your ${percent_off ? `${percent_off}%` : `$${amount_off}`} off discount has been succesfully applied!`,
        });

        if (saveLogs) {
          await insertCampaignLogs([
            {
              campaignId: campaign.id,
              type: CAMPAIGN_LOG_ENUM_TYPES.SET_COUPON_CODE,
              additionalInfo: `[CB] CAMPAIGN EDIT Coupon:${replaceNullValue(code)}, Amount off: ${replaceNullValue(
                amount_off
              )}, Percent off: ${replaceNullValue(percent_off)}`,
            },
          ]);
        }

        setCouponErrorMessage("");
        return true;
      } catch (err: unknown) {
        setCouponErrorMessage(err instanceof Error ? err.message : "An error occurred applying the coupon code");
      }
    }

    await onUpdateCampaignCostWithCoupon(null, 0, 0);
    return false;
  };

  const showCoupon = (!isSubmitted || !!campaign.stripeCouponCode) && !isPaymentSkipped;

  return {
    isCouponCodeOpen,
    couponErrorMessage,
    setCouponErrorMessage,
    handleOpenCouponCode,
    handleApplyCouponCode,
    showCoupon,
    currencySign,
    isUSClient,
    setIsCouponCodeOpen,
  };
};
