import { Box } from "@material-ui/core";

import { DescriptionText } from "../DescriptionText";
import { SubTitleText } from "../SubTitleText";

import "./TargetingSection.css";
import { AudienceText } from "../AudienceText";
import { Audience } from "../../../../types";

export type TargetingSectionProps = {
  audiences: Audience[];
};

export const TargetingSection = ({ audiences }: TargetingSectionProps) => {
  return (
    <div className="targetingSectionContainer">
      <SubTitleText>Targeting</SubTitleText>
      {audiences.map((audience) => (
        <Box key={audience.id}>
          <AudienceText>{audience.name}</AudienceText>
          {/* TODO: add when filters are implemented */}
          {/* <DescriptionText>{`0 filters applied`}</DescriptionText> */}
        </Box>
      ))}
    </div>
  );
};
