import { useState } from "react";
import cn from "classnames";
import { makeStyles, CircularProgress, Checkbox, styled } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);

const CheckboxStyled = styled(Checkbox)({
  "& .MuiSvgIcon-root": {
    fontSize: "20px",
  },
});

interface TermsOfServiceModalProps {
  setIsTermsOfServiceModalOpen: (value: boolean) => void;
  onSubmit: () => Promise<void>;
}

export const TermsOfServiceModal = ({ setIsTermsOfServiceModalOpen, onSubmit }: TermsOfServiceModalProps) => {
  const classes = useStyles();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const handleClose = () => setIsTermsOfServiceModalOpen(false);

  const handleSubmit = async () => {
    setSubmitting(true);
    await onSubmit();
    setSubmitting(false);
    handleClose();
  };

  return (
    <div className={classes.modalBackground}>
      <div className={classes.modalContainer}>
        <div className={classes.closeContainer}>
          <CloseIcon onClick={handleClose} className={classes.closeIcon} />
        </div>
        <div className={classes.contentContainer}>
          <span className={classes.title}>Ready to schedule your campaign?</span>
          <p>
            We will review it, which may take a few business hours, and notify you if there are any issues or if it's
            approved.
          </p>
          <div className={classes.checkboxContainer}>
            <CheckboxStyled
              color="secondary"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
            />
            <span className={classes.checkboxText}>
              I accept the{" "}
              <a
                className={classes.checkboxTextLink}
                href="https://www.oppizi.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms of service
              </a>{" "}
              and acknowledge the inclusion of the USPS address box in the final artwork.
            </span>
          </div>
        </div>
        <div className={classes.footer}>
          <button className={classes.button} onClick={handleClose}>
            Cancel
          </button>
          <button
            className={cn(classes.button, classes.updateButton, {
              [classes.disabledButton]: !termsAccepted || submitting,
            })}
            onClick={handleSubmit}
            disabled={!termsAccepted}
          >
            Submit
            {submitting && <CircularProgress size={16} className={classes.loading} />}
          </button>
        </div>
      </div>
    </div>
  );
};
