import { useState, useContext, useRef, useEffect } from "react";
import { format } from "date-fns";
import { parseTimestampStringDate } from "pages/adm/builder/utils";
import moment from "moment";
import { SnackBarContext } from "components/snack-bar";
import { useHistory } from "react-router-dom";
import { decrypt } from "pages/auth/utils";
import { CAMPAIGN_INTERNAL_STATUSES, COUPON_CODE_ERROR_MESSAGE } from "pages/constants";
import { CAMPAIGN_SUBTYPES_ENUM } from "shared/constants";
import { apiClient } from "module/api";
import { getCurrencyCode } from "utils";
import {
  updateCampaignExtraData,
  updateSelfServeCampaignData,
  updateCampaign as updateCampaignDB,
} from "../../../../../graphQL";
import { useCampaignData } from "../../context/CampaignDataContext";
import { useCampaignCostPreviewCalculation } from "../../hooks/useCampaignCostPreviewCalculation";

export const useADMSubmit = () => {
  const runSnackBar = useContext(SnackBarContext);
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const { campaignData, clientId, user, refetchCampaign, country, setIsReadOnly } = useCampaignData();
  const { costPerFlyer, flyersAmount, campaignCostPreview } = useCampaignCostPreviewCalculation();
  const { tax, subtotal, total } = campaignCostPreview || {};
  const [couponErrorMessage, setCouponErrorMessage] = useState("");
  const {
    id: campaignId,
    stripeCouponCode,
    isPaymentSkipped,
    startDate,
    flyerType,
    campaignName,
    amountOff,
    percentOff,
  } = campaignData || {};
  const promotionIdRef = useRef("");
  const customerIdRef = useRef("");

  useEffect(() => {
    if (history?.location?.search && campaignId) {
      const decryptedCampaignId = decrypt(history?.location?.search.split("payment=")[1]);
      window.history.replaceState(null, "", `${window.location.href.split("?payment")[0]}`);
      if (decryptedCampaignId && String(decryptedCampaignId) === String(campaignId)) {
        setIsReadOnly(true);
        sideEffects();
      } else {
        runSnackBar({
          type: "error",
          msg: `Payment error.`,
          vertical: "bottom",
          horizontal: "right",
        });
      }
    }
  }, [history?.location?.search, campaignId]);

  useEffect(() => {
    if (!stripeCouponCode && promotionIdRef.current) {
      promotionIdRef.current = "";
    }
  }, [stripeCouponCode]);

  const onUpdateCampaignCostWithCoupon = async (
    couponCode: string | null,
    amountOff: number | null,
    percentOff: number | null
  ) => {
    const payloadCampaignExtraData = {
      campaignId: campaignData?.id,
      stripeCouponCode: couponCode,
      amountOff,
      percentOff,
    };
    await updateCampaignExtraData(payloadCampaignExtraData);
  };

  const getGrandForStripe = () => {
    let grand = Number(Number(total) + (Number(amountOff) || 0));
    if (percentOff && percentOff < 100) {
      /**
       * on stripe side in case we applied percent off coupon
       * grand - grand * percentOff / 100 = totalCost
       */
      grand = (total * 100) / (100 - percentOff);
    }

    return Math.trunc(Number((Number(grand) * 100).toFixed(2)));
  };

  const updatePathfinderData = async () => {
    await updateSelfServeCampaignData({
      campaignId: campaignId,
      campaignDetailedCosts: { amountOff, percentOff, tax, subtotal, totalCost: total },
    });
  };

  const updateCampaignAndCampaignExtraData = async () => {
    await updateCampaignDB({
      campaignId,
      name: campaignName?.trim(),
      printingCostPerFlyer: costPerFlyer,
    });

    await updateCampaignExtraData({
      campaignId,
      stripeCouponCode,
      startDate: moment(moment(Number(startDate)).utc().format().split("T")[0], "YYYY-MM-DD", true).format(),
      flyerType,
      totalCosts: total,
      taxes: tax,
      submitterId: user.id,
      flyersCount: flyersAmount,
    });
  };

  const fetchStripeCheckout = async () => {
    const promotionId = promotionIdRef.current || "";
    const res = await apiClient.embeddedCheckoutCampaign({
      campaignId,
      clientId: clientId,
      campaignName: `Campaign ${campaignName ? campaignName.trim() : campaignName}`,
      grand: getGrandForStripe(),
      currencyCode: getCurrencyCode(country),
      countryCode: country?.code,
      promotionId,
      customerId: customerIdRef.current || "",
      userId: user?.id,
      subtype: campaignData?.subtype,
      campaignDetailedCosts: {
        tax,
        subtotal,
        totalCost: total,
        amountOff,
        percentOff,
      },
    });

    if (res.status === 200) {
      const { clientSecret, paymentIntent, paymentStatus } = await res.json();
      await updateCampaignExtraData({
        campaignId,
        paymentIntent,
        paymentStatus,
      });

      return clientSecret;
    }
  };

  const sideEffects = async () => {
    try {
      await updateCampaignExtraData({
        campaignId,
        paymentStatus: "uncaptured",
        internalStatus: CAMPAIGN_INTERNAL_STATUSES.IN_REVIEW,
        lastActiveStep: "submit",
      });
      refetchCampaign();
      runSnackBar({
        type: "success",
        msg: `The campaign was succesfully submitted.`,
        vertical: "bottom",
        horizontal: "right",
      });
    } catch (e) {
      runSnackBar({
        type: "error",
        msg: `Error while submitting the campaign.`,
        vertical: "bottom",
        horizontal: "right",
      });
    }
  };

  const handleSubmit = async () => {
    try {
      if (stripeCouponCode) {
        if (!(await handleApplyCouponCode({ stripeCouponCode }))) {
          throw new Error("Sorry, this coupon code is not active any more.");
        }
      }
      await updatePathfinderData();
      await updateCampaignAndCampaignExtraData();
      if (!isPaymentSkipped) {
        setShowCheckout(true);
      } else {
        await sideEffects();
      }
    } catch (error) {
      runSnackBar({
        type: "error",
        msg: `Failed to submit campaign. ${(error as any)?.message || ""}`,
        vertical: "bottom",
        horizontal: "right",
      });
    }
  };

  const getCustomerEmail = async (customer: string | number) => {
    try {
      const res = await apiClient.getCustomer({
        id: customer,
        countryCode: country?.code,
      });

      if (res.status === 200) {
        const { customer: responseData } = await res.json();

        if (responseData) {
          return responseData.email;
        }
      }

      return null;
    } catch (err) {
      return null;
    }
  };

  const handleApplyCouponCode = async ({ stripeCouponCode = "" }) => {
    if (stripeCouponCode) {
      const code = stripeCouponCode.trim();
      try {
        const res = await apiClient.getPromotionCode({
          promotionCode: code,
          countryCode: country?.code,
        });
        if (res.status !== 200) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }
        const { promotionCodes: responseData } = await res.json();
        if (!responseData) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }

        const { data } = responseData;
        if (!data || !data[0] || code !== data[0].code) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }

        const {
          id: promoId,
          coupon: couponObject,
          max_redemptions: max_redemptions_promotion_code,
          times_redeemed: times_redeemed_promotion_code,
          customer,
          restrictions,
          expires_at,
        } = data[0];
        if (!couponObject && !couponObject.id) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["NO_PROMO_EXIST"]);
        }
        if (
          max_redemptions_promotion_code &&
          times_redeemed_promotion_code &&
          max_redemptions_promotion_code <= times_redeemed_promotion_code
        ) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["EXCEED_USE_PROMO"]);
        }

        const { max_redemptions: max_redemptions_coupon, times_redeemed: times_redeemed_coupon } = couponObject;
        if (max_redemptions_coupon && times_redeemed_coupon && max_redemptions_coupon <= times_redeemed_coupon) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["EXCEED_USE_COUPON"]);
        }

        if (customer) {
          const customerEmail = await getCustomerEmail(customer);

          if (customerEmail !== user.email) {
            throw new Error(COUPON_CODE_ERROR_MESSAGE["NO_PERMISSION"]);
          }
          customerIdRef.current = customer;
        }

        /**
         * if coupon is fixed discount and has different currency
         */
        if (
          couponObject.amount_off > 0 &&
          couponObject.currency &&
          couponObject.currency.toUpperCase() !== getCurrencyCode(country).toUpperCase()
        ) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["INVALID_CURRENCY"]);
        }

        if (couponObject.duration_in_months && couponObject.created) {
          const expiredDate = new Date(Number(couponObject.created) * 1000);
          expiredDate.setMonth(expiredDate.getMonth() + Number(couponObject.duration_in_months));
          if (expiredDate < new Date()) {
            throw new Error(COUPON_CODE_ERROR_MESSAGE["COUPON_EXPRIED"]);
          }
        }

        /**
         * this promo code has some restrictions such as 'minimum_amount'
         */
        if (restrictions) {
          const { minimum_amount, minimum_amount_currency, first_time_transaction } = restrictions;

          if (minimum_amount) {
            if (minimum_amount / 100 > subtotal) {
              throw new Error(COUPON_CODE_ERROR_MESSAGE["MINIMUM_ORDER"]);
            }

            if (
              minimum_amount > 0 &&
              minimum_amount_currency &&
              minimum_amount_currency.toUpperCase() !== getCurrencyCode(country).toUpperCase()
            ) {
              throw new Error(COUPON_CODE_ERROR_MESSAGE["INVALID_CURRENCY_IN_RESTRICTION"]);
            }
          }

          if (first_time_transaction) {
            // TO DO: SAVE THIS ON STRIPE CUSTOMER METADATA OR ON THE OPPIZI USER
            const didReedemed = !!localStorage.getItem(`first_time_transaction_${promoId}`);
            if (didReedemed) {
              throw new Error(COUPON_CODE_ERROR_MESSAGE["ONLY_FIRST"]);
            }
          }
        }

        if (expires_at) {
          if (new Date().getTime() / 1000 > expires_at) {
            throw new Error(COUPON_CODE_ERROR_MESSAGE["PROMO_EXPIRED"]);
          }
        }
        /**
         * if percent_off is a positive float larger than 0, and smaller or equal to 100,
         * that represents the discount the coupon will apply
         *
         * amount_off is a positive integer representing the amount to subtract from an invoice total
         *
         * amount_off = subtotal * percent_off / 100
         */
        let percent_off = null;
        let amount_off = null;
        let flag = false;

        if (couponObject.percent_off > 0 && couponObject.percent_off < 100) {
          percent_off = couponObject.percent_off;
          amount_off = (subtotal * percent_off) / 100;
          amount_off = Math.round(Number((((subtotal * percent_off) / 100) * 100).toFixed(2))) / 100;
          flag = true;
        }
        if (couponObject.amount_off > 0 && couponObject.amount_off < subtotal * 100) {
          amount_off = Math.round(Number(((couponObject.amount_off / 100) * 100).toFixed(2))) / 100;
          flag = true;
        }
        if (!flag) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["INCORRECT_DISCOUNT_VALUE"]);
        }

        if (subtotal && amount_off && subtotal - amount_off <= 0.5) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["LESS_THAN"]);
        }

        runSnackBar({
          type: "success",
          vertical: "top",
          msg: `Your ${percent_off}% discount has been succesfully applied!`,
        });

        promotionIdRef.current = promoId;
        setCouponErrorMessage("");
        return true;
      } catch (err) {
        setCouponErrorMessage((err as any)?.message);
      }
    }

    // in case invalid or empty code
    onUpdateCampaignCostWithCoupon(null, null, null);
    promotionIdRef.current = "";
    customerIdRef.current = "";

    return false;
  };

  const onSubmit = async () => {
    setSubmitting(true);
    await handleSubmit();
    setSubmitting(false);
  };

  return {
    fetchStripeCheckout,
    onSubmit,
    submitting,
    showCheckout,
    setShowCheckout,
    couponErrorMessage,
  };
};
