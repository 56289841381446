import PrintIcon from "static/media/print-icon";
import { CampaignCostPreview } from "../../../../types";
import "./OrderDetailsFooter.css";
import { PrimaryButton } from "../../../PrimaryButton";

interface OrderDetailsFooterProps {
  onContinueToCheckout: () => void;
  isContinueToCheckoutDisabled: boolean;
  quote: CampaignCostPreview;
  onPrint: () => void;
  isSubmitted: boolean;
}

export const OrderDetailsFooter = ({
  onContinueToCheckout,
  isContinueToCheckoutDisabled,
  quote,
  onPrint,
  isSubmitted,
}: OrderDetailsFooterProps) => {
  const totalUnits = quote.lines.reduce((acc, line) => acc + line.quantity, 0);
  return (
    <div className="orderDetailsFooter">
      <div className="orderDetailsFooterQuote">
        <div className="orderDetailsFooterQuoteTotal">{`$${quote.total} ${isSubmitted ? "" : "(Before tax)"}`}</div>
        <div className="orderDetailsFooterQuoteUnits">{totalUnits} uni</div>
      </div>
      <div className="orderDetailsFooterButtons">
        <button className="orderDetailsFooterButtonPrint" onClick={onPrint}>
          <PrintIcon />
        </button>
        <PrimaryButton onClick={onContinueToCheckout} disabled={isContinueToCheckoutDisabled}>
          Continue to Checkout
        </PrimaryButton>
      </div>
    </div>
  );
};
