import { TARGET_SELECTOR_VALUES } from "shared/constants";

export const MeetingLinkMap = {
  US: "https://meetings.hubspot.com/arthur51?embed=true",
  CA: "https://meetings.hubspot.com/arthur51?embed=true",
  AU: "https://meetings.hubspot.com/alice-p1?embed=true",
  NZ: "https://meetings.hubspot.com/alice-p1?embed=true",
  BE: "https://meetings.hubspot.com/thomas-de-kerchove?embed=true",
  NL: "https://meetings.hubspot.com/thomas-de-kerchove?embed=true",
  FR: "https://meetings.hubspot.com/vincent-bonnet?embed=true",
  UK: "https://meetings.hubspot.com/nicolas202?embed=true",
  DE: "https://meetings.hubspot.com/sebastian-mark?embed=true",
  ES: "https://calendar.app.google/CQiWUAnF4fb3M82v8",
};

export const DefaultMeetingLink = MeetingLinkMap["US"];

export const INTERCECT_ROUTE_AREA_THRESHOLD = 0.3;

export const targetOptionsValues = {
  [TARGET_SELECTOR_VALUES.AGE_RANGE_TIERS]: [
    { label: "20-24", selected: false, value: "AGE_20_24" },
    { label: "25-34", selected: false, value: "AGE_25_34" },
    { label: "35-44", selected: false, value: "AGE_35_44" },
    { label: "45-54", selected: false, value: "AGE_45_54" },
    { label: "55-64", selected: false, value: "AGE_55_64" },
    { label: "65-74", selected: false, value: "AGE_65_74" },
    { label: "+75", selected: false, value: "AGE_75_AND_MORE" },
  ],
  [TARGET_SELECTOR_VALUES.GENDER_TIERS]: [
    { label: "Female", selected: false, value: "FEMALE" },
    { label: "Male", selected: false, value: "MALE" },
  ],
  [TARGET_SELECTOR_VALUES.MEDIAN_INCOME]: [
    {
      label: "$0-59k",
      selected: false,
      value: "0-59000",
    },
    {
      label: "$60-90k",
      selected: false,
      value: "60000-90000",
    },
    {
      label: "$91-115k",
      selected: false,
      value: "91000-115000",
    },
    {
      label: "$116-150k",
      selected: false,
      value: "116000-150000",
    },
    {
      label: "$151-200k",
      selected: false,
      value: "151000-200000",
    },
    {
      label: "+ $200k",
      selected: false,
      value: "200000-infinite",
    },
  ],
  [TARGET_SELECTOR_VALUES.ETHNICITY_TIERS]: [
    {
      label: "American Indian/Alaska native",
      selected: false,
      value: "AMERICAN_INDIAN_ALASKA_NATIVE",
    },
    {
      label: "Black",
      selected: false,
      value: "BLACK",
    },
    {
      label: "Hawaiian/Pacific Islander",
      selected: false,
      value: "HAWAIIAN_PACIFIC_ISLANDER",
    },
    {
      label: "White",
      selected: false,
      value: "WHITE",
    },
  ],
  [TARGET_SELECTOR_VALUES.AVERAGE_HOUSEHOLD_SIZE]: [
    {
      label: "1 Resident",
      selected: false,
      value: "1",
    },
    {
      label: "2 Residents",
      selected: false,
      value: "2",
    },
    {
      label: "3 Residents",
      selected: false,
      value: "3",
    },
    {
      label: "4 Residents",
      selected: false,
      value: "4",
    },
    {
      label: "5 or more residents",
      selected: false,
      value: "5",
    },
  ],
  [TARGET_SELECTOR_VALUES.LANGUAGE_TIERS]: [
    {
      label: "English",
      selected: false,
      value: "ENGLISH",
    },
    {
      label: "Spanish",
      selected: false,
      value: "SPANISH_ISOLATED",
    },
    {
      label: "Others",
      selected: false,
      value: "OTHERS",
    },
  ],
  [TARGET_SELECTOR_VALUES.EDUCATION_TIERS]: [
    {
      label: "No diploma",
      selected: false,
      value: "NO_DIPLOMA",
    },
    {
      label: "High school graduate",
      selected: false,
      value: "HIGH_SCHOOL_GRADUATE",
    },
    {
      label: "Associate degree",
      selected: false,
      value: "ASSOCIATE_DEGREE",
    },
    {
      label: "College, no diploma",
      selected: false,
      value: "COLLEGE_NO_DEPLIMA",
    },
    {
      label: "Bachelor's degree",
      selected: false,
      value: "BACHELOR_DEGREE",
    },
    {
      label: "Graduate or professional school degree",
      selected: false,
      value: "GRADUATE_OR_PROFESSIONAL_SCHOOL_DEGREE",
    },
  ],
  [TARGET_SELECTOR_VALUES.OCCUPANCY_TIERS]: [
    {
      label: "Owner-Occupied",
      selected: false,
      value: "OWNER",
    },
    {
      label: "Renter-Occupied",
      selected: false,
      value: "RENTER",
    },
  ],
  [TARGET_SELECTOR_VALUES.HEALTH_INSURANCE_TIERS]: [
    {
      label: "Insured",
      selected: false,
      value: "YES",
    },
    {
      label: "Uninsured",
      selected: false,
      value: "NO",
    },
  ],
};

export const TARGETS_PRIORITY = [
  TARGET_SELECTOR_VALUES.AGE_RANGE_TIERS,
  TARGET_SELECTOR_VALUES.MEDIAN_INCOME,
  TARGET_SELECTOR_VALUES.LANGUAGE_TIERS,
];
