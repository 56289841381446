import { Button, styled } from "@material-ui/core";

export const PrimaryButton = styled(Button)({
  display: "flex",
  height: "44px",
  width: "100%",
  borderRadius: "8px",
  background: "#EE4360",
  color: "#FFFFFF",
  textTransform: "none",
  "&:hover": {
    background: "#D93451",
  },
  "& .MuiButton-label": {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontFamily: "'Proxima Nova', 'sans-serif'",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 700,
  },
  "&:disabled": {
    background: "#E4E4EA",
    color: "#C2C5DD",
  },
});
