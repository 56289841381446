import { Box } from "@material-ui/core";
import { UseGetFlyerInfoResponse } from "pages/flyer/flyerTypePage/useGetFlyerInfo";
import { DesignThumbnailPreviewer } from "../../../DesignThumbnailPreviewer";
import "./DesignPreviewSection.css";

interface DesignPreviewSectionProps {
  frontImageUrl?: string;
  backImageUrl?: string;
  filename?: string;
  printingRef: React.RefObject<HTMLDivElement>;
  flyerInfo: UseGetFlyerInfoResponse;
  thumbnailHeight: number;
  thumbnailWidth: number;
}

export const DesignPreviewSection = ({
  printingRef,
  flyerInfo,
  thumbnailHeight,
  thumbnailWidth,
}: DesignPreviewSectionProps) => {
  return (
    <div className="designPreviewSection" ref={printingRef}>
      <div className="designPreviewTitle">Design Preview</div>
      <DesignThumbnailPreviewer
        height={thumbnailHeight}
        width={thumbnailWidth}
        flyerInfo={flyerInfo?.flyerInfo}
        isLoading={flyerInfo?.isLoading}
      />
    </div>
  );
};
