import { callAuthorizedGraphQLEndpoint } from "../../module/graphQL";

export const getCountryData = async (countryId) => {
  const query = `query ($countryId: ID!) {
    country (id: $countryId) {
      id
      name
      code
      currencySign
      citiesSettings {
        nodes {
          id,
          name, 
          enabledChannelPreciseLocations,
          enabledChannelBroadAreas,
          enabledChannelLetterbox,
          h2hDistributionCosts,
          d2dDistributionCosts,
          bonusBa,
          baPayout,
          outfitCosts,
          permitCostsD2d,
          permitCostsH2h,
          travelCosts,
          minimumMissionLimit,
          weeklyMissionLimit,
          flyerPackH2h,
          flyerPackD2d,
        }
      },
      countryDefaultSettings {
        h2hDistributionCosts,
        d2dDistributionCosts,
        bonusBa,
        baPayout,
        outfitCosts,
        permitCostsD2d,
        permitCostsH2h,
        travelCosts,
        minimumMissionLimit,
        weeklyMissionLimit,
        flyerPackH2h,
        flyerPackD2d,
        countryTaxRate,
        enabledChannelPreciseLocations,
        enabledChannelBroadAreas,
        enabledChannelLetterbox,
        enabledChannelEddm,
        baPerLocation
        defaultCampaignLimits
      }
      printingCosts { 
        nodes {
          id,
          countryId,
          label,
          value,
          flyersCount,
          index,
          }
      }
    }
  }`;

  const request = await callAuthorizedGraphQLEndpoint(query, {
    countryId,
  });

  if (request.status === 200) {
    const {
      data: { country },
    } = await request.json();

    return { ...country, citiesSettings: country.citiesSettings.nodes, printingCosts: country.printingCosts.nodes };
  }
};

export const getClientsPricings = async (countryId) => {
  try {
    const query = `query ($countryId: ID!) {
                    getDMPrintingCosts(id: $countryId){
                        DMPrintingCosts {
                          countryId
                          label
                          value
                          flyersCount
                          version
                          updatedAt
                          updatedBy 
                        } 
                      }
                    }`;
    const request = await callAuthorizedGraphQLEndpoint(query, {
      countryId,
    });

    if (request.status === 200) {
      const {
        data: {
          getDMPrintingCosts: { DMPrintingCosts },
        },
      } = await request.json();

      return DMPrintingCosts;
    }
  } catch (e) {
    return {};
  }
};

export const getAdmClientsPricings = async (countryId) => {
  try {
    const query = `query ($countryId: ID!) {
                    getADMPrintingCosts(id: $countryId){
                        ADMPrintingCosts {
                          countryId
                          label
                          value
                          flyersCount
                          version
                          updatedAt
                          updatedBy 
                        } 
                      }
                    }`;
    const request = await callAuthorizedGraphQLEndpoint(query, {
      countryId,
    });

    if (request.status === 200) {
      const {
        data: {
          getADMPrintingCosts: { ADMPrintingCosts },
        },
      } = await request.json();

      return ADMPrintingCosts;
    }
  } catch (e) {
    return {};
  }
};
