import { useState, useEffect } from "react";
import { SegmentsV1, SegmentsV3 } from "./index";

export const Segments = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isMobile) return <SegmentsV1 {...props} />;
  return <SegmentsV3 {...props} />;
};
