import { Box } from "@material-ui/core";
import { InputFieldNameText } from "../InputField/components/InputFieldNameText";
import { InputFieldDescriptionText } from "../InputField/components/InputFieldDescriptionText";
import { CustomDatePicker } from "./components/CustomDatePicker";

export type DateFieldProps = {
  className?: string;
  description?: string;
  error?: string;
  label: string;
  name: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
  disabled?: boolean;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
  isUS?: boolean;
};

export const DateField = ({
  className,
  description,
  disabled,
  error,
  label,
  name,
  onChange,
  value,
  required,
  minDate,
  maxDate,
  isUS,
}: DateFieldProps) => {
  return (
    <Box width="100%" className={className}>
      <Box className="inputLabelContainer">
        <InputFieldNameText>
          {label}
          {required && <span className="required">*</span>}
        </InputFieldNameText>
        {description && <InputFieldDescriptionText>{description}</InputFieldDescriptionText>}
      </Box>
      <CustomDatePicker
        value={value}
        onChange={onChange}
        disabled={disabled}
        error={error}
        isUS={isUS}
        minDate={minDate}
        maxDate={maxDate}
        name={name}
      />
      {error && <Box className="errorContainer">{error}</Box>}
    </Box>
  );
};
