import ROUTES from "config/routing";
import { FlyerStatus } from "pages/flyer/flyerTypePage/useGetDataForPage";
import { CampaignFlyerInfo } from "store/types";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams, generatePath } from "react-router-dom";
import { getCampaignFlyerData } from "../../../graphQL";

export interface UseGetFlyerInfoResponse {
  isLoading: boolean;
  flyerInfo: CampaignFlyerInfo | null;
  pageState: FlyerStatus;
  getFlyerInfo: () => void;
  isQrCodeExist: boolean;
  campaignId: string;
}

export const useGetFlyerInfo = (): UseGetFlyerInfoResponse => {
  const history = useHistory();
  const { campaignId, clientId: clientIdParam } = useParams();
  const [flyerInfo, setFlyerInfo] = useState<CampaignFlyerInfo | null>(null);
  const [isQrCodeExist, setIsQrCodeExist] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getPageState = () => {
    if (flyerInfo?.isFlyerUploaded) {
      return FlyerStatus.flyerUploaded;
    } else if (flyerInfo?.isFlyerUploaded === false) {
      return FlyerStatus.flyerCreated;
    }
    return FlyerStatus.flyerAbsent;
  };

  if (!campaignId) {
    history.push(generatePath(ROUTES.CAMPAIGN, { campaignId, clientId: clientIdParam }));
  }

  const getFlyerInfo = async () => {
    setIsLoading(true);
    const flyerData = await getCampaignFlyerData(Number.parseInt(campaignId));
    const {
      campaignFlyerInfo,
      campaign: { selfServeData },
    } = flyerData;

    if (selfServeData && selfServeData.length && selfServeData[0].qrCodeLink) {
      setIsQrCodeExist(true);
    }
    if (campaignFlyerInfo) {
      setFlyerInfo(campaignFlyerInfo);
    } else {
      setFlyerInfo(null);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getFlyerInfo();
  }, [campaignId]);

  return {
    isLoading,
    flyerInfo,
    pageState: getPageState(),
    getFlyerInfo,
    isQrCodeExist,
    campaignId,
  };
};
