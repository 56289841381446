import React, { useEffect, useState } from "react";
import cn from "classnames";
import BounceLoader from "components/loaders/bounce-loader";
import { makeStyles, Dialog } from "@material-ui/core";
import { styles } from "./styles";

const useStyles = makeStyles(() => styles);

export const ADMPDFPreview = ({ file, width, widthInInch, heightInInch, height, padding }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const loadAndRenderPDF = async (file) => {
      setLoading(true);
      let loadingTask;

      try {
        // Detect if file is a URL (string) or an ArrayBuffer
        if (typeof file === "string") {
          // eslint-disable-next-line no-undef
          loadingTask = pdfjsLib.getDocument(file);
        } else {
          // eslint-disable-next-line no-undef
          loadingTask = pdfjsLib.getDocument({ data: file });
        }

        const pdf = await loadingTask.promise;
        const totalPages = Math.min(pdf.numPages, 2); //only front and back in case there's more
        const pagesArray = [];

        for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
          const page = await pdf.getPage(pageNum);
          const viewport = page.getViewport({ scale: 1 });
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          canvas.width = viewport.width;
          canvas.height = viewport.height;

          await page.render({
            canvasContext: context,
            viewport,
          }).promise;

          const formattedHeight = (viewport.height / viewport.width) * width;
          const formattedWidth = (viewport.width / viewport.height) * height;
          const newCanvasSize = {};

          if (formattedHeight > height) {
            newCanvasSize.height = height;
            newCanvasSize.width = formattedWidth;
          } else {
            newCanvasSize.width = width;
            newCanvasSize.height = formattedHeight;
          }

          pagesArray.push({
            canvas: canvas,
            width: viewport.width,
            height: viewport.height,
            styleWidth: newCanvasSize.width,
            styleHeight: newCanvasSize.height,
          });
        }

        setPages(pagesArray);
      } catch (error) {
        console.error("Error loading PDF:", error);
        window.location.reload();
      }

      setLoading(false);
    };

    if (file) {
      loadAndRenderPDF(file);
    }
  }, [file, width, height]);

  return (
    <>
      {loading ? (
        <div className={classes.loaderContainer}>
          <BounceLoader />
        </div>
      ) : (
        <div className={classes.pdfPreviewContainer}>
          <div className={classes.pdfPreviewContent}>
            {pages.map((page, index) => (
              <div className={classes.page} key={index}>
                <div
                  style={{
                    width: width,
                    height: height + 20,
                  }}
                >
                  <p className={classes.sideTitle}>{index === 0 ? "Front" : "Back"}</p>
                  <div className={classes.previewWrapper}>
                    <div
                      style={{
                        height: height,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: 700,
                        fontSize: "16.96px",
                        color: "#707087",
                        position: "absolute",
                        top: 0,
                        left: -50,
                      }}
                    >
                      <span>{heightInInch}"</span>
                    </div>
                    <div
                      style={{
                        width: "2px",
                        height: height,
                        backgroundColor: "#707087",
                        position: "absolute",
                        top: 0,
                        left: -20,
                      }}
                    >
                      <div className={cn(classes.arrow, classes.arrowTop)} />
                      <div className={cn(classes.arrow, classes.arrowBottom)} />
                    </div>

                    <div
                      style={{
                        width: width,
                        height: height,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        border: "1px solid gray",
                        zIndex: 2,
                      }}
                    >
                      <div className={cn(classes.outBleedingLine, classes.leftOutBleedingLine)} />
                      <div className={cn(classes.outBleedingLine, classes.rightOutBleedingLine)} />
                      <div className={cn(classes.outBleedingLine, classes.topOutBleedingLine)} />
                      <div className={cn(classes.outBleedingLine, classes.bottomOutBleedingLine)} />
                      <div className={classes.innerBleedingLine} />
                    </div>
                    <canvas
                      ref={(el) => {
                        if (el) {
                          el.width = page.width;
                          el.height = page.height;
                          el.getContext("2d").drawImage(page.canvas, 0, 0);
                        }
                      }}
                      style={{
                        display: loading ? "none" : "block",
                        width: page.styleWidth,
                        height: page.styleHeight,
                        position: "absolute",
                        top: padding,
                        left: padding,
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: width,
                    position: "relative",
                    height: "2px",
                    backgroundColor: "#707087",
                    marginTop: "30px",
                  }}
                >
                  <div className={cn(classes.arrow, classes.arrowLeft)} />
                  <div className={cn(classes.arrow, classes.arrowRight)} />
                </div>
                <div className={classes.widthInInch}>
                  <span>{widthInInch}"</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
