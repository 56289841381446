import { createContext, useContext, useState, ReactNode, useEffect, useMemo } from "react";
import moment from "moment";
import { authorization } from "module/auth";
import { User } from "module/auth/dto";
import { CountryType } from "store/types";
import { useQuery } from "react-query";
import { apiClient } from "../../../../module/api";
import { ADMCampaignData, Audience, CampaignAudience } from "../types";
import { getIndexOfCurrentStep, isAheadByBusinessDays, parseTimestampStringDate } from "../utils";
import { CAMPAIGN_INTERNAL_STATUSES } from "../../../constants";
import { getCampaign, getCountryData, getSubmittedCampaignDetailedCosts } from "../../../../graphQL";
import { MINIMUM_BUSINESS_DAYS } from "../constants";

interface CampaignDataContextProps {
  user: User;
  country: CountryType | null;
  activeAudienceDetails: Audience[];
  activeTabIndex: number;
  campaignData: ADMCampaignData | null;
  error: Error | null;
  isLoading: boolean;
  refetchCampaign: () => void;
  setActiveTabIndex: (index: number) => void;
  setCampaignId: (campaignId: string) => void;
  setClientId: (clientId: string) => void;
  campaignId: string | null;
  clientId: string | null;
  audiences: Audience[];
  audiencesError: Error | null;
  audiencesLoading: boolean;
  refetchAudiences: () => void;
  campaignAudiences: CampaignAudience[];
  campaignAudiencesError: Error | null;
  campaignAudiencesLoading: boolean;
  refetchCampaignAudiences: () => void;
  shouldDisplayDatesAlert: boolean;
  isReadOnly: boolean;
  setIsReadOnly: (isReadOnly: boolean) => void;
}

const CampaignDataContext = createContext<CampaignDataContextProps | undefined>(undefined);

export const CampaignDataProvider = ({ children }: { children: ReactNode }) => {
  const [clientId, setClientId] = useState<string | null>(null);
  const [country, setCoutry] = useState<CountryType | null>(null);
  const [campaignId, setCampaignId] = useState<string | null>(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const user = useMemo(() => authorization.getUser(), [authorization]);

  const {
    data: campaignData = null,
    error,
    isLoading,
    refetch,
  } = useQuery<ADMCampaignData | null>({
    queryKey: ["campaign", clientId, campaignId],
    queryFn: async () => {
      if (!campaignId || !clientId) {
        return null;
      }
      const campaign: ADMCampaignData = await getCampaign(campaignId, clientId);
      campaign.isSubmitted =
        !!campaign.internalStatus &&
        campaign.internalStatus !== CAMPAIGN_INTERNAL_STATUSES.DRAFT &&
        campaign.internalStatus !== CAMPAIGN_INTERNAL_STATUSES.REJECTED;

      campaign.id = Number(campaignId);
      const savedStartDate = campaign.startDate
        ? new Date(
            moment(moment(Number(campaign.startDate)).utc().format().split("T")[0], "YYYY-MM-DD", true).format()
          ).getTime()
        : "";

      campaign.startDate = String(savedStartDate);

      let parsedCampaign = { ...campaign };
      if (campaign.isSubmitted) {
        setIsReadOnly(true);
        const submittedDetailedCosts = await getSubmittedCampaignDetailedCosts(campaignId);
        parsedCampaign = {
          ...campaign,
          taxes: submittedDetailedCosts.tax,
          subtotal: submittedDetailedCosts.subtotal,
          totalCosts: submittedDetailedCosts.totalCost,
          amountOff: submittedDetailedCosts.amountOff,
          percentOff: submittedDetailedCosts.percentOff,
        };
      }
      return parsedCampaign;
    },
  });

  const {
    data: audiences = [],
    error: audiencesError,
    isLoading: audiencesLoading,
    refetch: refetchAudiences,
  } = useQuery<Audience[]>({
    queryKey: ["audiences", clientId],
    queryFn: async () => {
      if (!clientId) {
        return [];
      }
      const clientAudiencesResponse = await apiClient.getClientAudiences(clientId);
      if (clientAudiencesResponse.status !== 200) {
        return [];
      }
      const fetchedClientAudiences = await clientAudiencesResponse.json();
      return fetchedClientAudiences.audiences;
    },
  });

  const {
    data: campaignAudiences = [],
    error: campaignAudiencesError,
    isLoading: campaignAudiencesLoading,
    refetch: refetchCampaignAudiences,
  } = useQuery<CampaignAudience[]>({
    queryKey: ["campaignAudiences", campaignId],
    queryFn: async () => {
      if (!campaignId) {
        return [];
      }
      const campaignAudiencesResponse = await apiClient.getCampaignAudiences(campaignId);
      if (campaignAudiencesResponse.status !== 200) {
        return [];
      }
      const fetchedCampaignAudiences = await campaignAudiencesResponse.json();
      return fetchedCampaignAudiences;
    },
  });

  const activeAudienceDetails = useMemo(() => {
    return campaignAudiences
      .map((campaignAudience) => audiences.find((audience) => audience.id === campaignAudience.audience_id))
      .filter(Boolean) as Audience[];
  }, [campaignAudiences, audiences]);

  useEffect(() => {
    if (campaignData && !activeTabIndex) {
      setActiveTabIndex(getIndexOfCurrentStep(campaignData.lastActiveStep));
    }
  }, [campaignData?.lastActiveStep]);

  const shouldDisplayDatesAlert = useMemo(() => {
    return (
      !!campaignData?.startDate &&
      !isAheadByBusinessDays(parseTimestampStringDate(campaignData.startDate)!, MINIMUM_BUSINESS_DAYS)
    );
  }, [campaignData]);

  useEffect(() => {
    if (!campaignData?.country?.id) return;
    const getAndSaveCountry = async () => {
      const countryId = campaignData.country.id;
      const country = await getCountryData(countryId);
      if (!country) return;
      setCoutry(country);
    };
    getAndSaveCountry();
  }, [campaignData]);

  return (
    <CampaignDataContext.Provider
      value={{
        country,
        user,
        activeTabIndex,
        campaignData,
        error: error as Error | null,
        isLoading,
        refetchCampaign: refetch,
        setActiveTabIndex,
        setCampaignId,
        setClientId,
        campaignId,
        clientId,
        refetchAudiences,
        audiencesLoading,
        audiences,
        audiencesError: audiencesError as Error | null,
        campaignAudiences,
        campaignAudiencesError: campaignAudiencesError as Error | null,
        campaignAudiencesLoading,
        refetchCampaignAudiences,
        activeAudienceDetails,
        shouldDisplayDatesAlert,
        isReadOnly,
        setIsReadOnly,
      }}
    >
      {children}
    </CampaignDataContext.Provider>
  );
};

export const useCampaignData = () => {
  const context = useContext(CampaignDataContext);
  if (context === undefined) {
    throw new Error("useCampaignData must be used within a CampaignDataProvider");
  }
  return context;
};
