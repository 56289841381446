import { IconsProps } from "shared/models";

export const Gender = ({
  width = "24",
  height = "24",
  fill = "none",
  isActive = false,
  stroke = "#2E3860",
  ...props
}: IconsProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.2656 1.47437H20.876V6.08467"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.8754 1.47437L15.4629 6.88446"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6543 16.0518V22.989"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.58203 19.9155H14.7291"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.29883 10.6941C6.29883 11.3976 6.43739 12.0942 6.70662 12.7441C6.97584 13.3941 7.37044 13.9847 7.8679 14.4821C8.36536 14.9796 8.95592 15.3742 9.60588 15.6434C10.2558 15.9126 10.9525 16.0512 11.656 16.0512C12.3595 16.0512 13.0561 15.9126 13.7061 15.6434C14.356 15.3742 14.9466 14.9796 15.444 14.4821C15.9415 13.9847 16.3361 13.3941 16.6053 12.7441C16.8745 12.0942 17.0131 11.3976 17.0131 10.6941C17.0131 9.99055 16.8745 9.29393 16.6053 8.64397C16.3361 7.99401 15.9415 7.40344 15.444 6.90598C14.9466 6.40853 14.356 6.01392 13.7061 5.7447C13.0561 5.47548 12.3595 5.33691 11.656 5.33691C10.9525 5.33691 10.2558 5.47548 9.60588 5.7447C8.95592 6.01392 8.36536 6.40853 7.8679 6.90598C7.37044 7.40344 6.97584 7.99401 6.70662 8.64397C6.43739 9.29393 6.29883 9.99055 6.29883 10.6941Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.74609 9.26318H13.5637"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.74609 12.1262H13.5637"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
