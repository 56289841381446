import { z } from "zod";

const URL_REGEX = /^(https?):\/\/(?=.*\.[a-z]{2,})[^\s$.?#].[^\s]*$/i;
export const campaignDetailsSchema = z
  .object({
    hasQRCode: z.string({
      required_error: "Please select if your flyer will have a QR code",
    }),
    qrCodeUrl: z.string().optional(),

    hasRedemptionCoupon: z.string({
      required_error: "Please select if your flyer will have a redemption coupon",
    }),
    couponCode: z.string().optional(),

    additionalComment: z.string().max(240, "Comment cannot exceed 240 characters").optional(),
  })
  .superRefine((data, ctx) => {
    if (data.hasQRCode === "yes") {
      if (!data.qrCodeUrl) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "QR code URL is required when QR code is enabled",
          path: ["qrCodeUrl"],
        });
      }

      if (data.qrCodeUrl && !URL_REGEX.test(data.qrCodeUrl)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please enter a valid URL",
          path: ["qrCodeUrl"],
        });
      }
    }

    if (data.hasRedemptionCoupon === "yes" && !data.couponCode) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Coupon code is required when redemption coupon is enabled",
        path: ["couponCode"],
      });
    }
  });

export type CampaignDetailsFormData = z.infer<typeof campaignDetailsSchema>;
