import { useState, useEffect, useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { UploadFile } from "shared/graphql/mutations/uploadFile";
import CONFIG from "config/config";
import { UseGetFlyerInfoResponse } from "pages/flyer/flyerTypePage/useGetFlyerInfo";
import { insertCampaignLogs } from "Logs/campaign/gql";
import { CAMPAIGN_LOG_ENUM_TYPES } from "shared/constants";
import { GetSignedUrl } from "shared/graphql/queries/getSignedFileUrl";

interface UseDragAndDropProps {
  files: File[];
  setFiles: (files: File[]) => void;
  flyerInfo?: UseGetFlyerInfoResponse;
  maxFileSize?: number; // in MB
}

interface FileRejection {
  errors: { code: string }[];
}

export const useDragAndDrop = ({ files, setFiles, flyerInfo, maxFileSize = 25 }: UseDragAndDropProps) => {
  const [uploadFile] = useMutation(UploadFile);
  const [s3Key, setS3Key] = useState<string | null>(null);
  const [pendingUpload, setPendingUpload] = useState<File | null>(null);

  const { data: signedUrlData } = useQuery(GetSignedUrl, {
    variables: {
      bucket: CONFIG.PDF_FLYER_BUCKET,
      key: s3Key,
      operation: "PUT",
    },
    skip: !s3Key,
  });
  const [tempFiles, setTempFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [fileError, setFileError] = useState<string | null>(null);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const { campaignId } = useParams();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.some((f: File) => f.size > maxFileSize * 1048576)) {
        setTempFiles([]);
        setFileError(`Error: The file exceeded the ${maxFileSize}mb limit`);
      } else {
        setFileError(null);
        setTempFiles(acceptedFiles);
      }
    },
    [setTempFiles, setFileError]
  );

  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-invalid-type") {
          setFileError("Error: You can upload only PDF file");
        }
      });
    });
  };

  const uploadHandler = async () => {
    const file = tempFiles[0];
    setFileError(null);
    setIsUploading(true);
    setFileUploadProgress(0);

    // Start the upload process by setting the key and pending file
    const key = `campaign-builder/${Date.now().toString()}/${file.name}`;
    setPendingUpload(file);
    setS3Key(key);
  };

  useEffect(() => {
    const continueUpload = async () => {
      if (!signedUrlData?.getSignedUrl || !pendingUpload || !s3Key) return;

      try {
        // Upload to S3 using signed URL
        const response = await fetch(signedUrlData.getSignedUrl, {
          method: "PUT",
          body: pendingUpload,
          headers: {
            "Content-Type": pendingUpload.type,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to upload file");
        }

        setFileUploadProgress(60);

        await uploadFile({
          variables: {
            file: s3Key,
            fileName: pendingUpload.name,
            fileType: pendingUpload.type,
            campaignId: `${campaignId}`,
          },
        });

        await insertCampaignLogs([
          {
            campaignId: `${campaignId}`,
            type: CAMPAIGN_LOG_ENUM_TYPES.UPLOAD_PDF_DESIGN,
            additionalInfo: `[CB] CAMPAIGN EDIT File name: ${pendingUpload.name}`,
          },
        ]);

        if (flyerInfo) {
          await flyerInfo.getFlyerInfo();
        }
        setFiles([pendingUpload]);
        setTempFiles([]);
        setFileUploadProgress(100);
      } catch (error) {
        setFileUploadProgress(0);
        console.error({ error }, "uploadFile error");
      } finally {
        setIsUploading(false);
        setPendingUpload(null);
        setS3Key(null);
      }
    };

    continueUpload();
  }, [signedUrlData]);

  useEffect(() => {
    if (tempFiles.length > 0) {
      uploadHandler();
    }
  }, [tempFiles]);

  return {
    isUploading,
    setIsUploading,
    fileUploadProgress,
    setFileUploadProgress,
    tempFiles,
    setTempFiles,
    fileError,
    onDrop,
    onDropRejected,
  };
};
